import { ProfileActionsTypes } from "./profileActionsTypes";
import { initialProfileState } from "../state/initialState";

export const profileReducer = (state = initialProfileState, action) => {
  switch (action.type) {
    case ProfileActionsTypes.SET_LOAD_STORE:
      return {
        ...state,
        profile: action.payload?.data?.user[0],
        imageProfile: action.payload?.data?.imageProfile[0],
        loading: false,
      };
    case ProfileActionsTypes.UPDATE_PROFILE:
      return {
        ...state,
        profile: { ...state.profile, ...action.payload.data.user },
      };
    case ProfileActionsTypes.UPDATE_IMAGE_PROFILE:
      return {
        ...state,
        imageProfile: { path_media: action.payload.data.enlaceUrl },
      };
    case ProfileActionsTypes.DELETE_IMAGE_PROFILE:
      return {
        ...state,
        imageProfile: { path_media: action.payload.data },
      };
    case ProfileActionsTypes.UPDATE_CHECK_THEME:
      return {
        ...state,
        profile: { ...state.profile, theme: action.payload.theme },
      };
    default:
      return state;
  }
};
