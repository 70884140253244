import React from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const BudgetComments = ({
  index,
  budget,
  comments,
  deleteInputPlaceholder,
  recalculateComments,
  textAreaCount,
}) => {
  const [t] = useTranslation("global");
  return (
    <div className="div-textarea-budget">
      <p className="bodyBold mb-2 color-text-secondary">
        {t("budget.comments")}
      </p>
      <InputGroup>
        <FormControl
          id={`inputTextarea-${index}`}
          className="bg-input color-text-secondary"
          autoComplete="nope"
          maxLength={200}
          as="textarea"
          rows="5"
          placeholder={
            budget.email_sent ? budget.email_sent : t("budget.inputComments")
          }
          value={comments}
          onClick={() => deleteInputPlaceholder(`inputTextarea-${index}`)}
          onChange={recalculateComments}
          required
        ></FormControl>
      </InputGroup>
      <p className="numberLetter text-end text-secondary">
        {textAreaCount}/200
      </p>
    </div>
  );
};
