import React, { useState } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import "./buttonCollapsed.css";

export const ButtonCollapsed = ({
  eventKey,
  buttonDisabled,
  textButton,
  textButton2,
  eventKeyStates,
  changeTextButton,
  setSelectedIdx,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const closedAccordion = useAccordionButton(eventKey, () => "");

  const handleClick = () => {
    closedAccordion();
    changeTextButton(eventKey);
    setSelectedIdx(isAccordionOpen ? null : eventKey);
    setIsAccordionOpen(!isAccordionOpen);
  };

  const buttonText = eventKeyStates[eventKey] ? textButton2 : textButton;

  return (
    <button
      type="button"
      onClick={() => handleClick()}
      disabled={buttonDisabled}
      className="style-button-collapsed text-color"
    >
      {buttonText}
    </button>
  );
};
