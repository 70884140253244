import React from "react";
import { useTranslation } from "react-i18next";
import iconCheckGreen from "../../../../assets/images/icon-Check-Green.svg";

export const PCInformation = ({ arrayData }) => {
  const [t] = useTranslation("global");

  return (
    <div className="pcContainer">
      <div className="pcInfo lightBlueBackground padding-box-dsh">
        <div className="d-flex ps-2">
          <img
            className="checkCopyConfigureAssistant"
            src={iconCheckGreen}
            alt="ex"
          />
          <p className="bodyBold pcTittle ps-2">
            {t("pageConfiguration.configuration")}
          </p>
        </div>
        <p className="pcTittle ps-2">{t("pageConfiguration.configuration2")}</p>
        <ul>
          {arrayData.map((item, index) => (
            <li key={index} className="pcTittle">
              {item?.text}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
