import React from "react";
import { DropdownButtonBudget } from "./DropdownButtonBudget/DropdownButtonButget";
import { BudgetModalDelete } from "./BudgetModalDelete";
import { ButtonCollapsed } from "./ButtonCollapsed/ButtonCollapsed";
import sinImageDefault from "../../../assets/images/sinImageDefault.jpg";

export const BudgetHeader = ({
  userProfile,
  budget,
  setIdBudgets,
  idBudgets,
  show,
  setShow,
  idx,
  textButton,
  textButton2,
  handleClose,
  handleClick,
  bodyPartPiercingObject,
  eventKeyStates,
  changeTextButton,
  setSelectedIdx,
}) => {
  return (
    <div
      disabled={
        userProfile.profile.premium === (0 || 1) ? false : budget.disabled
      }
      className={
        budget.disabled
          ? "accordionDisabled p-0 button-budge bg-container"
          : "accordionEnabled p-0 button-budge bg-container"
      }
    >
      <div className="div-button-styles-budgets">
        <div className="div-imagen-budget">
          <div>
            <BudgetModalDelete
              id={budget.id}
              setIdBudgets={setIdBudgets}
              idBudgets={idBudgets}
              checkedState={budget.checked}
              show={show}
              setShow={setShow}
              handleClose={handleClose}
              handleClick={handleClick}
            />
          </div>

          {budget.budget_type === 1 ? (
            <div className="imageDataBudget">
              {budget.media_path_budget === "null" ||
              budget.media_path_budget === null ? (
                <img
                  className="imagen-default color-border"
                  src={sinImageDefault}
                  alt="sinImage"
                />
              ) : (
                <img
                  className="imagen-default color-border"
                  src={budget?.media_path_budget?.split(", ")[0]}
                  alt="sinImage"
                />
              )}
            </div>
          ) : (
            <>
              <div className="imageDataBudget">
                {bodyPartPiercingObject?.map((data) => (
                  <React.Fragment key={data.id}>
                    {data?.parts?.map((part) => (
                      <React.Fragment key={part.id}>
                        {budget?.body_part === data.value &&
                          (budget?.body_part_site === part.value ||
                            budget?.body_part_site === null) && (
                            <div>
                              <img
                                className="imagen-default color-border"
                                src={part.image}
                                alt="First slide"
                              />
                            </div>
                          )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="div-info-budget">
          <div className="div-info-drop">
            <p className="text-color">
              {budget.budget_created_at.slice(0, 10)}
            </p>
            <DropdownButtonBudget
              budgetState={budget.state_budget_tag}
              budgetId={budget.id}
              user={userProfile.profile.id}
              buttonDisabled={budget.disabled}
            />
          </div>
          <p className="text-color textBold">{budget.name}</p>
          <div className="div-description-service">
            {budget.description === "undefined" ||
            budget.description === "null" ||
            budget.description === null ||
            budget.description === "" ||
            budget.description === "false" ? null : (
              <p className="color-text-secondary description-service">
                {budget.description}
              </p>
            )}
            <ButtonCollapsed
              eventKey={idx}
              buttonDisabled={budget.disabled}
              textButton={textButton}
              textButton2={textButton2}
              eventKeyStates={eventKeyStates}
              changeTextButton={() => changeTextButton(idx)}
              setSelectedIdx={setSelectedIdx}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
