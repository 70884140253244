import React from "react";
import { Col } from "react-bootstrap";
import { PCAvailability } from "./components/PC_Availability";
import { PCCity } from "./components/PC_City";
import { PCSize } from "./components/PC_Size";
import { PCSkinTone } from "./components/PC_SkinTone";
import { PCTypeJewelry } from "../PC_TypeJewelry/components/PC_TypeJewelry";
import {PCCentimeters} from "./components/PC_Centimeters";

export const PCOptions = ({
  id,
  theme,
  PCInformation,
  textTitleOptionsForm,
  id_rol,
}) => {
  return (
    <Col>
      <h4 className="headingH4 text-color">{textTitleOptionsForm}</h4>
      <div className="pcContainer divConfigureAssistantLogo bg-container">
        {id_rol !== 4 && (
          <>
            <PCSize PCInformation={PCInformation} />
            <PCCentimeters PCInformation={PCInformation}/>
            <PCSkinTone PCInformation={PCInformation} />
            <PCAvailability id={id} PCInformation={PCInformation} />
            <PCCity theme={theme} id={id} PCInformation={PCInformation} />
          </>
        )}
        {id_rol === 4 && (
          <PCTypeJewelry theme={theme} id={id} PCInformation={PCInformation} />
        )}
      </div>
    </Col>
  );
};
