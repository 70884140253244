import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./redux/store/configureStore";
import "./assets/styles/css/index.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from "./i18n/es/global.json";
import global_en from "./i18n/en/global.json";
import global_de from "./i18n/de/global.json";
import global_pt from "./i18n/pt/global.json";
import global_fr from "./i18n/fr/global.json";
import global_it from "./i18n/it/global.json";
let language = window.navigator.language;
console.log(language);
console.log(language.slice(0, 2));

// if (language?.slice(0, 2) === "es") {
//   language = "es";
// } else {
//   language = "en";
// }
switch (language?.slice(0, 2)) {
  case "es":
    language = "es";
    break;
  case "de":
    language = "de";
    break;
  case "pt":
    language = "pt";
    break;
  case "it":
    language = "it";
    break;
  case "fr":
    language = "fr";
    break;
  default:
    language = "en";
    break;
}
// i18next.init({
//   interpolation: { escapeValue: false },
//   lng: language,
//   resources: {
//     es: {
//       global: global_es,
//     },
//     en: {
//       global: global_en,
//     },
//   },
// });
i18next.init({
  interpolation: { escapeValue: false },
  lng: language,
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
    de: {
      global: global_de,
    },
    pt: {
      global: global_pt,
    },
    fr: {
      global: global_fr,
    },
    it: {
      global: global_it,
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App tab="home" translate="no" />
    </I18nextProvider>
  </Provider>
);
reportWebVitals();
