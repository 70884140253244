import axios from "axios";

const ROOT_URL = process.env.REACT_APP_API_URL;

export const forgotPassword= async(email)=> {  
    try{
        return await axios.post(`${ROOT_URL}/api/user/forgotPassword`,  email );       
    } catch (error) {
     return false
    }
}