//archivo que combina todos los reducers
import { combineReducers } from "redux";
import { authReducer } from "../auth/authReducer";
import { pcReducer } from "../pageConfiguration/pcReducer";
import {profileReducer} from "../profile/profileReducer";
import {budgetReducer} from "../budget/budgetReducer";
import {alertReducer} from "../alert/alertReducer";
import {currencyReducer} from "../currency/currencyReducer";
const rootReducer = combineReducers({
  auth:authReducer,
  user:profileReducer,
  pageConfiguration:pcReducer,
  budget:budgetReducer,
  alert:alertReducer,
  currencies: currencyReducer,
});

export default rootReducer;