import axios from "axios";

const ROOT_URL = process.env.REACT_APP_API_URL;

export const sendSuggestion= async(idUser,title,description)=> {  
    try{
        return await axios.post(`${ROOT_URL}/api/suggestion`, idUser,
        title,
        description );
    } catch (error) {
     return false
    }
}