import React from "react";
import { NavLink } from "react-router-dom";

export const Item = ({ text, to, svg, open }) => {
  return (
    <>
      <NavLink className={open ? "linkOpen" : "normal"} to={to}>
        <div>{svg}</div>
        {open ? <p>{text}</p> : null}
      </NavLink>
    </>
  );
};
