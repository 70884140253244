export const initialCurrenciesState = {
  all: [],
  selected: {},
};
export const initialAuthState = {
  user: [],
  token: [],
  loggedIn: false,
  error:""
};
export const initialProfileState = {
  profile: [],
  imageProfile: [],
  loading: false,
};

export const initialStateINFO = {
  PCInformation: [],
  pCTemplates: [],
  pCLogo: [],
  pCCover: [],
  pCPortfolio: [],
  pCEmployed: [],
  pCSchedule: [],
  pcCity: [],
  pcCheck: {
    CheckTemplates: true,
    CheckLogo: false,
    CheckCover: false,
    CheckPortfolio: false,
    checkAbout: false,
    CheckEmployed: false,
    CheckSchedule: false,
    CheckCity: false
  },
};
export const initialStateBudget = {
  budgets: [],
  ruben: [],
  budgetsCount: 0,
  employeds: [],
  alertAccept: false,
  alertDenied: false,
};

export const initialStateCheck = {
  PCCheckInformation: [],
  pCCheckTemplates: [],
  pCCheckLogo: [],
  pCCheckCover: [],
  pCCheckPortfolio: [],
  pCCheckEmployed: [],
  pCCheckSchedule: [],
  pcCheckCity: [],
};
export const initialStateAlerts = {
  confirm: {
    tittle:"",
    text:"",
    show:false,
  },
};
