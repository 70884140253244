import pc from '../../../../assets/images/icons/icon-regular-business-grey.svg'
import budget from '../../../../assets/images/icons/icon-regular-comments-euro-grey.svg'
import suggestion from '../../../../assets/images/icons/icon-regular-inbox-grey.svg'
import logout from '../../../../assets/images/icons/icon-regular-power-off-grey.svg'
import '../../../../assets/styles/scss/scss_files/general/_general.scss'

export const Link2 = [
  {
    to: "/presupuestos",
    text: "Solicitudes",
    svg: (
      <img
      viewBox="0 0 17 19"
      alt="home"
     src={budget}
    />
    ),
  },
];



export const Link5 = [
  {
    to: "/sugerencias",
    text: "Buzón de sugerencias",
    svg: (
      <img
      viewBox="0 0 17 19"
      alt="home"
     src={suggestion}
    />
    ),
  },
];

export const Link6 = [
  {
    to: "/pageConfiguration",
    text: "Configura tu página",
    svg: (
      <img
      viewBox="0 0 17 19"
      alt="home"
     src={pc}
    />
    ),
  },
];

export const LogoutButton = [
  {
    to: "/",
    text: "Cerrar sesión",
    svg: (
      <img
      viewBox="0 0 17 19"
      alt="home"
     src={logout}
    />
    ),
  },
];
