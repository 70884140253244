import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";

import { PCOperations } from "../../../../../redux/pageConfiguration/pcOperators";

export const PCTattoo = ({ PCInformation, id_rol }) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();

  const checkAvailabilityTattoo = async () => {
    if (PCInformation.PCInformation.check_tattoo === 0) {
      dispatch(
        PCOperations.changeCheckTattoo(1, PCInformation.PCInformation.id_user)
      );
    }
    if (PCInformation.PCInformation.check_tattoo === 1) {
      dispatch(
        PCOperations.changeCheckTattoo(0, PCInformation.PCInformation.id_user)
      );
    }
  };

  return (
    <>
      <div className="color-border border-div-availabilityCheck availabilityCheck mb-3">
        <p className="text-server-list color-text-secondary">
          {t("pageConfiguration.serviceList")}
        </p>
        {id_rol !== 4 && (
          <div className="div-options-form">
            <Form.Check
              // onClick={() => checkAvailabilityTattoo()}
              type="switch"
              id="custom-switch"
              style={{ height: "2em !important" }}
              className="switch-schedule"
              text="Selecciona si tu negocio dispone de turno intensivo o partido"
              defaultChecked="true"
              //////////////////////////////
              //Con el cambio del codigo de abajo, se quita un warning de la consola//
              // disabled="true"
              disabled={true}
              ///////////////////////////////
            />
            <span className="text-color">
              {t("pageConfiguration.customerTattooAvailability")}
            </span>
          </div>
        )}
        <Form>
          <div className="div-options-check">
            <Form.Check
              name="group1"
              type="radio"
              id="1"
              defaultChecked={PCInformation.PCInformation.check_tattoo === 0}
              onClick={() => checkAvailabilityTattoo()}
            />
            <span className="text-server-list color-text-secondary">
              {t("pageConfiguration.receiveByEmail")}
            </span>
          </div>
          <div className="div-options-check">
            <Form.Check
              name="group1"
              type="radio"
              id="2"
              defaultChecked={PCInformation.PCInformation.check_tattoo === 1}
              onClick={() => checkAvailabilityTattoo()}
            />
            <span className="text-server-list color-text-secondary">
              {t("pageConfiguration.receiveByWhatsApp")}
            </span>
          </div>
        </Form>
      </div>
    </>
  );
};
