import React from "react";
import { Col } from "react-bootstrap";
import { PCLogo } from "./components/Logo/Logo";
import { PCAboutMe } from "./components/AboutMe/AboutMe";

export const PCProfile = ({ pCInfo, user, textTitleProfile }) => {
  const { id } = user.profile;
  const { about_me } = pCInfo.PCInformation;

  return (
    <Col>
      <h4 className="headingH4 text-color">{textTitleProfile}</h4>
      <div className="pcContainer divConfigureAssistantLogo bg-container">
        <PCLogo id={id} user={user} pCInfo={pCInfo} />
        <PCAboutMe id={id} aboutMe={about_me} user={user} nameAssistant={user.profile.name} />
      </div>
    </Col>
  );
};
