import PCActions from "./pcActions";
import AlertActions from "../alert/alertActions";
import {
    updateAboutMe,
    updateInformationUser,
    deletePCEmployed,
    deletePCTypeJewelry,
    deletePCCity,
    updateSkinTone,
    updateCheckCity,
    updateCity,
    createArtist,
    createTypeJewelry,
    updateCheckEmployeds,
    updateCheckTypeJewelry,
    updateImageEmploye,
    updateImageTypeJewelry,
    updateCheckTattoo,
    updateCheckLaser,
    updateCheckMicro,
    updateCheckPiercing,
    updateServicesNumbers,
    updateCheckBarber,
    updateCheckMicroblading,
    updateCheckSizeTattoo,
    updateCheckGemTooth,
    deleteImageEmploye,
    deleteImageJewelry,
    saveArtistLists,
    saveTypeJewelry,
    updateAvailability,
    updateNameAssistant, updatePartialCorporation,
} from "../../helpers/InformationHelper";
import {corporationSelector} from "./pcReducer";

export const PCOperations = {
    updatePartialCorporation: (partialCorporation) => async (dispatch, getState) => {
        await updatePartialCorporation(partialCorporation);
        const state = getState();
        const corporation = corporationSelector(state)
        const newCorporation = {...corporation, ...partialCorporation};
        dispatch(PCActions.updateCorpInformationPC(newCorporation));
    },
  updateAssistanName: (id, name) => async (dispatch) => {
    const response = await updateNameAssistant(id, name);
    if (response.status === 200 || response.status === 204) {
      dispatch(PCActions.updateAssistantMePC(name));
    }
  },
  updateAboutMeAction: (id, textArea) => async (dispatch) => {
    const response = await updateAboutMe(id, textArea);
    if (response.status === 200 || response.status === 204) {
      dispatch(PCActions.updateAboutMePC(textArea));
      dispatch(
        AlertActions.alertSuccessShowAction({
          tittle: "Añadir descripción",
          text: "Se ha cambiado la descripción correctamente",
          show: true,
        })
      );
    }
  },
  changeAvailability: (availability, id) => async (dispatch) => {
    const response = await updateAvailability(availability, id);
    if (response.status === 200 || response.status === 204) {
      dispatch(PCActions.changeStoreAvailability(availability));
    }
  },
  updateNumberServicesAction:
    (
      {
        id,
        phone_laser,
        phone_barber,
        phone_micro,
        phone_piercing,
        phone_microblading,
        phone_gem_tooth,
      },
      t
    ) =>
    async (dispatch) => {
      const response = await updateServicesNumbers(
        id,
        phone_laser,
        phone_barber,
        phone_micro,
        phone_piercing,
        phone_microblading,
        phone_gem_tooth,
        t
      );
      if (response.status === 200 || response.status === 204) {
        dispatch(
          PCActions.updateNumbersServicesPC(
            phone_laser,
            phone_barber,
            phone_micro,
            phone_piercing,
            phone_microblading,
            phone_gem_tooth
          )
        );
        dispatch(
          AlertActions.alertSuccessShowAction({
            tittle: t("alertDomain.optionGeneric"),
            text: t("alertDomain.textGeneric"),
            show: true,
          })
        );
      }
    },
  checkSaveLogoAction:
    ({ checkLogo }) =>
    async (dispatch) => {
      dispatch(PCActions.updateCheckLogoPC(checkLogo));
    },

  checkSaveAboutAction:
    ({ checkAbout }) =>
    async (dispatch) => {
      dispatch(PCActions.updateCheckAboutPC(checkAbout));
    },
  updateCorpInformation:
    (id_user, domain_name, studio_name, t) => async (dispatch) => {
      const response = await updateInformationUser(
        id_user,
        domain_name,
        studio_name
      );
      if (response.status === 200 || response.status === 204) {
        dispatch(
          PCActions.updateCorpInformationPC({
            id_user,
            domain_name,
            studio_name,
          })
        );
        dispatch(
          AlertActions.alertSuccessShowAction({
            tittle: t("alertDomain.option"),
            text: t("alertDomain.text"),
            show: true,
          })
        );
      }
    },
  changeSkinTone: (skin, id) => async (dispatch) => {
    const response = await updateSkinTone(skin, id);
    if (response.status === 200 || response.status === 204) {
      dispatch(PCActions.changeStoreSkinTone(skin));
    }
  },
  changeCheckTattoo: (tattoo, id) => async (dispatch) => {
    const response = await updateCheckTattoo(tattoo, id);
    if (response.status === 200 || response.status === 204) {
      dispatch(PCActions.changeStoreCheckTattoo(tattoo));
    }
  },
  changeCheckLaser: (laser, id) => async (dispatch) => {
    const response = await updateCheckLaser(laser, id);
    if (response.status === 200 || response.status === 204) {
      dispatch(PCActions.changeStoreCheckLaser(laser));
    }
  },
  changeCheckBarber: (barber, id) => async (dispatch) => {
    const response = await updateCheckBarber(barber, id);
    if (response.status === 200 || response.status === 204) {
      dispatch(PCActions.changeStoreCheckBarber(barber));
    }
  },
  changeCheckGemTooth: (gem_tooth, id) => async (dispatch) => {
    const response = await updateCheckGemTooth(gem_tooth, id);
    if (response.status === 200 || response.status === 204) {
      dispatch(PCActions.changeStoreCheckGemTooth(gem_tooth));
    }
  },
  changeCheckMicroblading: (microblading, id) => async (dispatch) => {
    const response = await updateCheckMicroblading(microblading, id);
    if (response.status === 200 || response.status === 204) {
      dispatch(PCActions.changeStoreCheckMicroblading(microblading));
    }
  },
  changeCheckMicro: (micro, id) => async (dispatch) => {
    const response = await updateCheckMicro(micro, id);
    if (response.status === 200 || response.status === 204) {
      dispatch(PCActions.changeStoreCheckMicro(micro));
    }
  },
  changeCheckPiercing: (piercing, id) => async (dispatch) => {
    const response = await updateCheckPiercing(piercing, id);
    if (response.status === 200 || response.status === 204) {
      dispatch(PCActions.changeStoreCheckPiercing(piercing));
    }
  },
  changeCheckSizeTattoo: (sizeTattoo, id, t) => async (dispatch) => {
    const response = await updateCheckSizeTattoo(sizeTattoo, id);
    if (response.status === 200 || response.status === 204) {
      dispatch(PCActions.changeStoreCheckSizeTattoo(sizeTattoo));
      dispatch(
        AlertActions.alertSuccessShowAction({
          tittle: t("alertDomain.optionGeneric"),
          text: t("alertDomain.textGeneric"),
          show: true,
        })
      );
    }
  },
  changeCheckCity:
    (checkCity, id, lengthCities, newTodo) => async (dispatch) => {
      let resCheckCities = checkCity;
      if (lengthCities !== 0 && checkCity !== 0) {
        resCheckCities = 1;
      } else {
        resCheckCities = 0;
      }

      if (newTodo !== "") {
        resCheckCities = 1;
      }

      const response = await updateCheckCity(resCheckCities, id);
      if (response.status === 200 || response.status === 204) {
        dispatch(PCActions.changeStoreCheckCity(checkCity));
      }
    },
  changeCheckEmployed:
    (checkEmployeds, id, lengthEmployeds, newTodo) => async (dispatch) => {
      let resCheckEmployeds = checkEmployeds;
      if (lengthEmployeds !== 0 && checkEmployeds !== 0) {
        resCheckEmployeds = 1;
      } else {
        resCheckEmployeds = 0;
      }

      if (newTodo !== "") {
        resCheckEmployeds = 1;
      }

      const response = await updateCheckEmployeds(resCheckEmployeds, id);
      if (response.status === 200 || response.status === 204) {
        dispatch(PCActions.changeStoreCheckEmployed(checkEmployeds));
      }
    },
  changeCheckTypeJewelry:
    (checkTypeJewelry, id, lengthJewelry, newTodo) => async (dispatch) => {
      let resCheckTypeJewelry = checkTypeJewelry;
      if (lengthJewelry !== 0 && checkTypeJewelry !== 0) {
        resCheckTypeJewelry = 1;
      } else {
        resCheckTypeJewelry = 0;
      }

      if (newTodo !== "") {
        resCheckTypeJewelry = 1;
      }

      const response = await updateCheckTypeJewelry(resCheckTypeJewelry, id);
      if (response.status === 200 || response.status === 204) {
        dispatch(PCActions.changeStoreCheckTypeJewelry(checkTypeJewelry));
      }
    },
  deleteCity: (id_city, index) => async (dispatch) => {
    if (id_city.id_city) {
      const response = await deletePCCity(id_city.id_city);
      if (response.status === 200 || response.status === 204) {
        dispatch(PCActions.deleteStoreCity(index));
      }
    } else {
      dispatch(PCActions.deleteStoreCity(index));
    }
  },
  deleteEmploye: (id_employed, index) => async (dispatch) => {
    if (id_employed.id_employed) {
      const response = await deletePCEmployed(id_employed.id_employed);
      if (response.status === 200 || response.status === 204) {
        dispatch(PCActions.deleteStoreEmploye(index));
      }
    } else {
      dispatch(PCActions.deleteStoreEmploye(index));
    }
  },
  deleteTypeJewelry: (element, index) => async (dispatch) => {
    if (element?.id) {
      const response = await deletePCTypeJewelry(element?.id);
      if (response.status === 200 || response.status === 204) {
        dispatch(PCActions.deleteStoreTypeJewelry(index));
      }
    } else {
      dispatch(PCActions.deleteStoreTypeJewelry(index));
    }
  },
  addCity: (data, id_user, t) => async (dispatch) => {
    const response = await updateCity(data, id_user);
    if (response.status === 200 || response.status === 204) {
      dispatch(PCActions.addStoreCity(response.data));
      dispatch(
        AlertActions.alertSuccessShowAction({
          tittle: t("alertSuccessShowAction.option"),
          text: t("alertSuccessShowAction.text"),
          show: true,
        })
      );
    }
  },
  addArtist: (data, id_user, t) => async (dispatch) => {
    const response = await createArtist(data, id_user);

    if (response.status === 200 || response.status === 204) {
      dispatch(PCActions.addStoreArtist(response.data));
      dispatch(
        AlertActions.alertSuccessShowAction({
          tittle: t("alertSuccessShowAction.option"),
          text: t("alertSuccessShowAction.text"),
          show: true,
        })
      );
    }
  },
  addTypeJewelry: (data, id_user, t) => async (dispatch) => {
    const response = await createTypeJewelry(data, id_user);
    if (response.status === 200 || response.status === 204) {
      dispatch(PCActions.addStoreTpyeJewelry(response.data));
      dispatch(
        AlertActions.alertSuccessShowAction({
          tittle: t("alertSuccessShowAction.option"),
          text: t("alertSuccessShowAction.text"),
          show: true,
        })
      );
    }
  },

  copyLinkYourAssistant: (t) => async (dispatch) => {
    dispatch(
      AlertActions.alertSuccessShowAction({
        tittle: t("alertCopyLink.option"),
        text: t("alertCopyLink.text"),
        show: true,
      })
    );
  },

  updateImageEmployed: (dataImage, employed) => async (dispatch) => {
    const resp = await updateImageEmploye(
      dataImage,
      employed.id_employed,
      employed.id_user
    );
    if (resp.status === 200 || resp.status === 204) {
      dispatch(PCActions.addStoreArtist(resp.data));
    }
  },
  updateImageTypeJewelry: (enlaceUrl, element) => async (dispatch) => {
    const resp = await updateImageTypeJewelry(
      enlaceUrl,
      element.id,
      element.user_id
    );
    if (resp.status === 200 || resp.status === 204) {
      dispatch(PCActions.addStoreTpyeJewelry(resp.data));
    }
  },
  deleteImageEmployed: (employed) => async (dispatch) => {
    const resp = await deleteImageEmploye(
      employed.id_employed,
      employed.id_user
    );
    if (resp.status === 200 || resp.status === 204) {
      dispatch(PCActions.addStoreArtist(resp.data));
    }
  },
  deleteImageJewelry: (element) => async (dispatch) => {
    const resp = await deleteImageJewelry(element.id, element.user_id);
    if (resp.status === 200 || resp.status === 204) {
      dispatch(PCActions.addStoreTpyeJewelry(resp.data));
    }
  },
  saveArtistList: (employes) => async (dispatch) => {
    const resp = await saveArtistLists(employes);
    if (resp.status === 200 || resp.status === 204) {
      dispatch(PCActions.addStoreArtist(resp?.data));
    }
  },
  saveTypeJewelry: (jewelry) => async (dispatch) => {
    const resp = await saveTypeJewelry(jewelry);
    if (resp.status === 200 || resp.status === 204) {
      dispatch(PCActions.addStoreTpyeJewelry(resp?.data));
    }
  },
};
