import React from "react";
import { Button } from "react-bootstrap";
import iconClosedAlert from "../../../../assets/images/icons/iconClosedAlert.svg";
import iconDMInstagramColorModal from "../../../../assets/images/icons/iconDMInstagramColorModal.svg";
import iconInstagramColorModal from "../../../../assets/images/icons/iconInstagramColorModal.svg";
import iconWhatsappColorModal from "../../../../assets/images/icons/iconWhatsappColorModal.svg";
import iconChevronRight from "../../../../assets/images/icons/iconChevronRight.svg";
import { useTranslation } from "react-i18next";

export const PCSharedLinks = ({ setOpenModal, setShared }) => {
  const [t] = useTranslation("global");

  return (
    <>
      <div className="alert-confirmation-budget sharedLinks m-0 d-flex ">
        <div className="header-alert-budget">
          <p>{t("dashboard.modalmodalSharedLinksDescriptionTitle")}</p>
          <Button className="closedAlert" onClick={() => setOpenModal(false)}>
            <img
              className="iconCheck iconCheckModalRRSS"
              src={iconClosedAlert}
              alt="asdasd"
            />
          </Button>
        </div>
        <div className="sharedBody">
          <div className="divAlertConfirmation">
            <p className="text-start  mb-0  pb-5">
              {t("dashboard.modalSharedLinksDescription")}
            </p>
          </div>
          <div className="buttonsShared">
            <div className="links" onClick={() => setShared("whatsapp")}>
              <Button className="modalShared">
                <img
                  className="iconCheck"
                  src={iconWhatsappColorModal}
                  alt="whatsapp"
                />
                <span>WhatsApp Business</span>
              </Button>
              <img className="go" src={iconChevronRight} alt="go" />
            </div>
            <div className="links" onClick={() => setShared("insta")}>
              <Button className="modalShared">
                <img
                  className="iconCheck"
                  src={iconInstagramColorModal}
                  alt="instaColor"
                />
                <span>Instagram  Bio</span>
              </Button>
              <img className="go" src={iconChevronRight} alt="go" />
            </div>
            <div className="links" onClick={() => setShared("instaDM")}>
              <Button className="modalShared">
                <img
                  className="iconCheck"
                  src={iconDMInstagramColorModal}
                  alt="instaDM"
                />
                <span>DM Instagram </span>
              </Button>
              <img className="go" src={iconChevronRight} alt="go" />
            </div>
            <div className="links" onClick={() => setShared("instaStories")}>
              <Button className="modalShared">
                <img
                  className="iconCheck"
                  src={iconInstagramColorModal}
                  alt="instaStories"
                />
                <span>Instagram stories</span>
              </Button>
              <img className="go" src={iconChevronRight} alt="go" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
