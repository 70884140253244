import imageTattooColor from "../../../../assets/images/image-Tattoo-Color.png";
import imageTattooNegro from "../../../../assets/images/image-Tattoo-Negro.png";
import imageTattooIDontKnow from "../../../../assets/images/image-Tattoo-I-Dont-Know.png";

export const ColorTattooData = (t) => {
  return [
    {
      id: "color",
      text: `${t("formChat.colorColor")}`,
      value: "Color",
      image: imageTattooColor,
      type: "radio",
    },
    {
      id: "negro",
      text: `${t("formChat.colorBlack")}`,
      value: "Negro",
      image: imageTattooNegro,
      type: "radio",
    },
    {
      id: "no-lo-se-color",
      text: `${t("formChat.colorIDoNotKnow")}`,
      value: "No lo sé",
      image: imageTattooIDontKnow,
      type: "radio",
    },
  ];
};

export default ColorTattooData;
