import React from "react";
import { Button } from "react-bootstrap";

export const ButtonInkup = ({
  buttonId,
  buttonClass,
  handle,
  text,
  image,
  disabled,
  vip,
  iconW,
}) => {
  return (
    <Button
      onClick={handle}
      id={buttonId}
      className={buttonClass}
      disabled={disabled}
    >
      {iconW && (vip === 1 || vip === 5) ? (
        <img className="iconW" src={iconW} alt="icon-w" />
      ) : null}
      {image}
      {text}
      &nbsp;&nbsp;
      {vip === 1 || vip === 5 ? <>por WhatsApp</> : null}
    </Button>
  );
};
