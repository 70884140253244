import React, { useState } from "react";
import { Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { PCOperations } from "../../../../redux/pageConfiguration/pcOperators";
import { PCTattoo } from "./components/PC_Tattoo";
import { PCLaser } from "./components/PC_Laser";
import { PCMicro } from "./components/PC_Micro";
import { PCPiercing } from "./components/PC_Piercing";
import { PCBarber } from "./components/PC_Barber";
import { PCMicroblanding } from "./components/PC_Microblading";
import { PCGemTooth } from "./components/PC_GemTooth";

export const PCServices = ({
  id,
  id_rol,
  PCInformation,
  optionsFormServices,
  otherServices,
  textButtonSave,
}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  const [phone_laser, setPhone_laser] = useState(null);
  const [phone_micro, setPhone_micro] = useState(null);
  const [phone_piercing, setPhone_piercing] = useState(null);
  const [phone_barber, setPhone_barber] = useState(null);
  const [phone_microblading, setPhone_microblading] = useState(null);
  const [phone_gem_tooth, setPhone_gem_tooth] = useState(null);
  const [disabledButton, setDisabledButton] = useState(true);
  const saveNumbers = () => {
    setDisabledButton(true);
    dispatch(
      PCOperations.updateNumberServicesAction({
        id,
        phone_laser,
        phone_barber,
        phone_micro,
        phone_piercing,
        phone_microblading,
        phone_gem_tooth
      }, t)
    );
  };

  return (
    <Col>
      <h4 className="headingH4 text-color">{optionsFormServices}</h4>
      <div className="pcContainer divConfigureAssistantLogo availabilityCheck bg-container">
        <p className="color-text-secondary">{otherServices}</p>
        <PCTattoo PCInformation={PCInformation} id_rol={id_rol} />
        {id_rol !== 4 && (
          <>
            <PCLaser
              id={id}
              PCInformation={PCInformation}
              setPhone_laser={setPhone_laser}
              setDisabledButton={setDisabledButton}
            />
            <PCMicro
              id={id}
              PCInformation={PCInformation}
              setPhone_micro={setPhone_micro}
              setDisabledButton={setDisabledButton}
            />
            <PCPiercing
              id={id}
              PCInformation={PCInformation}
              setPhone_piercing={setPhone_piercing}
              setDisabledButton={setDisabledButton}
            />
            <PCBarber
              id={id}
              PCInformation={PCInformation}
              setPhone_barber={setPhone_barber}
              setDisabledButton={setDisabledButton}
            />
            <PCMicroblanding
              id={id}
              PCInformation={PCInformation}
              setPhone_microblading={setPhone_microblading}
              setDisabledButton={setDisabledButton}
            />
            <PCGemTooth
              id={id}
              PCInformation={PCInformation}
              setPhone_gem_tooth={setPhone_gem_tooth}
              setDisabledButton={setDisabledButton}
            />
          </>
        )}
        {(PCInformation?.PCInformation.check_laser === 1 ||
          PCInformation?.PCInformation.check_micro === 1 ||
          PCInformation?.PCInformation.check_piercing === 1 ||
          PCInformation?.PCInformation.check_barber === 1 ||
          PCInformation?.PCInformation.check_microblading === 1 ||
          PCInformation?.PCInformation.check_gem_tooth === 1) && (
            <div className="div-button-save-phones mt-3 bodyDetailsBold">
              <Button
                className="rounded-pill bg-button saveImgConfiguration bodyDetailsBold color-text-button"
                onClick={() => saveNumbers()}
                disabled={disabledButton}
              >
                {textButtonSave}
              </Button>
            </div>
          )}
      </div>
    </Col>
  );
};
