import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import iconUnlock from "../../../assets/images/icon-unlock.svg";
import errorIcon from "../../../assets/images/icons/circle-exclamation-solid 2.png";
import eyeIcon from "../../../assets/images/icons/eye-regular2.svg";

export default function ForgetPassword({ onChangePwdSubmit }) {
  const [t] = useTranslation("global");
  const [newState, flipToggle] = useState(false);
  const [newState1, flipToggle1] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordLengthError, setPasswordLengthError] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleToggle = () => {
    flipToggle(!newState);
  };
  const handleToggle1 = () => {
    flipToggle1(!newState1);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (newPassword?.length >= 6) {
      setPasswordLengthError(false);
    } else {
      setPasswordLengthError(true);
    }

    setPasswordsMatch(newPassword === confirmPwd);
    setShowError(false);
  };

  const handleConfirmPwdChange = (e) => {
    const newConfirmPwd = e.target.value;
    setConfirmPwd(newConfirmPwd);

    setPasswordsMatch(password === newConfirmPwd);
    setShowError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passwordsMatch && !passwordLengthError) {
      onChangePwdSubmit(password);
    } else {
      setShowError(true);
    }
  };

  return (
    <div className="">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label className="greyText">
            {t("updatePassword.newPassword")}
          </Form.Label>
          <div className="divMyAccount">
            <img src={iconUnlock} alt="Icono del nombre"></img>
            <Form.Control
              autoComplete="nope"
              type={newState ? "text" : "password"}
              name="password"
              value={password}
              onChange={handlePasswordChange}
              className={`inputProfile color-text-secondary bg-input`}
            ></Form.Control>
            <div className="div-images">
              {(!passwordsMatch || passwordLengthError) && showError && (
                <img className="icon-error" src={errorIcon} alt="errorIcon" />
              )}
              <img
                onClick={handleToggle}
                className="icon-eye mt-1"
                src={eyeIcon}
                alt=""
              />
            </div>
          </div>
          {!showError && (
            <p className="characterPassword2 mt-1">{t("signup.character")}</p>
          )}
          {passwordLengthError && showError ? (
            <p className="invalid-feedback characterPassword2 d-block">
              {t("signup.character")}
            </p>
          ) : null}
        </Form.Group>
        <Form.Label className="greyText">
          {t("updatePassword.repeatPassword")}
        </Form.Label>
        <Form.Group className="mb-4">
          <div className="divMyAccount">
            <img src={iconUnlock} alt="Icono del nombre"></img>
            <Form.Control
              autoComplete="nope"
              type={newState1 ? "text" : "password"}
              name="confirmPwd"
              value={confirmPwd}
              onChange={handleConfirmPwdChange}
              className={`inputProfile color-text-secondary bg-input`}
            ></Form.Control>
            <div className="div-images">
              {(!passwordsMatch || passwordLengthError) && showError && (
                <img className="icon-error" src={errorIcon} alt="errorIcon" />
              )}
              <img
                onClick={handleToggle1}
                className="icon-eye mt-1"
                src={eyeIcon}
                alt=""
              />
            </div>
          </div>
          {!showError && (
            <p className=" characterPassword2 mt-1">{t("signup.character")}</p>
          )}
          {!passwordsMatch && showError ? (
            <p className="invalid-feedback characterPassword2 d-block">
              {t("signup.passwordNomatch")}
            </p>
          ) : passwordLengthError && showError ? (
            <p className="invalid-feedback characterPassword2 d-block">
              {t("signup.character")}
            </p>
          ) : null}
        </Form.Group>
        <div className="divBottonProfile">
          <Button
            type="submit"
            className="bottonProfile bodyDetailsBold bg-button color-text-button"
            disabled={showError}
          >
            {t("updatePassword.update")}
          </Button>
        </div>
      </Form>
    </div>
  );
}
