import { AlertActionsTypes } from "./alertActionsTypes";

const AlertActions = {
  alertSuccessShowAction: (data) => ({
    type: AlertActionsTypes.ALERT_SUCCESS_SHOW,
    payload: { data },
  }),
  alertSuccessHideAction: (data) => ({
    type: AlertActionsTypes.ALERT_SUCCESS_HIDE,
    payload: { data },
  }),
};

export default AlertActions;
