import React from "react";
import { useTranslation } from "react-i18next";
import { BodyTattooData } from "../BudgetDataElements/BodyPartTattooData";
import { ColorTattooData } from "../BudgetDataElements/ColorTattooData";
import { SizeTattooData } from "../BudgetDataElements/SizeTattooData";
import { StyleTattoData } from "../BudgetDataElements/StyleTattoData";
import { TimeTattooData } from "../BudgetDataElements/TimeTattooData";
import { BodyPiercingData } from "../BudgetDataElements/BodyPiercingData";
import iconSize from "../../../../assets/images/icons/icon-size.svg";
import iconBodyPart from "../../../../assets/images/icons/icon-bodyPart.svg";
import iconColor from "../../../../assets/images/icons/icon-color.svg";
import iconJewelry from "../../../../assets/images/icons/icon-jewelry.svg";
import iconSchedule from "../../../../assets/images/icons/icon-schedule.svg";
import iconLocation from "../../../../assets/images/icons/icon-location.svg";
import iconArtist from "../../../../assets/images/icons/icon-artist.svg";
import iconSkinTone from "../../../../assets/images/icons/icon_skin.svg";
import iconClientPiercing from "../../../../assets/images/icons/icon-client-Piercing.svg";
import iconStyle from "../../../../assets/images/icons/styles-icon.svg";
import { SkinTattooData } from "../BudgetDataElements/SkinTattooData";

export const BudgetElements = ({ budget, pcInfo }) => {
  const [t] = useTranslation("global");
  let bodyPartTattooObject = BodyTattooData(t);
  let coloTattooObject = ColorTattooData(t);
  let SkinTattooDataObject = SkinTattooData(t);
  let sizeTattooObject = SizeTattooData(t);
  let timeArrayObject = TimeTattooData(t);
  let styleArrayObject = StyleTattoData(t);
  let bodyPartPiercingObject = BodyPiercingData(t);

  let bodyPartObject =
    budget.budget_type === 1 ? bodyPartTattooObject : bodyPartPiercingObject;
  const resultCityString = `${
    budget.city !== null
      ? budget?.city?.charAt(0).toUpperCase() + budget?.city?.slice(1)
      : budget?.city
  }`;
  let bodyName = budget?.body_part;
  let bodyNamePart = budget?.body_part_site;

  function isBudgetEmpty(budget) {
    return (
      budget === "undefined" ||
      budget === undefined ||
      budget === "null" ||
      budget === false ||
      budget === "false" ||
      budget === null
    );
  }

  return (
    <div className="div-summary-budget color-border disabled-budgetRow">
      <div className="icons-text-info-budgets">
        {isBudgetEmpty(budget.employed) ? null : (
          <div className="div-icons-text-info-budgets">
            <img src={iconArtist} alt="icono tamaño"></img>
            <p className="bodyRegular text-color">{budget.employed}</p>
          </div>
        )}
        {isBudgetEmpty(budget.size) ? null : (
          <div className="div-icons-text-info-budgets">
            <img src={iconSize} alt="icono tamaño"></img>
            {sizeTattooObject.map((data) => (
              <React.Fragment key={data.id}>
                {pcInfo.check_cm === 0 ? (
                  <>
                    {budget.size === data.value ||
                    budget.size === data.valueText ||
                    budget.size === data.valueTextI ? (
                      <p key={data.id} className="text-color">
                        {pcInfo.check_cm === 0 ? data.textSize : data.text}
                      </p>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <React.Fragment key={data.id}>
                    {budget.size === data.value ||
                    budget.size === data.valueText ||
                    budget.size === data.valueTextI ? (
                      <p key={data.id} className="text-color">
                        {pcInfo.check_cm === 0
                          ? data.textSize
                          : data.textMicro || data.text}
                      </p>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
        {isBudgetEmpty(budget.body_part) ? null : (
          <div className="div-icons-text-info-budgets">
            <img
              className="margin-icon-info"
              src={iconBodyPart}
              alt="icono tamaño"
            ></img>
            {bodyPartObject?.map((data) => (
              <React.Fragment key={data.id}>
                {budget.body_part === data.value || bodyName === data.value ? (
                  <div key={data.id} className="margin-left-text-bodypart">
                    <span className="text-color p-0">{data.text}</span>
                    {data?.parts?.map((part) => (
                      <React.Fragment key={part.id}>
                        {(budget.body_part_site === part.value ||
                          bodyNamePart === part.value) && (
                          <span
                            key={part.id}
                            className="bodyRegular text-color p-0"
                          >
                            {" "}
                            - {part.text}
                          </span>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                ) : null}
              </React.Fragment>
            ))}
          </div>
        )}
        {isBudgetEmpty(budget.type_jewelry) ? null : (
          <>
            {budget.budget_type === 2 && (
              <div className="div-icons-text-info-budgets">
                <img src={iconJewelry} alt="icono tamaño"></img>
                <p className="text-color">{budget.type_jewelry}</p>
              </div>
            )}
          </>
        )}

        {isBudgetEmpty(budget.budget_color) ? null : (
          <div className="div-icons-text-info-budgets">
            <img src={iconColor} alt="icono tamaño"></img>
            {coloTattooObject.map((data) => (
              <React.Fragment key={data.id}>
                {budget.budget_color === data.value && (
                  <p key={data.id} className="text-color">
                    {data.text}
                  </p>
                )}
              </React.Fragment>
            ))}
          </div>
        )}

        {isBudgetEmpty(budget.skintone) ? null : (
          <div className="div-icons-text-info-budgets">
            <img src={iconSkinTone} alt="icono tamaño" />
            {SkinTattooDataObject.map((data) => (
              <React.Fragment key={data.id}>
                {budget.skintone === data.value && (
                  <div className="skin-tone-image">
                    <img key={data.id} src={data.image} alt="Skin Tone" />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}

        {isBudgetEmpty(budget.time) ? null : (
          <div className="div-icons-text-info-budgets">
            <img src={iconSchedule} alt="icono tamaño"></img>
            {timeArrayObject.map((data) => (
              <React.Fragment key={data.id}>
                {budget.time === data.value && (
                  <p key={data.id} className="text-color">
                    {data.text}
                  </p>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
        {isBudgetEmpty(budget.styles) ? null : (
          <div className="div-icons-text-info-budgets">
            <img src={iconStyle} alt="icono tamaño"></img>
            {styleArrayObject.map((data) => (
              <React.Fragment key={data.id}>
                {budget.styles === data.value && (
                  <p key={data.id} className="text-color">
                    {data.text}
                  </p>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
        {isBudgetEmpty(budget.city) ? null : (
          <div className="div-icons-text-info-budgets">
            <img
              className="margin-icon-info"
              src={iconLocation}
              alt="icono tamaño"
            ></img>
            <p className="text-color text-color margi-left-text-city">
              {resultCityString}
            </p>
          </div>
        )}
        {budget.budget_type === 2 && (
          <div className="div-icons-text-info-budgets">
            <img src={iconClientPiercing} alt="icono tamaño"></img>
            <p className="text-color">
              {budget.old_client === 0
                ? t("piercingpreviouslyData.piercingNo")
                : t("piercingpreviouslyData.piercingYes")}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
