import React from "react";
import { useTranslation } from "react-i18next";

export const BudgetDescription = ({ budget, idRol }) => {
  const [t] = useTranslation("global");

  return (
    <>
      {idRol !== 4 && (
        <>
          {budget.description === "undefined" ||
          budget.description === "null" ||
          budget.description === null ||
          budget.description === "" ||
          budget.description === "false" ? null : (
            <div className="div-description-budget color-border">
              <p className="bodyBold mb-3 text-color">
                {t("budgetInformation.description")}
              </p>
              <p className="m-0 bodyRegular color-text-secondary">
                {budget.description}
              </p>
            </div>
          )}
        </>
      )}
    </>
  );
};
