import { PCActionsTypes } from "./pcActionsTypes";

const PCActions = {
  setLoadStorePC: (data) => ({
    type: PCActionsTypes.SET_LOAD_STORE_PAGE_CONFIGURATION,
    payload: { data },
  }),
 
  changeStoreAvailability: (data) => ({
    type: PCActionsTypes.UPDATE_AVAILABILITY,
    payload: { data },
  }),
 
 
  updateAboutMePC: (data) => ({
    type: PCActionsTypes.UPDATE_ABOUT_ME,
    payload: { data },
  }),
  updateAssistantMePC: (data) => ({
    type: PCActionsTypes.UPDATE_ASSISTANT_ME,
    payload: { data },
  }),
  updateNumbersServicesPC: (
    phone_laser,
    phone_barber,
    phone_micro,
    phone_piercing,
    phone_microblading,
    phone_gem_tooth
  ) => ({
    type: PCActionsTypes.UPDATE_NUMBERS_SERVICES,
    payload: {
      phone_laser,
      phone_barber,
      phone_micro,
      phone_piercing,
      phone_microblading,
      phone_gem_tooth,
    },
  }),
  updateCorpInformationPC: (data) => ({
    type: PCActionsTypes.UPDATE_CORP_INFORMATION,
    payload: { data },
  }),
 
 
  
  
  changeStoreSkinTone: (data) => ({
    type: PCActionsTypes.UPDATE_SKINTONE,
    payload: { data },
  }),
  changeStoreCheckTattoo: (data) => ({
    type: PCActionsTypes.UPDATE_CHECK_TATTOO,
    payload: { data },
  }),
  changeStoreCheckLaser: (data) => ({
    type: PCActionsTypes.UPDATE_CHECK_LASER,
    payload: { data },
  }),
  changeStoreCheckBarber: (data) => ({
    type: PCActionsTypes.UPDATE_CHECK_BARBER,
    payload: { data },
  }),
  changeStoreCheckGemTooth: (data) => ({
    type: PCActionsTypes.UPDATE_CHECK_GEM_TOOTH,
    payload: { data },
  }),
  changeStoreCheckMicroblading: (data) => ({
    type: PCActionsTypes.UPDATE_CHECK_MICROBLADING,
    payload: { data },
  }),
  changeStoreCheckMicro: (data) => ({
    type: PCActionsTypes.UPDATE_CHECK_MICRO,
    payload: { data },
  }),
  changeStoreCheckPiercing: (data) => ({
    type: PCActionsTypes.UPDATE_CHECK_PIERCING,
    payload: { data },
  }),
  changeStoreCheckSizeTattoo: (data) => ({
    type: PCActionsTypes.UPDATE_CHECK_SIZE_TATTOO,
    payload: { data },
  }),
  changeStoreCheckCity: (data) => ({
    type: PCActionsTypes.UPDATE_CHECK_CITY,
    payload: { data },
  }),
  changeStoreCheckEmployed: (data) => ({
    type: PCActionsTypes.UPDATE_CHECK_EMPLOYED,
    payload: { data },
  }),
  changeStoreCheckTypeJewelry: (data) => ({
    type: PCActionsTypes.UPDATE_CHECK_TYPE_JEWELRY,
    payload: { data },
  }),
  deleteStoreCity: (data) => ({
    type: PCActionsTypes.DELETE_CITY,
    payload: { data },
  }),
  deleteStoreEmploye: (data) => ({
    type: PCActionsTypes.DELETE_EMPLOYE,
    payload: { data },
  }),
  deleteStoreTypeJewelry: (data) => ({
    type: PCActionsTypes.DELETE_TYPEJEWELRY,
    payload: { data },
  }),
  addStoreCity: (data) => ({
    type: PCActionsTypes.ADD_CITY,
    payload: { data },
  }),
  addStoreArtist: (data) => ({
    type: PCActionsTypes.ADD_ARTIST,
    payload: { data },
  }),
  addStoreTpyeJewelry: (data) => ({
    type: PCActionsTypes.ADD_TYPEJEWELRY,
    payload: { data },
  }),
  updateCheckLogoPC: (checkLogo) => ({
    type: PCActionsTypes.UPDATE_CHECK_LOGO,
    payload: { checkLogo },
  }),
  
 
  updateCheckAboutPC: (checkAbout) => ({
    type: PCActionsTypes.UPDATE_CHECK_ABOUT,
    payload: { checkAbout },
  }),
};

export default PCActions;
