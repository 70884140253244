import { useTranslation } from "react-i18next";

export const BudgetMockPiercing = () => {
  const [t] = useTranslation("global");

  return [
    {
      id: 24,
      id_user: 1,
      name_employed: "Equipo Inkup",
      name: t("budget.nameMock"),
      phone_budget: "+34 627778062",
      email_sent: "",
      time: "Tardes",
      email: t("budget.exampleEmailMock"),
      body_part: "Boca",
      type_jewelry: t("budget.exampleJewelryMock"),
      // skintone: t("budget.exampleSkintoneMock"),
      old_client: 1,
      body_part_site: "Simetría lengua vertical",
      state_budget: "pending",
      state_budget_tag: 1,
      assigned_by: 1,
      // media_path_budget: null,
      employed: null,
      size: null,
      budget_color: null,
      city: null,
      budget_type: 2,
      budget_created_at: t("budget.dateMock"),
      updated_at: "2023-04-21T10:34:09.000Z",
      media_path_budget:
        "https://firebasestorage.googleapis.com/v0/b/connectattoo.appspot.com/o/11688996905794?alt=media",
      disabled: false,
      checked: false,
      deleted_at: null,
    },
  ];
};
