import ExcelJS from "exceljs";
import { BodyTattooData } from "../components/Budget/components/BudgetDataElements/BodyPartTattooData";
import { BodyPiercingData } from "../components/Budget/components/BudgetDataElements/BodyPiercingData";
import ColorTattooData from "../components/Budget/components/BudgetDataElements/ColorTattooData";
import { TimeTattooData } from "../components/Budget/components/BudgetDataElements/TimeTattooData";
import { SkinTattooData } from "../components/Budget/components/BudgetDataElements/SkinTattooData";
import SizeTattooData from "../components/Budget/components/BudgetDataElements/SizeTattooData";
import { StyleTattoData } from "../components/Budget/components/BudgetDataElements/StyleTattoData";

export const convertToExcel = async (budgets, t, userProfile) => {
  let bodyPartTattooObject = BodyTattooData(t);
  let bodyPartPiercingObject = BodyPiercingData(t);
  let coloTattooObject = ColorTattooData(t);
  let timeArrayObject = TimeTattooData(t);
  let SkinTattooDataObject = SkinTattooData(t);
  let sizeTattooObject = SizeTattooData(t);
  let styleTattooObject = StyleTattoData(t);
  let bodyPartObject =
    budgets[0]?.budget_type === 1
      ? bodyPartTattooObject
      : bodyPartPiercingObject;
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Budgets");

  worksheet.columns = [
    { header: t("downloadExcel.Created"), key: "Created" },
    { header: t("downloadExcel.Name"), key: "Name" },
    { header: t("downloadExcel.Email"), key: "Email" },
    { header: t("downloadExcel.Phone"), key: "Phone" },
    { header: t("downloadExcel.Employee"), key: "Employee" },
    { header: t("downloadExcel.BodyPart"), key: "BodyPart" },
    { header: t("downloadExcel.BodyPartSite"), key: "BodyPartSite" },

    ...(userProfile?.profile?.id_rol !== 4
      ? [
          { header: t("downloadExcel.Color"), key: "Color" },
          { header: t("downloadExcel.Size"), key: "Size" },
          { header: t("downloadExcel.City"), key: "City" },
          { header: t("downloadExcel.SkinType"), key: "SkinType" },
          { header: t("downloadExcel.SkinType"), key: "StylesType" },
          { header: t("downloadExcel.AvailableTime"), key: "AvailableTime" },
          { header: t("downloadExcel.Description"), key: "Description" },
          { header: t("downloadExcel.Images"), key: "Images" },
        ]
      : [
          { header: t("downloadExcel.JewelryType"), key: "JewelryType" },
          { header: t("downloadExcel.OldCustomer"), key: "OldCustomer" },
        ]),
  ];

  worksheet.getRow(1).eachCell((cell) => {
    cell.style = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF385C" },
      },
      font: {
        bold: true,
        color: { argb: "FFFFFF" },
      },
      alignment: {
        vertical: "middle",
        horizontal: "center",
      },
      border: {
        top: { style: "thick", color: { argb: "FFFFFF" } },
        bottom: { style: "thick", color: { argb: "FFFFFF" } },
        left: { style: "thick", color: { argb: "FFFFFF" } },
        right: { style: "thick", color: { argb: "FFFFFF" } },
      },
    };
  });
  worksheet.columns.forEach((column) => {
    column.width = 20;
  });
  worksheet.eachRow((row) => {
    row.height = 30;
  });

  budgets?.forEach((budget) => {
    const images = budget?.media_path_budget;
    const color = coloTattooObject?.find(
      (data) => data?.value === budget?.budget_color
    );
    const time = timeArrayObject?.find((data) => data?.value === budget?.time);
    const skintone = SkinTattooDataObject?.find(
      (data) => data?.value === budget?.skintone
    );
    const size = sizeTattooObject?.find((data) => data?.value === budget?.size);
    const style = styleTattooObject?.find((data) => data?.value === budget?.styles);
    const bodypart = bodyPartObject?.find(
      (data) => budget?.body_part === data?.value
    );
    const bodyPartSite = bodypart?.parts?.find(
      (data) => budget?.body_part_site === data?.value
    );
    
    worksheet.addRow({
      Created: budget?.budget_created_at.slice(0, 10) || " ",
      Name: budget?.name || " ",
      Email: budget?.email || " ",
      Phone: budget?.phone_budget || " ",
      Employee: budget?.employed || " ",
      BodyPart: bodypart?.text || " ",
      BodyPartSite: bodyPartSite?.text || " ",
      Color: color?.text || " ",
      Size: size?.textSize || " ",
      City: ["false", "true", "undefined", "null"].includes(budget?.city)
        ? " "
        : budget?.city || " ",
      SkinType: skintone?.imageMessage || " ",
      StylesType: style?.text || " ",
      AvailableTime: time?.text || " ",
      Description: budget?.description || " ",
      Images: images || " ",
      JewelryType: budget?.type_jewelry || " ",
      OldCustomer:
        budget?.old_client === 0
          ? t("piercingpreviouslyData.piercingNo") || " "
          : t("piercingpreviouslyData.piercingYes") || " ",
    });
  });

  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber !== 1) {
      const fillColor = rowNumber % 2 === 0 ? "ECBAC2" : "FFFFFF";
      row.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: fillColor },
        };
        cell.font = {
          bold: true,
          color: { argb: "000000" },
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        cell.border = {
          top: { style: "thick", color: { argb: "FFFFFF" } },
          bottom: { style: "thick", color: { argb: "FFFFFF" } },
          left: { style: "thick", color: { argb: "FFFFFF" } },
          right: { style: "thick", color: { argb: "FFFFFF" } },
        };
        cell.width = 50;
        row.height = 25;
      });
    }
  });

  const blob = await workbook.xlsx.writeBuffer();
  const url = window.URL.createObjectURL(new Blob([blob]));
  return url;
};

export const handleDownloadExcel = async (budgets, t, userProfile) => {
  try {
    const budgetsActivos = budgets?.budgetsAll?.filter(
      (budget) => !budget?.disabled && budget?.deleted_at === null
    );
    const excelUrl = await convertToExcel(budgetsActivos, t, userProfile);
    const a = document.createElement("a");
    a.href = excelUrl;
    a.download = "Budgets.xlsx";
    a.click();
  } catch (error) {
    console.error("Error al descargar Excel:", error);
  }
};
