export const BudgetActionsTypes = {
  SET_LOAD_STORE_BUDGET: "SET_LOAD_STORE_BUDGET",
  DELETE_BUDGET_STORE: "DELETE_BUDGET_STORE",
  CHANGE_VALUE_BUDGET_STORE: "CHANGE_VALUE_BUDGET_STORE",
  CHANGE_BUDGET_STORE: "CHANGE_BUDGET_STORE",
  ASSIGN_EMPLOYED_BUDGET: "ASSIGN_EMPLOYED_BUDGET",
  // CLOSE_ALERT_BUDGET: "CLOSE_ALERT_BUDGET",
  ACCEPT_BUDGET: "ACCEPT_BUDGET",
  DENIED_BUDGET: "DENIED_BUDGET",
  UPDATE_TAG_BUDGET: "UPDATE_TAG_BUDGET",
  FILTER_BUDGET: "FILTER_BUDGET",
  SET_LOAD_STORE_NEXT_1000_BUDGET: "SET_LOAD_STORE_NEXT_1000_BUDGET",
};
