import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { PCOperations } from "../../../../../../redux/pageConfiguration/pcOperators";

export const PCAboutMe = ({ id, aboutMe, user, nameAssistant }) => {
  const [t] = useTranslation("global");
  const [textArea, setTextArea] = useState(aboutMe);
  const [name, setName] = useState(nameAssistant);
  const [textAreaCount, setTextAreaCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (aboutMe) {
      setTextAreaCount(aboutMe.length);
    }
    if (aboutMe) {
      dispatch(PCOperations.checkSaveAboutAction({ checkAbout: true }));
    }
  }, [aboutMe, dispatch]);

  const handleChange = (e) => {
    setTextArea(e.target.value);
    recalculate(e);
  };

  const recalculate = (e) => {
    setTextAreaCount(e.target.value.length);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    dispatch(PCOperations.updateAboutMeAction(id, textArea));
    if (textArea) {
      dispatch(PCOperations.checkSaveAboutAction({ checkAbout: true }));
    } else {
      dispatch(PCOperations.checkSaveAboutAction({ checkAbout: false }));
    }
  };
  const changeNameAssistant = async () => {

    dispatch(PCOperations.updateAssistanName(id, name));

  };

  function handleChangeName(e) {
    setName(e.target.value)

  }
  return (
    <Row>
      <Form onSubmit={onFormSubmit}>
        <Col className="mt-3 mb-2 color-text-secondary">
          <p className="mb-0">{t("information.description")}</p>
        </Col>
        <Col>
          <Form.Group id="description">
            <Form.Label
              className="label-aboutMe"
              htmlFor="characters"
            ></Form.Label>
            <Form.Control
              autoComplete="nope"
              maxLength={100}
              as="textarea"
              className="text-textarea color-text-secondary bg-input"
              onChange={handleChange}
              placeholder={t("information.placeholderTextAddDescription")}
              value={textArea !== null ? textArea : undefined}
            />
            <p className="countTextAreaPageConfiguration color-text-secondary">
              {textAreaCount}/100
            </p>
          </Form.Group>
        </Col>
        <Col className="template-button">
          <Button
            className="rounded-pill bodyDetailsBold saveImgConfiguration bg-button color-text-button"
            type="submit"
          >
            {t("information.save")}
          </Button>
        </Col>
        <Col>

          <Form.Group className="mb-3" controlId="formBasicName">

            <Col className="mt-3 mb-2 color-text-secondary">
              <p className="mb-0">{t("profile.nameBoxAssistant")}</p>
            </Col>
            <div className="div-img-text-singup">
              <Form.Control
                autoComplete="nope"
                // className="businessArtistName style-input-generic-singup"
                type="text"
                id="nameAssistantUser"
                name="name_user"
                value={nameAssistant === null ? null : name}
                onChange={handleChangeName}
                placeholder={"Lia"}
                ></Form.Control>

              {/* <Form.Control
                autoComplete="nope"
                className="inputProfile color-text-secondary bg-input"
                type="text"
                id="domain_name2a"
                name="domain_name"
                value={nameAssistant === null ? "" : nameAssistant}
                onChange={handleChangeName}
                placeholder={t("editInformation.placeholderAddress")}
              ></Form.Control> */}
            </div>
          </Form.Group>
        </Col>
        <Col className="template-button">
          <Button
            className="rounded-pill bodyDetailsBold saveImgConfiguration bg-button color-text-button"
            type="button"
            onClick={() => changeNameAssistant()}

          >
            {t("information.save")}
          </Button>
        </Col>

      </Form>
    </Row>
  );
};
