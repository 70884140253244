import React, { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { BudgetOperations } from "../../../../redux/budget/budgetOperators";
import arrowLeft from "../../../../assets/images/icon-Arrow-left.svg";
import arrowRight from "../../../../assets/images/icon-Arrow-right.svg";
import { useAccordionButton } from "react-bootstrap";

export const Pagination = ({
  budgets,
  currentItems2,
  setCurrentItems2,
  selectedIdx,
  setSelectedIdx,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const endOffset = budgets.itemOffset + budgets.itemsPerPage;
    setCurrentItems2(budgets.budgets.slice(budgets.itemOffset, endOffset));
  }, [
    budgets.itemOffset,
    budgets.itemsPerPage,
    budgets.budgets,
    setCurrentItems2,
  ]);
  const handleClick = () => {
    closedAccordion();
    setSelectedIdx(null);
  };

  const closedAccordion = useAccordionButton(selectedIdx, () => "");
  const handlePageClick = async (event) => {
    const newOffset = event.selected * budgets.itemsPerPage;
    const endOffset = newOffset + budgets.itemsPerPage;

    setCurrentItems2(budgets.budgets.slice(newOffset, endOffset));

    const newPageCount = Math.ceil(
      budgets.budgets.length / budgets.itemsPerPage
    );
    await dispatch(
      BudgetOperations.changePage(currentItems2, newOffset, newPageCount)
    );
    handleClick();
  };

  return (
    <ReactPaginate
      previousLabel={<img src={arrowLeft} alt="izquierda" />}
      nextLabel={<img src={arrowRight} alt="derecha" />}
      breakLabel={"..."}
      pageCount={budgets.pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={3}
      onPageChange={handlePageClick}
      containerClassName={"pagination justify-content-center"}
      pageClassName={"page-item"}
      pageLinkClassName={"page-link"}
      previousClassName={"page-item"}
      previousLinkClassName={"page-link"}
      nextClassName={"page-item"}
      nextLinkClassName={"page-link"}
      breakClassName={"page-item"}
      breakLinkClassName={"page-link"}
      activeClassName={"active"}
      renderOnZeroPageCount={null}
    />
  );
};
