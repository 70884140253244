import marfil from "../../../../assets/images/marfil.png";
import beige from "../../../../assets/images/beige.png";
import lightBrown from "../../../../assets/images/lightbrown.png";
import mediumBrown from "../../../../assets/images/mediumbrown.png";
import darkBrown from "../../../../assets/images/darkbrown.png";
import veryDarkBrown from "../../../../assets/images/verydarkbrown.png";

export const SkinTattooData = (t) => {
  return [
    {
      id: "marfil",
      text: `${t("SkinToneData.ivory")}`,
      value: "Marfil",
      type: "radio",
      image: marfil,
      imageMessage:
        "https://firebasestorage.googleapis.com/v0/b/connectattoo.appspot.com/o/11692867586406?alt=media",
    },
    {
      id: "beige",
      text: `${t("SkinToneData.beige")}`,
      value: "Beige",
      type: "radio",
      image: beige,
      imageMessage:
        "https://firebasestorage.googleapis.com/v0/b/connectattoo.appspot.com/o/11692867585933?alt=media",
    },
    {
      id: "moreno-claro",
      text: `${t("SkinToneData.lightBrown")}`,
      value: "Moreno claro",
      type: "radio",
      image: lightBrown,
      imageMessage:
        "https://firebasestorage.googleapis.com/v0/b/connectattoo.appspot.com/o/11692867586772?alt=media",
    },
    {
      id: "moreno-intermedio",
      text: `${t("SkinToneData.mediumBrown")}`,
      value: "Moreno intermedio",
      type: "radio",
      image: mediumBrown,
      imageMessage:
        "https://firebasestorage.googleapis.com/v0/b/connectattoo.appspot.com/o/11692867587194?alt=media",
    },
    {
      id: "moreno-oscuro",
      text: `${t("SkinToneData.darkBrown")}`,
      value: "Moreno oscuro",
      type: "radio",
      image: darkBrown,
      imageMessage:
        "https://firebasestorage.googleapis.com/v0/b/connectattoo.appspot.com/o/11692867065535?alt=media",
    },
    {
      id: "moreno-muy-oscuro",
      text: `${t("SkinToneData.veryDarkBrown")}`,
      value: "Moreno muy oscuro",
      type: "radio",
      image: veryDarkBrown,
      imageMessage:
        "https://firebasestorage.googleapis.com/v0/b/connectattoo.appspot.com/o/11692867245384?alt=media",
    },
  ];
};
