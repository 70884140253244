import ProfileActions from "./profileActions";
import AlertActions from "../alert/alertActions";
import {
  updateProfile,
  updateImageProfile,
  changePassword,
  deleteImageProfile,
  updateCheckTheme,
} from "../../helpers/profileHelper";

export const ProfileOperations = {
  updateProdile: (loginPayload) => async (dispatch) => {
    const resp = await updateProfile(loginPayload);
    if (resp.status === 200 || resp.status === 204) {
      dispatch(ProfileActions.updateProfile(loginPayload));
      dispatch(
        AlertActions.alertSuccessShowAction({
          tittle: "Actualización de la información",
          text: "Se ha realizado el cambio correctamente",
          show: true,
        })
      );
    }
  },
  updateImageProfile: (dataImage) => async (dispatch) => {
    const resp = await updateImageProfile(dataImage);
    if (resp.status === 200 || resp.status === 204) {
      dispatch(ProfileActions.updateImageProfile(dataImage));
    }
  },

  deleteImageProfileAction: (id) => async (dispatch) => {
    const response = await deleteImageProfile(id);
    if (response.status === 200 || response.status === 204) {
      dispatch(ProfileActions.deleteImageProfile(null));
    }
  },
  changePass: (data) => async (dispatch) => {
    const response = await changePassword(data);
    if (response.status === 200 || response.status === 204) {
      dispatch(
        AlertActions.alertSuccessShowAction({
          tittle: "Cambio de contraseña",
          text: "Se ha realizado el cambio correctamente",
          show: true,
        })
      );
    }
  },
  changeCheckTheme: (checkTheme, id) => async (dispatch) => {
    const response = await updateCheckTheme(checkTheme, id);
    if (response.status === 200 || response.status === 204) {
      dispatch(ProfileActions.changeStoreCheckTheme(checkTheme));
    }
  },
};
