import {currencyActionsTypes} from "./currencyActionsTypes";

export const currencyActions = {
    setCurrencies: (currencies) => ({
        type: currencyActionsTypes.SET_CURRENCIES,
        payload: currencies,
    }),
    setCurrencySelected: (currency) => ({
        type: currencyActionsTypes.SET_CURRENCY_SELECTED,
        payload: currency,
    }),
};
