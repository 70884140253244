import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import iconClosedModal from "../../../../assets/images/iconClosedModal.svg";

export const ModalDownloadExcel = ({
  show,
  handleClose,
  handleDownloadExcel,
  budgets,
  userProfile,
}) => {
  const [t] = useTranslation("global");

  const handleChange = () => {
    handleDownloadExcel(budgets, t, userProfile);
    handleClose();
  };

  return (
    <>
      <Modal
        dialogClassName="dialog-modal-download-excel"
        show={show}
        onHide={handleClose}
        contentClassName="modal-content-download-excel"
      >
        <Modal.Header className="modal-download-excel-header">
          <Modal.Title className="modal-download-excel-title">
            {t("modalDownloadExcel.title")}
          </Modal.Title>
          <img
            onClick={handleClose}
            className="icon-closed-modal-download-excel"
            src={iconClosedModal}
            alt="Icono de cierre"
          ></img>
        </Modal.Header>
        <Modal.Body>{t("modalDownloadExcel.body")}</Modal.Body>
        <div className="div-buttons-modal-budgets">
          <Button
            className="button-modal-download-excel-cancel"
            onClick={handleClose}
          >
            {t("modalDownloadExcel.buttonCancel")}
          </Button>
          <Button
            className="button-modal-download-excel"
            onClick={handleChange}
          >
            {t("modalDownloadExcel.buttonDownload")}
          </Button>
        </div>
      </Modal>
    </>
  );
};
