import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Toaster } from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { PCShared } from "../../PageConfigurationChat/components/PC_Shared/PC_Shared";
import { ButtonInkup } from "../../Button/ButtonInkup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { PCOperations } from "../../../redux/pageConfiguration/pcOperators";
import { useDispatch } from "react-redux";

export const BudgetEmpty = ({ pcInfo, budgets }) => {
  const [t] = useTranslation("global");
  const [openModal, setOpenModal] = useState(false);
  const ROOT_URL = process.env.REACT_APP_MY_URL;
  const dispatch = useDispatch();

  const copyLink = () => {
    dispatch(PCOperations.copyLinkYourAssistant(t));
  };

  return (
    <>
      <div className="d-flex centralBoxColunm budgetEmpty">
        <h2 className="text-color">{t("budgetEmpty.title")}</h2>
        <div
          className={
            budgets?.budgetsAll?.length === 0
              ? "header-be-link header-be-link-mock"
              : "header-be-link"
          }
        >
          <div className="divTextLink divTextLink-mock">
            <span className="bodyBold text-color">
              {t("information.yourLink")}&nbsp;
            </span>
            <CopyToClipboard text={`${ROOT_URL}/` + pcInfo?.domain_name}>
              <span
                onClick={() => copyLink()}
                className="bodyRegular colorLink linkShared"
              >
                {`${ROOT_URL}/` + pcInfo?.domain_name}
              </span>
            </CopyToClipboard>
          </div>
          <div className="bounce">
            <ButtonInkup
              buttonClass={"greenLargeCopyButtonlink"}
              handle={() => setOpenModal(true)}
              text={t("information.copyLink")}
            />
          </div>
          <Toaster />
          <p className="m-0 mt-2 greyText">{t("budgetEmpty.example")}</p>
        </div>
        <Modal id="asdas" className="modalShare" show={openModal}>
          <PCShared setOpenModal={setOpenModal} pcInfo={pcInfo} />
        </Modal>
      </div>
    </>
  );
};
