import { Toast, ToastContainer } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AlertOperations } from "../../redux/alert/alertOperators";
import iconCheckGreen from "../../assets/images/icon-Check-Green.svg";

export const ToastConfirmation = ({ showAlerts }) => {
  const dispatch = useDispatch();
  const closeToast = () => {
    dispatch(
      AlertOperations.alertSuccessHide({
        tittle: "",
        text: "",
        show: false,
      })
    );
  };

  return (
    <ToastContainer position="top-center" id="divToastConfirmation">
      <Toast
        translate="no"
        className="toast-confirmation m-0 d-flex "
        onClose={() => closeToast()}
        show={showAlerts.confirm?.show}
        delay={5000}
        autohide
      >
        <Toast.Header className="toast-header">
          <img src={iconCheckGreen} alt="Imagen Check"></img>
          <strong className="me-auto">{showAlerts.confirm?.tittle}</strong>
        </Toast.Header>
        <Toast.Body className="me-auto">{showAlerts.confirm?.text}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
