import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { firebaseImage } from "../../../../../utils/firebaseImage";
import iconX from "../../../../../assets/images/icon-x-circle2.svg";
import Spinner from "react-bootstrap/Spinner";

export const LogoTypeJewelry = ({
  theme,
  element,
  index,
  id,
  deleteTypeJewelry,
  onImageUpload,
}) => {
  const [t] = useTranslation("global");
  const [loading, setLoading] = useState(false);

  const inputRef = useRef();

  const uploadImage = async (e) => {
    setLoading(true);
    let enlaceUrl = await firebaseImage(e.target.files[0], id);
    if (enlaceUrl) {
      onImageUpload(enlaceUrl, element, index);
      setLoading(false);
    }
  };

  const inputRefWithButton = async () => {
    inputRef.current.click();
  };
  return (
    <div className="logoInitialEmployed">
      <div className="employed-left">
        <div className="employed-l">
          <button
            className="btn-delete-city"
            size="small"
            onClick={() => deleteTypeJewelry(element, index)}
            shape="circle"
          >
            <img
              ///Con este cambio en el calassName, se quita un warning en la consola//
              // className={theme === 1 && "filter-img"}
              className={theme === 1 ? "filter-img" : ""}
              src={iconX}
              alt="x"
            />
          </button>
          <p className="p-add-employed color-text-secondary">
            {element.type_name}
          </p>
        </div>
      </div>
      <div
        className="employed-right file-upload"
        onClick={() => inputRefWithButton()}
      >
        <p className="p-add-image text-color">
          {t("pageConfiguration.uploadImage")}
        </p>

        {loading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <>
            <p className="initial-employed color-border text-color">
              {element?.type_name?.charAt(0).toUpperCase()}
            </p>
            <input
              type="file"
              onChange={(e) => uploadImage(e, element, index)}
              ref={inputRef}
            />
          </>
        )}
      </div>
    </div>
  );
};
