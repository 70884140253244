import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import iconClosedAlert from "../../../assets/images/icons/iconClosedAlert.svg";
import { ButtonInkup } from "../../Button/ButtonInkup";
import { useDispatch } from "react-redux";
import { BudgetOperations } from "../../../redux/budget/budgetOperators";

export const BudgetModalDelete = ({
  id,
  checkedState,
  idBudgets,
  setIdBudgets,
  show,
  setShow,
  handleClose,
  handleClick,
}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  
  const changeCheck = (id) => {
    if (idBudgets.includes(id) === true) {
      let ee = idBudgets.filter((item) => item !== id);
      setIdBudgets(ee);
    } else {
      setIdBudgets((idBudgets) => [...idBudgets, id]);
    }
    dispatch(BudgetOperations.changeCheck(id));
  };
  return (
    <>

      <div key="checkbox" className="div-check-delete-budget">
        <div
          id={id}
          onClick={() => changeCheck(id)}
          label="checkbox"
          value={checkedState}
          className={
            checkedState === true
              ? "form-check-input-checked"
              : "form-check-input color-border-black"
          }
        ></div>

      </div>
      <Modal className="modalDeleteSize" show={show} onHide={handleClose}>
        <div className="alert-delete-budget m-0 d-flex">
          <div className="header-alert-budget header-alert-confirmation mb-2">
            {t("budget.titleConfirmationMessage")}
            <Button className="closedAlert" onClick={() => setShow(false)}>
              <img className="iconCheck" src={iconClosedAlert} alt="asdasd" />
            </Button>
          </div>
          <div>
            <div className="divAlertConfirmation">
              <p className="bodyRegular">{t("budget.confirmationMessage")}</p>
            </div>
          </div>
          <div className="modalDeleteBudget d-flex justify-content-end">
            <ButtonInkup
              buttonClass={"mx-3 rounded-pill blueSmallButton bodyDetailsBold "}
              handle={() => handleClose()}
              text={t("budget.cancel")}
            />
            <ButtonInkup
              buttonClass={"rounded-pill redSmallButton bodyDetailsBold"}
              handle={() => handleClick()}
              text={t("budget.delete")}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
