import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { AuthOperations } from "../../../redux/auth/authOperators";


export const Logout = ({text, to, svg, open}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const onLogout = async (e) => {
   
      dispatch(AuthOperations.logout());

    navigate("/");
  window.location.reload(true)

  };
  return (
    <>

    <NavLink onClick={onLogout} className={open ? "linkOpen" : "normal"} to={to}>
      <div>{svg}</div>
      {open ? <p>{text}</p> : null}
    </NavLink>    
    </>
  )
}