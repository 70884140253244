import { AuthActionsTypes } from "./authActionsTypes";

const AuthActions = {
  //3
  setLogin: (data) => ({
    type: AuthActionsTypes.SET_LOGIN,
    payload: { data },
  }),

  setLoadStore: (data) => ({
    type: AuthActionsTypes.SET_LOAD_STORE,
    payload: { data },
  }),
  logout: (data) => ({
    type: AuthActionsTypes.SET_LOAD_STORE,
  }),
  setError: (data) => ({
    type: AuthActionsTypes.SET_ERROR_LOGIN_STORE,
    payload: { data },
  }),
  setLoadError: (data) => ({
    type: AuthActionsTypes.SET_ERROR_REGISTER,
    payload: { data },
  }),
};

export default AuthActions;
