import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { PCOperations } from "../../../../../redux/pageConfiguration/pcOperators";
import { AddCity } from "./PC_AddCity";

export const PCCity = ({ theme, PCInformation }) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
  let lengthCities = PCInformation.pcCity.length;

  const checkCity = async () => {
    if (PCInformation.PCInformation.check_city === 0) {
      dispatch(
        PCOperations.changeCheckCity(
          1,
          PCInformation.PCInformation.id_user,
          lengthCities,
          ""
        )
      );
    }
    if (PCInformation.PCInformation.check_city === 1) {
      dispatch(
        PCOperations.changeCheckCity(
          0,
          PCInformation.PCInformation.id_user,
          lengthCities,
          ""
        )
      );
    }
  };

  return (
    <>
      <div className="availabilityCheck">
        <div className="div-options-form">
          <Form.Check
            onClick={() => checkCity()}
            type="switch"
            id="custom-switch"
            style={{ height: "2em !important" }}
            className="switch-schedule"
            text="Selecciona si tu negocio dispone de turno intensivo o partido"
            checked={PCInformation.PCInformation.check_city}
            readOnly
          />
          <span className="text-color">
            {t("pageConfiguration.optionsFormCity")}
          </span>
        </div>
      </div>
      <div className="color-text-secondary">
        {t("pageConfiguration.optionsFormTextCity")}
      </div>
      {PCInformation.PCInformation.check_city === 1 && (
        <AddCity theme={theme} PCInformation={PCInformation} />
      )}
    </>
  );
};
