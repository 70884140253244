import { AlertActionsTypes } from "./alertActionsTypes";
import { initialStateAlerts } from "../state/initialState";

export const alertReducer = (state = initialStateAlerts, action) => {
  switch (action.type) {
    case AlertActionsTypes.ALERT_SUCCESS_SHOW:
      return {
        ...state,
        confirm: action.payload.data 
      };
      case AlertActionsTypes.ALERT_SUCCESS_HIDE:
        return {
          ...state,
          confirm: action.payload.data 
        };
    default:
      return state;
  }
};
