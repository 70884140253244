import React from "react";
import { NavLink } from "react-router-dom";
import "../../../../assets/styles/css/index.css";

const Item = ({ text, to, svg, open, budget }) => {
  return (
    <>
      <NavLink className={open ? "linkOpen" : "normal"} to={to}>
        {svg}
        {open ? <p>{text}</p> : null}
        {/* {(text === "Solicitudes" || text === "Requests") && budget !== 0 && (
          <p className="budget-info">{budget}</p>
        )} */}
      </NavLink>
    </>
  );
};
export default Item;
