import { AuthActionsTypes } from "./authActionsTypes";
import { initialAuthState } from "../state/initialState";

export const authReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case AuthActionsTypes.SET_LOGIN:
      return {
        ...state,
        user: action.payload.data.user.user[0],
        token: action.payload.data.token,
        loggedIn: true,
      };
    case AuthActionsTypes.SET_ERROR_LOGIN_STORE:
      return {
        ...state,
        error: action.payload.data,
      };
    case AuthActionsTypes.SET_ERROR_REGISTER:
      return {
        ...state,
        error: action.payload.data,
      };
    default:
      return state;
  }
};
