import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../../components/Login/Login";
import SignUp from "../../components/SignUp/SignUp";
import ForgotPassword from "../../components/ForgotPassword/ForgotPassword";
import { Container } from "react-bootstrap";

export default function PublicRoute() {
  return (
    <Container fluid className="paddingContainerFluidRoutesPublic">
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="*" element={<Login />} />
      </Routes>
    </Container>
  );
}