import React from "react";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";

import iconSearch from "../../../assets/images/icon-Search.svg";

export const BudgetSearch = ({ input, hadleSearch, value, handleChange }) => {
  const [t] = useTranslation("global");

  return (
    <Col sm={12} className="col-input-search-select">
      <div className="div-input-search-select">
        <div className="div-input-search">
          <img src={iconSearch} alt="Icono búsqueda"></img>
          <input
            className="bg-input"
            type="text"
            value={input}
            placeholder={t("budgetAccordion.accordionSearch")}
            onChange={(e) => hadleSearch(e)}
          />
        </div>
        <div className="div-input-select">
          <select
            className="bg-input color-text-secondary ruben"
            value={value}
            onChange={handleChange}
          >
            <option value="phone_budget">
              {t("budgetAccordion.accordionSelectSearchPhone")}
            </option>
            <option value="name">
              {t("budgetAccordion.accordionSelectSearchName")}
            </option>
            <option value="employed">
              {t("budgetAccordion.accordionSelectSearchEmployed")}
            </option>
          </select>
        </div>
      </div>
    </Col>
  );
};
