export const stylesAplication = async (modeJson) => {
  const style = document.documentElement.style;
  style.setProperty("--color-text", modeJson.color_text);
  style.setProperty("--color-text-white", modeJson.color_text_white);
  style.setProperty("--color-text-secondary", modeJson.color_text_secondary);
  style.setProperty("--bg-input", modeJson.bg_input);
  style.setProperty("--bg-button", modeJson.bg_button);
  style.setProperty("--color-text-button", modeJson.color_text_button);
  style.setProperty("--color-shadow", modeJson.color_shadow);
  style.setProperty("--color-hr", modeJson.color_hr);
  style.setProperty("--color-border", modeJson.color_border);
  style.setProperty("--color-border-black", modeJson.color_border_black);
  style.setProperty("--bg-mode", modeJson.bg_mode);
  style.setProperty("--bg-container", modeJson.bg_container);
};
