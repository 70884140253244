import { BudgetActionsTypes } from "./budgetActionsTypes";
import { initialStateBudget } from "../state/initialState";
import { setBudgetFree } from "../../utils/budgetbloqued";
import { budgetSearch } from "../../utils/budgetSearch";

const budgetBloquedRequest = (budget, user) => {
  const oo = setBudgetFree(budget.budget, user.user[0].premium);
  return oo;
};
const budgetBloquedRequestFilter = (budget, user) => {
  const oo = budgetSearch(budget, user.profile.premium);
  return oo;
};
const eee = (data) => {
  const result = data?.budget?.filter(
    (word) =>
      word.state_budget === "pending" && (word.deleted_at === null) | undefined
  );
  return result.length;
};
const changeTag = (state, payload) => {
  const newProjects = state?.map((p) =>
    p.id === payload.state_budget_tag.budgetId
      ? {
          ...p,
          state_budget_tag: payload.state_budget_tag.state_budget_tag,
        }
      : p
  );
  return newProjects;
};
const budgetCountMoth = (data, user) => {
  let now = new Date();
  let arr = [];

  let year = now.toISOString().split("-")[0];
  let moth = now.toISOString().split("-")[1];
  data?.budget?.filter((word) => {
    let date = word.budget_created_at.split("-");

    if (date[0] === year && date[1] === moth && word.disabled === false) {
      arr.push(word);
      return true;
    }
    return false;
  });
  let countBudget;
  if (user.user[0].premium === 0) {
    countBudget = 11 - arr.length;
  }
  if (user.user[0].premium === 1) {
    countBudget = 40 - arr.length;
  }

  return countBudget;
};
const deleteCount = (data) => {
  const result = data?.filter((word) => word.state_budget === "pending");
  return result.length;
};
const deleteBudgets = (idArray, data) => {
  const result = data.filter((bgt) => bgt.id !== idArray);
  return result;
};
const pending = (budget, user) => {
  const oo = setBudgetFree(budget.budget, user.user[0].premium);
  const result = oo.filter((word) => word.state_budget_tag === 1);
  return result.length;
};
const changeValue = (id, state) => {
  const rows = state.budgets.map((li) =>
    li.id === +id ? { ...li, checked: !li.checked } : li
  );
  return rows;
};
const calculatePremiumLimit = (premium, filteredBudgets) => {
  switch (premium) {
    case 0:
      return Math.max(0, 11 - filteredBudgets.length);
    case 1:
      return Math.max(0, 40 - filteredBudgets.length);
    default:
      return 10;
  }
};
const budgetLimit = (data, user) => {
  const premium = user.user[0].premium;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const filteredBudgets = data.budget.filter((budget) => {
    const createdAt = new Date(budget.budget_created_at);
    const createdYear = createdAt.getFullYear();
    const createdMonth = createdAt.getMonth() + 1;

    return createdYear === currentYear && createdMonth === currentMonth;
  });
  const limitValue = calculatePremiumLimit(premium, filteredBudgets);
  return limitValue;
};
export const budgetReducer = (state = initialStateBudget, action) => {
  switch (action.type) {
    case BudgetActionsTypes.SET_LOAD_STORE_BUDGET:
      return {
        ...state,
        budgetsAll: action.payload.data.budget,
        budgets: budgetBloquedRequest(action.payload.data, action.payload.user),
        budgetsPage: budgetBloquedRequest(
          action.payload.data,
          action.payload.user
        ).slice(0, 10 + 0),
        pageCount: Math.ceil(
          budgetBloquedRequest(action.payload.data, action.payload.user)
            .length / 10
        ),
        budgetsCount: eee(action.payload.data, action.payload.user),
        budgetLimit: budgetLimit(action.payload.data, action.payload.user),
        budgetsPending: pending(action.payload.data, action.payload.user),
        budgetsCountMoth: budgetCountMoth(
          action.payload.data,
          action.payload.user
        ),
        employeds: action.payload.data.employeds,
        itemOffset: 0,
        itemsPerPage: 10,
      };
    case BudgetActionsTypes.SET_LOAD_STORE_NEXT_1000_BUDGET:

      const newBudgets = action.payload.data.budget;
      let combinedBudgetsAll;

      if (state.budgetsAll.length % 1000 === 0) {
        combinedBudgetsAll = [...state.budgetsAll, ...newBudgets];
      } else {
        // Si no es divisible entre 1000, volvemos a los primeros 1000 budgets
        combinedBudgetsAll = newBudgets.slice(0, 1000);
      }

      return {
        ...state,
        budgetsAll: combinedBudgetsAll,
        budgets: budgetBloquedRequest(action.payload.data, action.payload.user),
        budgetsPage: budgetBloquedRequest(
          action.payload.data,
          action.payload.user
        ).slice(0, 10 + 0),
        pageCount: Math.ceil(
          budgetBloquedRequest(action.payload.data, action.payload.user)
            .length / 10
        ),
        budgetsCount: eee(action.payload.data, action.payload.user),
        budgetLimit: budgetLimit(action.payload.data, action.payload.user),
        budgetsPending: pending(action.payload.data, action.payload.user),
        budgetsCountMoth: budgetCountMoth(
          action.payload.data,
          action.payload.user
        ),
        employeds: action.payload.data.employeds,
        itemOffset: 0,
        itemsPerPage: 10,
      };
    case BudgetActionsTypes.CHANGE_BUDGET_STORE:
      return {
        ...state,
        budgetsPage: action.data,
        pageCount: action.newPageCount,
        itemOffset: action.newOffset,
      };
    case BudgetActionsTypes.CHANGE_VALUE_BUDGET_STORE:
      return {
        ...state,
        budgets: changeValue(action.data, state),
      };
    case BudgetActionsTypes.DELETE_BUDGET_STORE:
      return {
        ...state,
        budgets: action.data,
        budgetsAll: deleteBudgets(action.idArray, state.budgetsAll),
        budgetsCount: deleteCount(action.data),
      };
    case BudgetActionsTypes.ASSIGN_EMPLOYED_BUDGET:
      return {
        ...state,
        budgets: action.aaa,
        budgetsPage: action.eee,
        budgetsCount: action.budgetsCount.length,
      };

    case BudgetActionsTypes.ACCEPT_BUDGET:
      return {
        ...state,
        budgets: action.budgetsPageStore,
        budgetsPage: action.newBudgetsPage,
        budgetsCount: deleteCount(action.budgetsPageStore),
        alertAccept: true,
      };
    case BudgetActionsTypes.DENIED_BUDGET:
      return {
        ...state,
        budgets: action.budgetsPageStore,
        budgetsPage: action.newBudgetsPage,
        budgetsCount: eee(action.payload.data),
        alertAccept: true,
      };
    case BudgetActionsTypes.UPDATE_TAG_BUDGET:
      return {
        ...state,
        budgets: changeTag(state.budgets, action.payload),
      };
    case BudgetActionsTypes.FILTER_BUDGET:
      return {
        ...state,
        budgets: budgetBloquedRequestFilter(
          action.payload.result,
          action.payload.user
        ),
        budgetsPage: budgetBloquedRequestFilter(
          action.payload.result,
          action.payload.user
        ).slice(0, 10 + 0),
        pageCount: Math.ceil(
          budgetBloquedRequestFilter(action.payload.result, action.payload.user)
            .length / 10
        ),
      };
    default:
      return state;
  }
};
