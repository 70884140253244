import {initialCurrenciesState} from "../state/initialState";
import {currencyActionsTypes} from "./currencyActionsTypes";

export const currencySelectedSelector = (state) => state.currencies.selected;
export const currenciesSelector = (state) => state.currencies.all;

export const currencyReducer = (state = initialCurrenciesState, action) => {
    switch (action.type) {
        case currencyActionsTypes.SET_CURRENCIES:
            return {...state, all: [...action.payload]}
        case currencyActionsTypes.SET_CURRENCY_SELECTED:
            return {...state, selected: action.payload}
        default:
            return state;
    }
}