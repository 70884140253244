import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Form} from "react-bootstrap";
import {PCOperations} from "../../../../../redux/pageConfiguration/pcOperators";

export const PCCentimeters = ({PCInformation}) => {
    const [t] = useTranslation("global");
    const dispatch = useDispatch();

    const [canShowCentimeters, setCanShowCentimeters] = useState(!PCInformation.PCInformation.check_cm);

    const toggleCheckCentimeters = async () => {
        const canShow = !canShowCentimeters;
        await dispatch(PCOperations.updatePartialCorporation({
                id_corporation: PCInformation.PCInformation.id_corporation,
                check_cm: canShow,
            })
        );
        setCanShowCentimeters(canShow)
    };

    return (
        <>
            <div className="availabilityCheck">
                <div className="div-options-form">
                    <Form.Check
                        onChange={() => toggleCheckCentimeters()}
                        type="switch"
                        style={{height: "2em !important"}}
                        className="switch-schedule"
                        checked={canShowCentimeters}
                    />
                    <span className="text-color">
            {t("pageConfiguration.show_centimeters")}
          </span>
                </div>
            </div>
        </>
    );
};
