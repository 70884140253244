import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Offcanvas,
  Nav,
  Navbar,
  Col,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  // Link1,
  Link2,
  Link5,
  Link6,
  LogoutButton,
} from "../Sidebar/components/Data/data";
import { Item } from "./Item/Item";
import { Logout } from "./components/logout";
import logoInkupSidebar from "../../assets/images/logoInkupSidebar.svg";
import iconClosedAlert from "../../assets/images/icons/iconClosedAlert.svg";
import logoInkupNavbarWhite from "../../assets/images/logoInkupNavbarWhite.svg";
import iconAvatar from "../../assets/images/iconAvatar.svg";
import iconArrow from "../../assets/images/icons/iconChevronRight.svg";
import "../../assets/styles/css/index.css";

export const NavbarMenu = ({ state }) => {
  const [open] = useState(true);
  const [show, setShow] = useState(false);
  const [t] = useTranslation("global");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Col
      sm={12}
      translate="no"
      className="NavbarMain navbarPosition pe-0 d-md-none bg-navbar fontFamilyApp"
    >
      <Navbar collapseOnSelect bg="#FCFCFC" expand="lg">
        <Container className="containerNavbarStudio">
          <div className="navbarButtons">
            <Navbar.Brand href="/presupuestos" className="logoTypographic">
              <img src={logoInkupNavbarWhite} alt="img" />
            </Navbar.Brand>
            <Navbar.Toggle className="navbar-dark" onClick={handleShow} />
          </div>
          {["end"].map((placement, idx) => (
            <Offcanvas
              key={idx}
              placement={placement}
              show={show}
              onHide={handleClose}
              className="offCanvasMain"
            >
              <Offcanvas.Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px 16px 0 16px' }}>
                <img src={logoInkupSidebar} alt="img" style={{ maxHeight: '40px' }} />
                <button 
                  className="noStyle" 
                  onClick={handleClose} 
                  style={{ background: 'none', border: 'none', padding: '0', cursor: 'pointer' }}
                >
                  <img
                    className="blueFilter"
                    src={iconClosedAlert}
                    alt="img"
                  />
                </button>
              </Offcanvas.Header>
              <div>
                <div className="divAccountProfile pt-5">
                  <div className="divAccountProfile">
                    <Link to="/perfil">
                      <Button
                        onClick={handleClose}
                        type="button"
                        className="userButton userButtonNavbar"
                      >
                        {state.user.imageProfile?.path_media ? (
                          <img
                            className="avatar avatar-48 rounded-circle p-2 userImage"
                            src={state.user.imageProfile?.path_media}
                            alt="phot-user"
                          />
                        ) : (
                          <img
                            className="avatar avatar-48 rounded-circle p-2 userImage"
                            src={iconAvatar}
                            alt="phot-user"
                          />
                        )}
                      </Button>
                    </Link>
                    <div onClick={handleClose} className="userData">
                      <div>
                        <Link className="LinkUser" to="/perfil">
                          <h5 className="text-center headingH5 mb-0">
                            {state?.pageConfiguration?.PCInformation?.studio_name}
                          </h5>
                          <p className="bodySubcontentBold greyText mb-1">
                            {t("sideboard.account")}
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <Link to="/perfil">
                    <button
                      onClick={handleClose}
                      className="noStyle greyFilter p-3"
                    >
                      <img src={iconArrow} alt="Icono Flecha"></img>
                    </button>
                  </Link>
                </div>

                {/* <div
                  onClick={handleClose}
                  className="justify-content-center bodySubcontentBold"
                >
                  {Link1.map(({ text, to, svg }, index) => (
                    <Nav.Link key={index} className="p-0" href="#">
                      <Item
                        key={text}
                        open={open}
                        to={to}
                        text={t("sideboard.home")}
                        svg={svg}
                      >
                        {text}
                      </Item>
                    </Nav.Link>
                  ))}
                </div> */}
                <div
                  onClick={handleClose}
                  className="justify-content-center bodySubcontentBold"
                >
                  {Link6.map(({ text, to, svg }, idx) => (
                    <Nav.Link key={idx} className="p-0" href="#">
                      <Item
                        key={text}
                        open={open}
                        to={to}
                        text={t("sideboard.pageConfiguration")}
                        svg={svg}
                      >
                        {text}
                      </Item>
                    </Nav.Link>
                  ))}
                </div>
                <div
                  onClick={handleClose}
                  className="justify-content-center bodySubcontentBold"
                >
                  {Link2.map(({ text, to, svg }, idx) => (
                    <Nav.Link key={idx} className="p-0" href="#">
                      <Item
                        key={text}
                        open={open}
                        to={to}
                        text={t("sideboard.requests")}
                        svg={svg}
                      >
                        {text}
                      </Item>
                    </Nav.Link>
                  ))}
                </div>
                <div
                  onClick={handleClose}
                  className="linksDown bodySubcontentBold "
                >
                  {Link5.map(({ text, to, svg }, idx) => (
                    <Nav.Link key={idx} className="p-0" href="#">
                      <Item
                        key={text}
                        open={open}
                        to={to}
                        text={t("sideboard.suggestion")}
                        svg={svg}
                      >
                        {text}
                      </Item>
                    </Nav.Link>
                  ))}
                  {LogoutButton.map(({ text, to, svg }, idx) => (
                    <Logout
                      key={idx}
                      open={open}
                      to={to}
                      text={t("sideboard.logout")}
                      svg={svg}
                    >
                      {text}
                    </Logout>
                  ))}
                </div>
              </div>
            </Offcanvas>
          ))}
        </Container>
      </Navbar>
    </Col>
  );
};
