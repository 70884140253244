import {BsPencilSquare, BsX} from "react-icons/bs";

export const Icon = ({iconName}) => {
    switch (iconName) {
        case "edit":
            return <BsPencilSquare/>;
        case "close":
            return <BsX/>;
        default:
            return null;
    }
}