import axios from "axios";

const ROOT_URL = process.env.REACT_APP_API_URL;

export const deletedBudget = async ({ id }) => {
  try {
    return await axios.put(`${ROOT_URL}/api/budget/deleteBudget`, {
      id,
    });
  } catch (error) {
    return false;
  }
};
export const sendAcceptBudget = async (data) => {
  try {
    return await axios.put(`${ROOT_URL}/api/budget/acceptBudget`, {
      budget: data.budget,
      from: data.from,
      until: data.until,
      comments: data.comments,
    });
  } catch (error) {
    return false;
  }
};
export const sendDenegedBudget = async (budgetPayload) => {
  try {
    return await axios.put(
      `${ROOT_URL}/api/budget/denegedBudget`,
      budgetPayload
    );
  } catch (error) {
    return false;
  }
};
export const updateTagBudget = async (state_budget_tag, budgetId) => {
  try {
    return await axios.put(
      `${ROOT_URL}/api/budget/updateTagBudget`,
      state_budget_tag,
      budgetId
    );
  } catch (error) {
    return false;
  }
};
export const loadStore = async (id) => {
  try {
    return await axios.post(`${ROOT_URL}/api/loadStore`, { id });
  } catch (error) {
    return false;
  }
};

export const loadStoreNext1000Budgets = async (id, offset) => {
  try {
    return await axios.post(`${ROOT_URL}/api/loadStoreNext1000Budgets`, {
      id,
      offset,
    });
  } catch (error) {
    return false;
  }
};
