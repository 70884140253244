/* eslint-disable default-case */
import { PCActionsTypes } from "./pcActionsTypes";
import { initialStateINFO } from "../state/initialState";

export const corporationIdSelector =  (state) => state.pageConfiguration.PCInformation?.id_corporation;
export const corporationSelector = (state) => state.pageConfiguration.PCInformation;

export const pcReducer = (state = initialStateINFO, action) => {
   switch (action.type) {
    case PCActionsTypes.SET_LOAD_STORE_PAGE_CONFIGURATION:
      return {
        ...state,
        PCInformation: action.payload?.data?.resultInformation[0],
        pCTemplates: action.payload?.data?.resultTemplate[0],
        pCLogo: action.payload?.data?.resultLogo[0],
        pCCover: action.payload?.data?.resultCover[0],
        pCPortfolio: action.payload?.data?.resultPortfolio,
        pCEmployed: action.payload?.data?.resultEmployed,
        pCInvitations: action.payload?.data?.resultInvitation,
        pcSchedule: action.payload?.data?.resultSchedule,
        pcCity: action.payload?.data?.resultCity,
        pcServices: action.payload?.data?.resultService,
        pcTypeJewelry: action.payload?.data?.resultTypeJewelry,
      };

      case PCActionsTypes.UPDATE_ASSISTANT_ME:
        return {
          ...state,
          PCInformation: {
            ...state.PCInformation,
            about_me: action.payload.data,
          },
        };
    case PCActionsTypes.UPDATE_ABOUT_ME:
      return {
        ...state,
        PCInformation: {
          ...state.PCInformation,
          about_me: action.payload.data,
        },
      };
    case PCActionsTypes.UPDATE_NUMBERS_SERVICES:
      return {
        ...state,
        PCInformation: {
          ...state.PCInformation,
          phone_laser: action.payload.phone_laser,
          phone_barber: action.payload.phone_barber,
          phone_micro: action.payload.phone_micro,
          phone_piercing: action.payload.phone_piercing,
          phone_microblading: action.payload.phone_microblading,
          phone_gem_tooth: action.payload.phone_gem_tooth,
        },
      };
    case PCActionsTypes.UPDATE_CORP_INFORMATION:
      return {
        ...state,
        PCInformation: action.payload.data,
      };






    case PCActionsTypes.UPDATE_SKINTONE:
      return {
        ...state,
        PCInformation: {
          ...state.PCInformation,
          check_skintone: action.payload.data,
        },
      };
    case PCActionsTypes.UPDATE_CHECK_TYPE_JEWELRY:
      return {
        ...state,
        PCInformation: {
          ...state.PCInformation,
          check_typejewelry: action.payload.data,
        },
      };
    case PCActionsTypes.UPDATE_AVAILABILITY:
      return {
        ...state,
        PCInformation: {
          ...state.PCInformation,
          time_availability: action.payload.data,
        },
      };
    case PCActionsTypes.UPDATE_CHECK_CITY:
      return {
        ...state,
        PCInformation: {
          ...state.PCInformation,
          check_city: action.payload.data,
        },
      };
    case PCActionsTypes.UPDATE_CHECK_EMPLOYED:
      return {
        ...state,
        PCInformation: {
          ...state.PCInformation,
          check_employeds: action.payload.data,
        },
      };
    case PCActionsTypes.UPDATE_CHECK_TATTOO:
      return {
        ...state,
        PCInformation: {
          ...state.PCInformation,
          check_tattoo: action.payload.data,
        },
      };
    case PCActionsTypes.UPDATE_CHECK_LASER:
      return {
        ...state,
        PCInformation: {
          ...state.PCInformation,
          check_laser: action.payload.data,
        },
      };
    case PCActionsTypes.UPDATE_CHECK_BARBER:
      return {
        ...state,
        PCInformation: {
          ...state.PCInformation,
          check_barber: action.payload.data,
        },
      };
    case PCActionsTypes.UPDATE_CHECK_GEM_TOOTH:
      return {
        ...state,
        PCInformation: {
          ...state.PCInformation,
          check_gem_tooth: action.payload.data,
        },
      };
    case PCActionsTypes.UPDATE_CHECK_MICROBLADING:
      return {
        ...state,
        PCInformation: {
          ...state.PCInformation,
          check_microblading: action.payload.data,
        },
      };
    case PCActionsTypes.UPDATE_CHECK_MICRO:
      return {
        ...state,
        PCInformation: {
          ...state.PCInformation,
          check_micro: action.payload.data,
        },
      };
    case PCActionsTypes.UPDATE_CHECK_PIERCING:
      return {
        ...state,
        PCInformation: {
          ...state.PCInformation,
          check_piercing: action.payload.data,
        },
      };
    case PCActionsTypes.UPDATE_CHECK_SIZE_TATTOO:
      return {
        ...state,
        PCInformation: {
          ...state.PCInformation,
          check_size_tattoo: action.payload.data,
        },
      };
    case PCActionsTypes.DELETE_CITY:
      return {
        ...state,
        pcCity: state.pcCity.filter(
          (item, index) => index !== action.payload.data
        ),
      };
    case PCActionsTypes.DELETE_EMPLOYE:
      return {
        ...state,
        pCEmployed: state.pCEmployed.filter(
          (element, index) => index !== action.payload.data
        ),
      };
    case PCActionsTypes.DELETE_TYPEJEWELRY:
      return {
        ...state,
        pcTypeJewelry: state.pcTypeJewelry.filter(
          (element, index) => index !== action.payload.data
        ),
      };
    case PCActionsTypes.ADD_CITY:
      return {
        ...state,
        pcCity: action.payload.data,
      };
    case PCActionsTypes.ADD_ARTIST:
      return {
        ...state,
        pCEmployed: action.payload.data,
      };
    case PCActionsTypes.ADD_TYPEJEWELRY:
      return {
        ...state,
        pcTypeJewelry: action.payload.data,
      };
    case PCActionsTypes.UPDATE_CHECK_LOGO:
      return {
        ...state,
        pcCheck: {
          ...state.pcCheck,
          CheckLogo: action.payload.checkLogo,
        },
      };
    case PCActionsTypes.UPDATE_CHECK_ABOUT:
      return {
        ...state,
        pcCheck: {
          ...state.pcCheck,
          checkAbout: action.payload.checkAbout,
        },
      };
    default:
      return state;
  }
};
