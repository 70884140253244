import { BudgetActionsTypes } from "./budgetActionsTypes";

const BudgetActions = {
  setLoadStoreBudget: (data, user) => ({
    type: BudgetActionsTypes.SET_LOAD_STORE_BUDGET,
    payload: { data, user },
  }),
  setLoadStoreNext1000Budgets: (data, user) => ({
    type: BudgetActionsTypes.SET_LOAD_STORE_NEXT_1000_BUDGET,
    payload: { data, user },
  }),
  deleteBudgetStore: (idArray, data) => ({
    type: BudgetActionsTypes.DELETE_BUDGET_STORE,
    idArray,
    data,
  }),
  changeValueBudgetStore: (data) => ({
    type: BudgetActionsTypes.CHANGE_VALUE_BUDGET_STORE,
    data,
  }),
  updateTagBudgetStore: (budgetId, state_budget_tag) => ({
    type: BudgetActionsTypes.UPDATE_TAG_BUDGET,
    payload: { budgetId, state_budget_tag },
  }),
  changeBudgetStore: (data, newOffset, newPageCount) => ({
    type: BudgetActionsTypes.CHANGE_BUDGET_STORE,
    data,
    newOffset,
    newPageCount,
  }),
  assingEmployedBudgetStore: (aaa, eee, budgetsCount) => ({
    type: BudgetActionsTypes.ASSIGN_EMPLOYED_BUDGET,
    aaa,
    eee,
    budgetsCount,
  }),
  // closeAlert: (close) => ({
  //   type: BudgetActionsTypes.CLOSE_ALERT_BUDGET,
  //   close,
  // }),
  acceptBudget: (budgetsPageStore, newBudgetsPage) => ({
    type: BudgetActionsTypes.ACCEPT_BUDGET,
    budgetsPageStore,
    newBudgetsPage,
  }),
  deniedBudget: (budgetsPageStore, newBudgetsPage) => ({
    type: BudgetActionsTypes.DENIED_BUDGET,
    budgetsPageStore,
    newBudgetsPage,
  }),
  filterBudgetStore: (result, user) => ({
    type: BudgetActionsTypes.FILTER_BUDGET,
    payload: { result, user },
  }),
};

export default BudgetActions;
