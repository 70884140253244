import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PCOperations } from "../../../../../redux/pageConfiguration/pcOperators";
import { LogoArtist } from "./PC_LogoArtist";
import iconPlusCircle from "../../../../../assets/images/icons/icon-Plus-circle.svg";
import iconX from "../../../../../assets/images/icon-x-circle2.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Spinner from "react-bootstrap/Spinner";

export const AddArtist = ({ theme, PCInformation, id }) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
  const [artist, setArtist] = useState("");
  const [loading, setLoading] = useState(false);
  let lengthEmployeds = PCInformation.pCEmployed.length;

  const deleteEmployed = async (id_employed, index) => {
    dispatch(PCOperations.deleteEmploye(id_employed, index));

    if (lengthEmployeds === 1) {
      dispatch(
        PCOperations.changeCheckEmployed(
          0,
          PCInformation.PCInformation.id_user,
          lengthEmployeds,
          ""
        )
      );
    }
  };

  const handleImageUpload = async (imageUrl, employed, index) => {
    const updatedList = [...PCInformation.pCEmployed];
    updatedList[index] = { ...updatedList[index], image: imageUrl };
    dispatch(PCOperations.updateImageEmployed(imageUrl, employed));
  };

  const handleSubmit = () => {
    const newTodo = { name: artist };
    if (newTodo.name !== "") {
      dispatch(
        PCOperations.addArtist(newTodo, PCInformation.PCInformation.id_user,t)
      );

      if (lengthEmployeds === 0) {
        dispatch(
          PCOperations.changeCheckEmployed(
            1,
            PCInformation.PCInformation.id_user,
            lengthEmployeds,
            newTodo
          )
        );
      }
    }
    setArtist("");
  };

  const deleteImage = async (e, employed) => {
    dispatch(PCOperations.deleteImageEmployed(employed));
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(PCInformation.pCEmployed);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setLoading(true);

    dispatch(PCOperations.saveArtistList(items));

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      {loading ? (
        <div className="cityList">
          <div className="spinner-container">
            <Spinner animation="border" variant="primary" />
          </div>
        </div>
      ) : (
        <div className="todo-list">
          <div className="file-input">
            <p className="cityAndStudio color-text-secondary">
              {" "}
              {t("pageConfiguration.optionsFormArtistP")}
            </p>
            <div className="div-input-add-city">
              <input
                onChange={(e) => setArtist(e.target.value)}
                value={artist === null ? "" : artist}
                className="inputCity bg-input color-text-secondary"
                id="task"
                type="text"
                name="task"
                placeholder={t("pageConfiguration.placeholderArtistName")}
              />
              <button className="buttonCityAdd" onClick={() => handleSubmit()}>
                <img
                  className={theme === 1 ? "no-filter-img" : ""}
                  src={iconPlusCircle}
                  alt="buttonADD"
                />
              </button>
            </div>
            {PCInformation?.pCEmployed?.length !== 0 && (
              <>
                <p className="p-add-artist color-text-secondary">
                  {t("pageConfiguration.addedArtists")}
                  {t("pageConfiguration.orderArtist")}
                </p>
              </>
            )}
          </div>
          <div className="cityList">
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="artistList">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {PCInformation?.pCEmployed?.map((element, index) => (
                      <Draggable
                        key={index}
                        draggableId={`artist-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="cityElementList color-border div-margin-padding"
                          >
                            {element.image ? (
                              <div className="logoInitialEmployed">
                                <div className="employed-left">
                                  <div className="employed-l">
                                    <button
                                      className="btn-delete-city"
                                      size="small"
                                      onClick={() =>
                                        deleteEmployed(element, index)
                                      }
                                      shape="circle"
                                    >
                                      <img
                                        className={
                                          theme === 1 ? "filter-img" : ""
                                        }
                                        src={iconX}
                                        alt="x"
                                      />
                                    </button>
                                    <p
                                      translate="no"
                                      className="p-add-employed color-text-secondary"
                                    >
                                      {element.name}
                                    </p>
                                  </div>
                                </div>
                                <div className="employed-right">
                                  <div
                                    className="employed-right file-upload"
                                    onClick={(e) => deleteImage(e, element)}
                                  >
                                    <p className="p-add-image text-color m-2">
                                      {t(
                                        "pageConfiguration.deleteImagePCAddArtist"
                                      )}
                                    </p>
                                  </div>
                                  <img
                                    className="image-employed object-artist"
                                    src={element?.image}
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : (
                              <LogoArtist
                                theme={theme}
                                element={element}
                                index={index}
                                deleteEmployed={deleteEmployed}
                                onImageUpload={handleImageUpload}
                                id={id}
                              />
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      )}
    </>
  );
};
