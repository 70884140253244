import axios from "axios";
import { verifyToken } from "../services/verifyTokenService";
var CryptoJS = require("crypto-js");

const ROOT_URL = process.env.REACT_APP_API_URL;

export const hasError = (status) => status >= 400 && status <= 600;

export const saveAuthUser = async (user, token) => {
  let tokenEncript = await encript(JSON.stringify(token));
  let userEncript = await encript(JSON.stringify(user));
  localStorage.setItem("token", tokenEncript);
  localStorage.setItem("user", userEncript);
};

export const logoutUser = async () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

export const encript = async (data) => {
  // Encrypt
  var ciphertext = await CryptoJS.AES.encrypt(
    data,
    process.env.REACT_APP_CODIFICATION
  ).toString();
  return ciphertext;
};

export const decode = (data) => {
  // Decrypt
  var bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_CODIFICATION);
  var originalText = bytes.toString(CryptoJS.enc.Utf8);

  return originalText;
};

export const getAuthUser = () => {
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("token");

  if (user != null && token != null) {
    let userDecode = decode(user);
    let userObj = JSON.parse(userDecode);
    return !user || !token || isEmpty(user) || isEmpty(token)
      ? null
      : { user, token, userObj };
  }
};
export const getAuthUser2 = () => {
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("token");

  if (user != null && token != null) {
    let userDecode = decode(user);
    let userObj = JSON.parse(userDecode);
    return !user || !token || isEmpty(user) || isEmpty(token)
      ? null
      : { user, token, userObj };
  }
};
export const getAuthUserToken = () => getAuthUser()?.token;
export const getAuthIdUser = () => getAuthUser()?.userObj;

export const clearStorage = () => localStorage.clear();

export const falsy = (d) => d === undefined || d === null;
export const isEmpty = (d) => d?.toString().trim().length === 0;

export const isLogin = async () => {
  let token = localStorage.getItem("token");
  try {
    let control = verifyToken(token);
    if (control) return await true;
    if (!control) return await false;
  } catch (error) {
    return false;
  }
};

export const loginAuthUser = async (data) => {
  try {
    return await axios.post(`${ROOT_URL}/api/login`, data);
  } catch (error) {
    return false;
  }
};

export const signAuthUser = async (data) => {
  try {
    return await axios.post(`${ROOT_URL}/api/signup`, data);
  } catch (error) {
    return error.response.data;
  }
};

export const loadStore = async (id) => {
  try {
    return await axios.post(`${ROOT_URL}/api/loadStore`, { id });
  } catch (error) {
    return false;
  }
};
