import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { PCOperations } from "../../../../../redux/pageConfiguration/pcOperators";

export const PCSkinTone = ({ PCInformation }) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
   const checkSkinTone = async () => {
    if (PCInformation.PCInformation.check_skintone === 0) {
      dispatch(
        PCOperations.changeSkinTone(1, PCInformation.PCInformation.id_user)
      );
    }
    if (PCInformation.PCInformation.check_skintone === 1) {
      dispatch(
        PCOperations.changeSkinTone(0, PCInformation.PCInformation.id_user)
      );
    }
  };

  return (
    <>
      <div className="availabilityCheck">
        <div className="div-options-form">
          <Form.Check
            onClick={() => checkSkinTone()}
            type="switch"
            id="custom-switch"
            style={{ height: "2em !important" }}
            className="switch-schedule"
            defaultChecked={PCInformation.PCInformation.check_skintone}
          />
          <span className="text-color">
            {t("pageConfiguration.customerSkinTone")}
          </span>
        </div>
      </div>
      <div className="div-text-schedule color-text-secondary">
        {t("pageConfiguration.optionsFormTextSkinTone")}
      </div>
    </>
  );
};
