import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import ReactPhoneInput from "react-phone-input-2";

import { PCOperations } from "../../../../../redux/pageConfiguration/pcOperators";

export const PCGemTooth = ({
  PCInformation,
  setPhone_gem_tooth,
  setDisabledButton,
}) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();

  const checkAvailabilityGemTooth = async () => {
    if (PCInformation.PCInformation.check_gem_tooth === 0) {
      dispatch(
        PCOperations.changeCheckGemTooth(1, PCInformation.PCInformation.id_user)
      );
    }
    if (PCInformation.PCInformation.check_gem_tooth === 1) {
      dispatch(
        PCOperations.changeCheckGemTooth(0, PCInformation.PCInformation.id_user)
      );
    }
  };

  const handleChange = (e) => {
    setDisabledButton(false);
    setPhone_gem_tooth(e, "phone_gem_tooth");
  };

  return (
    <>
      <div className="availabilityCheck mt-3">
        <div className="div-options-form">
          <Form.Check
            onClick={() => checkAvailabilityGemTooth()}
            type="switch"
            id="custom-switch"
            style={{ height: "2em !important" }}
            className="switch-schedule"
            text="Selecciona si tu negocio dispone de turno intensivo o partido"
            defaultChecked={PCInformation.PCInformation.check_gem_tooth}
          />
          <span className="text-color">
            {t("pageConfiguration.customerGemToothAvailability")}
          </span>
        </div>
        {PCInformation?.PCInformation?.check_gem_tooth === 1 && (
          <Form.Group className="mb-2" id="phone">
            <Form.Label className="size-text-label color-text-secondary">
              {" "}
              {t("profile.textPhoneOtherServices")}{" "}
            </Form.Label>
            <div className="divMyAccount">
              <ReactPhoneInput
                id="idInputPhoneNumberForm"
                className="greyTextFormPhone color-text-secondary bg-input"
                inputExtraProps={{
                  name: "phone_gem_tooth",
                  required: true,
                  autoFocus: true,
                }}
                placeholder={t("pageConfiguration.textNewPhone")}
                value={
                  PCInformation.PCInformation?.phone_gem_tooth === null
                    ? PCInformation.PCInformation.phone_corporation
                    : PCInformation.PCInformation?.phone_gem_tooth
                }
                country="es"
                required
                onChange={(e) => handleChange(e)}
              />
            </div>
          </Form.Group>
        )}
      </div>
    </>
  );
};
