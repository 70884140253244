import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Button } from "react-bootstrap";
import { BudgetEmpty } from "./BudgetEmpty";
import { handleDownloadExcel } from "../../../utils/convertToExcel";
import iconTrash from "../../../assets/images/icon-Trash.svg";
import iconRotateRight from "../../../assets/images/icon-Rotate-right.svg";
import iconDownload from "../../../assets/images/downloadExcel.svg";
import { ModalDownloadExcel } from "./ModalDownloadExcel/ModalDownloadExcel";

export const BudgetButtons = ({
  refreshBudgets,
  budgets,
  pcInfo,
  idBudgets,
  handleShow,
  userProfile,
}) => {
  const [t] = useTranslation("global");
  const [show, setShow] = useState(false);
  return (
    <>
      <ModalDownloadExcel
        show={show}
        handleClose={() => setShow(false)}
        handleDownloadExcel={handleDownloadExcel}
        budgets={budgets}
        userProfile={userProfile}
      ></ModalDownloadExcel>
      <Col className="colBudgetAccordion mb-3">
        {budgets?.budgetsAll?.length === 0 && (
          <BudgetEmpty pcInfo={pcInfo} budgets={budgets} />
        )}
        <div className="div-buttons-refresh-delete">
          <Button
            className="custom-button-download"
            onClick={() => setShow(true)}
            disabled={budgets?.budgetsAll?.length === 0}
          >
            <img src={iconDownload} alt="Icono download"></img>
            <p className="text-boton-download">
              {t("budgetAccordion.accordionButtonDownload")}
            </p>
          </Button>
          <div className="d-flex">
            <Button
              className="button-refresh"
              variant="primary"
              onClick={() => refreshBudgets()}
            >
              <img src={iconRotateRight} alt="Icono papelera"></img>
              <p className="text-boton-refresh">
                {t("budgetAccordion.accordionButtonRefresh")}
              </p>
            </Button>
            <Button
              className="button-delete"
              variant="primary"
              onClick={handleShow}
              disabled={idBudgets.length === 0}
            >
              <img src={iconTrash} alt="Icono refresh"></img>
              <p className="text-boton-delete">
                {t("budgetAccordion.accordionButtonDelete")}
              </p>
            </Button>
          </div>
        </div>
      </Col>
    </>
  );
};
