import imageHead from "../../../../assets/images/image-Head.png";
import imageNeck from "../../../../assets/images/image-Neck.png";
import imageShoulder from "../../../../assets/images/image-Shoulder.png";
import imageArm from "../../../../assets/images/image-Arm.png";
import imageHand from "../../../../assets/images/image-Hand.png";
import imageChest from "../../../../assets/images/image-Chest.png";
import imageBack from "../../../../assets/images/image-Back.png";
import imageCrotch from "../../../../assets/images/image-Crotch.png";
import imageButtock from "../../../../assets/images/image-Buttock.png";
import imageLeg from "../../../../assets/images/image-Leg.png";
import imageFoot from "../../../../assets/images/image-Foot.png";
import imageIDontKnow from "../../../../assets/images/image-I-Dont-Know.png";

export const BodyTattooData = (t) => {
  return [
    {
      id: "cabeza",
      text: `${t("formChat.bodyPartHead")}`,
      value: "Cabeza",
      image: imageHead,
      type: "radio",
      parts: [
        {
          id: "frente",
          text: `${t("formChat.bodyPartFront")}`,
          value: "Frente",
          type: "radio",
          classNameBorder: "borderUp",
        },
        {
          id: "barbilla",
          text: `${t("formChat.bodyPartChin")}`,
          value: "Barbilla",
          type: "radio",
        },
        {
          id: "oreja",
          text: `${t("formChat.bodyPartEar")}`,
          value: "Oreja",
          type: "radio",
        },
        {
          id: "boca",
          text: `${t("formChat.bodyPartMouth")}`,
          value: "Boca",
          type: "radio",
        },
        {
          id: "nariz",
          text: `${t("formChat.bodyPartNose")}`,
          value: "Nariz",
          type: "radio",
        },
        {
          id: "no-lo-se-cabeza",
          text: `${t("formChat.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "cuello",
      text: `${t("formChat.bodyPartNeck")}`,
      value: "Cuello",
      image: imageNeck,
      type: "radio",
      parts: [
        {
          id: "lateral",
          text: `${t("formChat.bodyPartLateral")}`,
          value: "Lateral",
          type: "radio",
          classNameBorder: "borderUp",
        },
        {
          id: "garganta",
          text: `${t("formChat.bodyPartThroat")}`,
          value: "Garganta",
          type: "radio",
        },
        {
          id: "nuca",
          text: `${t("formChat.bodyPartNape")}`,
          value: "Nuca",
          type: "radio",
        },
        {
          id: "no-lo-se-cuello",
          text: `${t("formChat.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "hombro",
      text: `${t("formChat.bodyPartShoulder")}`,
      value: "Hombro",
      image: imageShoulder,
      type: "radio",
      parts: [
        {
          id: "lateral-cuello",
          text: `${t("formChat.bodyPartLateral")}`,
          value: "Lateral",
          type: "radio",
          classNameBorder: "borderUp",
        },
        {
          id: "detrás",
          text: `${t("formChat.bodyPartBackShoulder")}`,
          value: "Detrás",
          type: "radio",
        },
        {
          id: "delante",
          text: `${t("formChat.bodyPartFrontShoulder")}`,
          value: "Delante",
          type: "radio",
        },
        {
          id: "no-lo-se-hombro",
          text: `${t("formChat.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "brazo",
      text: `${t("formChat.bodyPartArm")}`,
      value: "Brazo",
      image: imageArm,
      type: "radio",
      parts: [
        {
          id: "manga",
          text: `${t("formChat.bodyPartSleeve")}`,
          value: "Manga",
          type: "radio",
          classNameBorder: "borderUp",
        },
        {
          id: "media-manga-baja",
          text: `${t("formChat.bodyParttHalfSleeve")}`,
          value: "Media manga (muñeca a codo)",
          type: "radio",
        },
        {
          id: "media-manga-baja1",
          text: `${t("formChat.bodyParttHalfSleeveInside")}`,
          value: "Media manga (muñeca a codo) - Interior",
          type: "radio",
        },
        {
          id: "media-manga-baja2",
          text: `${t("formChat.bodyParttHalfSleeveOutside")}`,
          value: "Media manga (muñeca a codo) - Exterior",
          type: "radio",
        },
        {
          id: "media-manga-baja3",
          text: `${t("formChat.bodyParttHalfSleeveBoth")}`,
          value: "Media manga (muñeca a codo) - Ambos",
          type: "radio",
        },
        {
          id: "media-manga-baja4",
          text: `${t("formChat.bodyParttHalfSleeveBoth2")}`,
          value: "Media manga (muñeca a codo) - Interior y exterior",
          type: "radio",
        },
        {
          id: "media-manga-alta",
          text: `${t("formChat.bodyParttHalfSleeve")}`,
          value: "Media manga (codo a hombro)",
          type: "radio",
        },
        {
          id: "media-manga-alta1",
          text: `${t("formChat.bodyParttHalfSleeve2Inside")}`,
          value: "Media manga (codo a hombro) - Interior",
          type: "radio",
        },
        {
          id: "media-manga-alta2",
          text: `${t("formChat.bodyParttHalfSleeve2Outside")}`,
          value: "Media manga (codo a hombro) - Exterior",
          type: "radio",
        },
        {
          id: "media-manga-alta3",
          text: `${t("formChat.bodyParttHalfSleeve2Both")}`,
          value: "Media manga (codo a hombro) - Ambos",
          type: "radio",
        },
        {
          id: "media-manga-alta4",
          text: `${t("formChat.bodyParttHalfSleeve2Both2")}`,
          value: "Media manga (codo a hombro) - Interior y exterior",
          type: "radio",
        },
        {
          id: "biceps",
          text: `${t("formChat.bodyPartBiceps")}`,
          value: "Bíceps",
          type: "radio",
        },
        {
          id: "triceps",
          text: `${t("formChat.bodyPartTriceps")}`,
          value: "Tríceps",
          type: "radio",
        },
        {
          id: "codo",
          text: `${t("formChat.bodyPartElbow")}`,
          value: "Codo",
          type: "radio",
        },
        {
          id: "antebrazo",
          text: `${t("formChat.bodyPartForearm")}`,
          value: "Antebrazo",
          type: "radio",
        },
        {
          id: "muñeca",
          text: `${t("formChat.bodyPartWrist")}`,
          value: "Muñeca",
          type: "radio",
        },
        {
          id: "no-lo-se-brazo",
          text: `${t("formChat.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "mano",
      text: `${t("formChat.bodyPartHand")}`,
      value: "Mano",
      image: imageHand,
      type: "radio",
      parts: [
        {
          id: "dorso",
          text: `${t("formChat.bodyPartBackHand")}`,
          value: "Dorso",
          type: "radio",
          classNameBorder: "borderUp",
        },
        {
          id: "palma",
          text: `${t("formChat.bodyPartPalm")}`,
          value: "Palma",
          type: "radio",
        },
        {
          id: "dedos",
          text: `${t("formChat.bodyPartFingers")}`,
          value: "Dedos",
          type: "radio",
        },
        {
          id: "no-lo-se-mano",
          text: `${t("formChat.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "pecho",
      text: `${t("formChat.bodyPartChest")}`,
      value: "Pecho",
      image: imageChest,
      type: "radio",
      parts: [
        {
          id: "superior",
          text: `${t("formChat.bodyPartUpper")}`,
          value: "Superior",
          type: "radio",
          classNameBorder: "borderUp",
        },
        {
          id: "esternón",
          text: `${t("formChat.bodyPartSternum")}`,
          value: "Esternón",
          type: "radio",
        },
        {
          id: "abdominales",
          text: `${t("formChat.bodyPartAbdominals")}`,
          value: "Abdominales",
          type: "radio",
        },
        {
          id: "no-lo-se-pecho",
          text: `${t("formChat.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "espalda",
      text: `${t("formChat.bodyPartBack")}`,
      value: "Espalda",
      image: imageBack,
      type: "radio",
      parts: [
        {
          id: "escápula",
          text: `${t("formChat.bodyPartScapula")}`,
          value: "Escápula",
          type: "radio",
          classNameBorder: "borderUp",
        },
        {
          id: "columna",
          text: `${t("formChat.bodyPartColumn")}`,
          value: "Columna",
          type: "radio",
        },
        {
          id: "costillas",
          text: `${t("formChat.bodyPartRibs")}`,
          value: "Costillas",
          type: "radio",
        },
        {
          id: "lumbar",
          text: `${t("formChat.bodyPartLumbar")}`,
          value: "Lumbar",
          type: "radio",
        },
        {
          id: "espalda-completa",
          text: `${t("formChat.bodyPartFullBack")}`,
          value: "Espalda completa",
          type: "radio",
        },
        {
          id: "no-lo-se-espalda",
          text: `${t("formChat.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "ingle",
      text: `${t("formChat.bodyPartGroin")}`,
      value: "Ingle",
      image: imageCrotch,
      type: "radio",
      classNameBorder: "borderUp",
    },
    {
      id: "glúteo",
      text: `${t("formChat.bodyPartButtock")}`,
      value: "Glúteo",
      image: imageButtock,
      type: "radio",
    },
    {
      id: "pierna",
      text: `${t("formChat.bodyPartLeg")}`,
      value: "Pierna",
      image: imageLeg,
      type: "radio",
      parts: [
        {
          id: "muslo",
          text: `${t("formChat.bodyPartThigh")}`,
          value: "Muslo",
          type: "radio",
          classNameBorder: "borderUp",
        },
        {
          id: "contramuslo",
          text: `${t("formChat.bodyPartCounterThigh")}`,
          value: "Contramuslo",
          type: "radio",
        },
        {
          id: "rodilla",
          text: `${t("formChat.bodyPartKnee")}`,
          value: "Rodilla",
          type: "radio",
        },
        {
          id: "espinilla",
          text: `${t("formChat.bodyPartShin")}`,
          value: "Espinilla",
          type: "radio",
        },
        {
          id: "gemelo",
          text: `${t("formChat.bodyPartCalf")}`,
          value: "Gemelo",
          type: "radio",
        },
        {
          id: "no-lo-se-pierna",
          text: `${t("formChat.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "pie",
      text: `${t("formChat.bodyPartFoot")}`,
      value: "Pie",
      image: imageFoot,
      type: "radio",
      parts: [
        {
          id: "tobillo",
          text: `${t("formChat.bodyPartAnkle")}`,
          value: "Tobillo",
          type: "radio",
          classNameBorder: "borderUp",
        },
        {
          id: "empeine",
          text: `${t("formChat.bodyPartInstep")}`,
          value: "Empeine",
          type: "radio",
        },
        {
          id: "no-lo-se-pie",
          text: `${t("formChat.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "no-lo-se-body-part",
      text: `${t("formChat.bodyPartIDoNotKnow")}`,
      value: "No lo sé",
      image: imageIDontKnow,
      type: "radio",
    },
  ];
};
