import { appFirebase } from "../fb";

export const firebaseImage = async (file, id) => {
  let date = Date.now();
  let archivoName = id.toString();
  archivoName = id.toString() + date;
  const storageRef = appFirebase.storage().ref();
  const archivoPath = storageRef.child(archivoName);
  await archivoPath.put(file);
  const enlaceUrl = await archivoPath.getDownloadURL();
  return enlaceUrl
};
