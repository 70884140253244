import { useTranslation } from "react-i18next";

export const PageConfigurationData = (id_rol) => {
  const [t] = useTranslation("global");
  if (id_rol !== 4) {
    return [
      {
        text: `${t("pageConfiguration.size")}`,
      },
      {
        text: `${t("pageConfiguration.bodyPart")}`,
      },
      {
        text: `${t("pageConfiguration.color")}`,
      },
      {
        text: `${t("pageConfiguration.description")}`,
      },
      {
        text: `${t("pageConfiguration.imagen")}`,
      },
      {
        text: `${t("pageConfiguration.contactDetails")}`,
      },
    ];
  } else if (id_rol === 4) {
    return [
      {
        text: `${t("pageConfiguration.bodypartPiercing")}`,
      },
      // {
      //   text: `${t("pageConfiguration.jewelryPiercing")}`
      // },
      {
        text: `${t("pageConfiguration.availabilitySchedulePiercing")}`,
      },
      {
        text: `${t("pageConfiguration.formerClientPiercing")}`,
      },
      {
        text: `${t("pageConfiguration.contactInformationPiercing")}`,
      },
    ];
  }
};
