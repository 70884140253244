import axios from "axios";

const ROOT_URL = process.env.REACT_APP_API_URL;


export const updatePartialCorporation = async (partialCorporation) => {
  await axios.patch(`${ROOT_URL}/api/corporation/${partialCorporation.id_corporation}`, partialCorporation);
};

export const updateCorporationDefaults = async (corporationId, currencyIso) => {
  await axios.patch(`${ROOT_URL}/api/information/defaults`, {
      id: corporationId,
      defaults: {
        currency: currencyIso
      },
  });
};

export const updateImageEmploye = async (image, id, id_user) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/updateImageEmployed`, {
      image,
      id,
      id_user,
    });
  } catch (error) {
    return false;
  }
};
export const updateImageTypeJewelry = async (image, id, id_user) => {
  try {
    return await axios.put(
      `${ROOT_URL}/api/information/updateImageTypeJewelry`,
      {
        image,
        id,
        id_user,
      }
    );
  } catch (error) {
    return false;
  }
};

export const deleteImageEmploye = async (id, id_user) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/deleteImageEmployed`, {
      id,
      id_user,
    });
  } catch (error) {
    return false;
  }
};
export const deleteImageJewelry = async (id, id_user) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/deleteImageJewelry`, {
      id,
      id_user,
    });
  } catch (error) {
    return false;
  }
};

export const saveArtistLists = async (employes) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/saveArtistListAddPc`, {
      employes,
    });
  } catch (error) {
    return false;
  }
};
export const saveTypeJewelry = async (jewelry) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/saveTypeJewelry`, {
      jewelry,
    });
  } catch (error) {
    return false;
  }
};
export const updateAboutMe = async (id, textArea) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/updateAboutMe`, {
      id,
      textArea,
    });
  } catch (error) {
    return false;
  }
};
export const updateNameAssistant = async (id, name) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/updateAssistantName`, {
      id,
      name,
    });
  } catch (error) {
    return false;
  }
};
export const updateServicesNumbers = async (
  id,
  phone_laser,
  phone_barber,
  phone_micro,
  phone_piercing,
  phone_microblading,
  phone_gem_tooth
) => {
  try {
    return await axios.put(
      `${ROOT_URL}/api/information/updateServicesNumbers`,
      {
        id,
        phone_laser,
        phone_barber,
        phone_micro,
        phone_piercing,
        phone_microblading,
        phone_gem_tooth,
      }
    );
  } catch (error) {
    return false;
  }
};
export const updateAvailability = async (availability, id) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/updateAvailability`, {
      availability,
      id,
    });
  } catch (error) {
    return false;
  }
};
export const updateInformationUser = async (
  id_user,
  domain_name,
  studio_name,
  street_corporation,
  phone_corporation,
  email_corporation,
  instagram,
  facebook,
  tik_tok
) => {
  try {
    return await axios.post(
      `${ROOT_URL}/api/information/updateInformationUserCorp`,
      {
        id_user,
        domain_name,
        studio_name,
        street_corporation,
        phone_corporation,
        email_corporation,
        instagram,
        facebook,
        tik_tok,
      }
    );
  } catch (error) {
    return false;
  }
};
export const deletePCCity = async (id) => {
  try {
    return await axios.post(`${ROOT_URL}/api/information/deletePCCity`, { id });
  } catch (error) {
    return false;
  }
};
export const deletePCEmployed = async (id) => {
  try {
    return await axios.post(`${ROOT_URL}/api/information/deletePCEmployed`, {
      id,
    });
  } catch (error) {
    return false;
  }
};
export const deletePCTypeJewelry = async (id) => {
  try {
    return await axios.post(`${ROOT_URL}/api/information/deletePCTypeJewelry`, {
      id,
    });
  } catch (error) {
    return false;
  }
};

export const updateSkinTone = async (skin, id) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/updateSkinTone`, {
      skin,
      id,
    });
  } catch (error) {
    return false;
  }
};
export const updateCheckTattoo = async (tattoo, id) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/updateCheckTattoo`, {
      tattoo,
      id,
    });
  } catch (error) {
    return false;
  }
};
export const updateCheckLaser = async (laser, id) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/updateCheckLaser`, {
      laser,
      id,
    });
  } catch (error) {
    return false;
  }
};
export const updateCheckBarber = async (barber, id) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/updateCheckBarber`, {
      barber,
      id,
    });
  } catch (error) {
    return false;
  }
};
export const updateCheckGemTooth = async (gem_tooth, id) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/updateCheckGemTooth`, {
      gem_tooth,
      id,
    });
  } catch (error) {
    return false;
  }
};
export const updateCheckMicroblading = async (microblading, id) => {
  try {
    return await axios.put(
      `${ROOT_URL}/api/information/updateCheckMicroblading`,
      {
        microblading,
        id,
      }
    );
  } catch (error) {
    return false;
  }
};
export const updateCheckMicro = async (micro, id) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/updateCheckMicro`, {
      micro,
      id,
    });
  } catch (error) {
    return false;
  }
};
export const updateCheckPiercing = async (piercing, id) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/updateCheckPiercing`, {
      piercing,
      id,
    });
  } catch (error) {
    return false;
  }
};
export const updateCheckSizeTattoo = async (sizeTattoo, id) => {
  try {
    return await axios.put(
      `${ROOT_URL}/api/information/updateCheckSizeTattoo`,
      {
        sizeTattoo,
        id,
      }
    );
  } catch (error) {
    return false;
  }
};
export const updateCheckCity = async (check_city, id) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/updateCheckCity`, {
      check_city,
      id,
    });
  } catch (error) {
    return false;
  }
};
export const updateCheckEmployeds = async (check_employeds, id) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/updateCheckEmployeds`, {
      check_employeds,
      id,
    });
  } catch (error) {
    return false;
  }
};
export const updateCheckTypeJewelry = async (check_typejewelry, id) => {
  try {
    return await axios.put(
      `${ROOT_URL}/api/information/updateCheckTypeJewelry`,
      {
        check_typejewelry,
        id,
      }
    );
  } catch (error) {
    return false;
  }
};

export const updateCity = async (data, id) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/updateCity`, {
      data,
      id,
    });
  } catch (error) {
    return false;
  }
};
export const createArtist = async (data, id) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/createArtist`, {
      data,
      id,
    });
  } catch (error) {
    return false;
  }
};
export const createTypeJewelry = async (data, id) => {
  try {
    return await axios.put(`${ROOT_URL}/api/information/createTypeJewelry`, {
      data,
      id,
    });
  } catch (error) {
    return false;
  }
};
export const deleteArtist = async (id) => {
  try {
    return await axios.post(`${ROOT_URL}/api/information/deletePCCity`, { id });
  } catch (error) {
    return false;
  }
};
