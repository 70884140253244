import { ProfileActionsTypes } from "./profileActionsTypes";

const ProfileActions = {
  setLoadStore: (data) => ({
    type: ProfileActionsTypes.SET_LOAD_STORE,
    payload: { data },
  }),
  updateProfile: (data) => ({
    type: ProfileActionsTypes.UPDATE_PROFILE,
    payload: { data },
  }),
  updateImageProfile: (data) => ({
    type: ProfileActionsTypes.UPDATE_IMAGE_PROFILE,
    payload: { data },
  }),
  deleteImageProfile: (data) => ({
    type: ProfileActionsTypes.DELETE_IMAGE_PROFILE,
    payload: { data },
  }),
  changeStoreCheckTheme: (theme) => ({
    type: ProfileActionsTypes.UPDATE_CHECK_THEME,
    payload: { theme },
  }),
};

export default ProfileActions;
