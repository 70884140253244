import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {AmountInput} from "./AmountInput";
import {currencyActions} from "../../../redux/currency/currencyActions";
import {updateCorporationDefaults} from "../../../helpers/InformationHelper";
import {corporationIdSelector} from "../../../redux/pageConfiguration/pcReducer";
import {currenciesSelector, currencySelectedSelector} from "../../../redux/currency/currencyReducer";

export const WrappedAmountInput = ({
                                       value,
                                       disableCurrency,
                                       onCurrencyChange,
                                       onValueChange
                                   }) => {

    const dispatch = useDispatch();
    const currencies = useSelector(currenciesSelector);
    const currencySelected = useSelector(currencySelectedSelector);
    const corporationId = useSelector(corporationIdSelector);

    const changeCurrency = async (currency) => {
        dispatch(currencyActions.setCurrencySelected(currency));
        await updateCorporationDefaults(corporationId, currency.iso);
        onCurrencyChange && onCurrencyChange(currency);

    }
    return (
        <AmountInput
            value={value}
            currencySelected={currencySelected}
            disableCurrency={disableCurrency}
            onCurrencyChange={changeCurrency}
            onValueChange={onValueChange}
            currencies={currencies}
        />
    )
}
