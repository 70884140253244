import React from "react";
import { Alert, Button } from "react-bootstrap";
import iconCheckCircle from "../../assets/images/icons/icon-Check-circle.svg";
import iconClosedAlert from "../../assets/images/icons/iconClosedAlert.svg";

export const AlertConfirmation = ({ text, tittle, show, setShow }) => {
  return (
    <Alert
      translate="no"
      className="confirmation-container m-0 d-flex "
      show={show}
    >
      <Alert.Heading>{tittle}</Alert.Heading>
      <div>
        <div className="divAlertConfirmation">
          <img className="iconCheck greenFilterSucces" src={iconCheckCircle} alt="ex" />
          <p className="text-start fs-5 mb-0 ps-3 pb-1">{text}</p>
          <Button className="closedAlert" onClick={() => setShow(false)}>
            <img
              className="iconClosedAlert"
              src={iconClosedAlert}
              alt="asdasd"
            />
          </Button>
        </div>
      </div>
    </Alert>
  );
};
