import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import CookieConsent from "react-cookie-consent";

export const Cookies = ({ setIsOpenModal }) => {
  const [t] = useTranslation("global");
  const acceptCookies = async () => {
    setIsOpenModal(false);
    localStorage.setItem("cookiesInkup", "true", { path: "/" });
  };

  return (
    <Row translate="no">
      <Col>
        <div className="cookies-inkup">
          <CookieConsent
            contentClasses="divTextCookies"
            buttonWrapperClasses="divButtonCookies"
            className="cookiesStyle"
            debug={true}
            style={{
              background: "#FFF",
              textAlign: "center",
              color: "#808080",
            }}
            buttonStyle={{
              background: "#52AF9A",
              color: "#ffffff",
              borderRadius: "50px",
            }}
            buttonText="Aceptar Cookies"
            onAccept={acceptCookies}
          >
            {t("cookiesConsent.see")}{" "}
            <a
              href="//inkup.io/politica-de-cookies/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("cookiesConsent.PrivacyPolicy")}
            </a>{" "}
          </CookieConsent>
        </div>
      </Col>
    </Row>
  );
};
