import { useTranslation } from "react-i18next";

export const BudgetMock = () => {
  const [t] = useTranslation("global");

  return [
    {
      id: 24,
      id_user: 1,
      name_employed: "Equipo Inkup",
      name: t("budget.nameMock"),
      phone_budget: "+34 627778062",
      email_sent: "",
      time: "Tardes",
      email: t("budget.exampleEmailMock"),
      description: t("budget.commentDescriptionMock"),
      body_part: "Brazo",
      city: t("budget.exampleCityMock"),
      employed: t("budget.nameArtistMock"),
      skintone: "Moreno claro",
      body_part_site: "Media manga (muñeca a codo)",
      size: "de 5 a 9 cm",
      state_budget: "pending",
      state_budget_tag: 1,
      budget_color: "Negro",
      assigned_by: 1,
      budget_type: 1,
      // media_path_budget: null,
      budget_created_at: t("budget.dateMock"),
      updated_at: "2023-04-21T10:34:09.000Z",
      media_path_budget:
        "https://firebasestorage.googleapis.com/v0/b/connectattoo.appspot.com/o/11683271406270?alt=media, https://firebasestorage.googleapis.com/v0/b/connectattoo.appspot.com/o/11683271429633?alt=media, https://firebasestorage.googleapis.com/v0/b/connectattoo.appspot.com/o/11683271445898?alt=media",

      disabled: false,
      checked: false,
      deleted_at: null,
    },
  ];
};
