import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import { Button, Form, Row, Col } from "react-bootstrap";
import eyeIcon from "../../../assets/images/icons/eye.svg";
import logoInkupSidebar from "../../../assets/images/logoInkupSidebar.svg";
import "../../../assets/styles/css/index.css";
import { AuthOperations } from "../../../redux/auth/authOperators";
import ErrorsComponent from "../../Errors/ErrorsComponent";

export const LoginComponent = () => {
  const state = useSelector((state) => state);
  const dispatchR = useDispatch();
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const [newState, flipToggle] = useState(false);
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  let error = state?.auth?.error?.error;

  const handleToggle = () => {
    flipToggle(!newState);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const email = emailRef.current.value;
      const password = passwordRef.current.value;
      dispatchR(AuthOperations.setLogin({ email, password }));
      navigate("/presupuestos");
    } catch (error) {}
  };

  return (
    <span translate="no">
      <Row className="totalHeight">
        <Col
          xs={0}
          sm={0}
          md={6}
          lg={6}
          xl={6}
          xxl={6}
          className="mx-0 p-0 loginTattoo d-none d-md-block"
        ></Col>

        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <div className="topContentLogin">
            <div className="logo-icon-login">
              <img src={logoInkupSidebar} className="logo-img" alt="logo" />
            </div>
            <br />
            <Link to="/signup" className="btnSingup">
              <Button
                id="idRegisterInkup"
                className="border-3 greenSmallButton rounded-pill whiteSmallButton bodyDetailsBold  buttonMarginLoginRegister"
              >
                {t("login.register")}
              </Button>
            </Link>
          </div>
          <h2 className="mb-5  text-center headingH2">{t("login.title")}</h2>
          <Form className="col-12  formData" onSubmit={onFormSubmit}>
            <Form.Group className="blockLogin" controlId="formBasicEmail">
              <Form.Label className="bodySubcontentBold">
                {t("login.email")}
              </Form.Label>
              <Form.Control
                type="email"
                placeholder={t("login.boxEmail")}
                className="border-3 input-lg"
                ref={emailRef}
                required
              />
              {error === 1 && <ErrorsComponent error={error} />}
            </Form.Group>
            <Form.Group className="pass-login" controlId="formBasicPassword">
              <Form.Label className="bodySubcontentBold">
                {t("login.password")}
              </Form.Label>
              <div className="login-input">
                <Form.Control
                  autoComplete="nope"
                  placeholder={t("login.boxPassword")}
                  className="border-3 pass-login"
                  ref={passwordRef}
                  type={newState ? "text" : "password"}
                  required
                />
                <img
                  onClick={handleToggle}
                  className="eye-login"
                  src={eyeIcon}
                  alt=""
                />
              </div>
            </Form.Group>
            {error === 2 && <ErrorsComponent error={error} />}
            <Form.Group
              className="blockLogin forgotPassword"
              controlId="formBasicPassword"
            >
              <Link
                to="/forgot-password"
                className="d-block text-end forgotPassword bodySubcontentBold"
              >
                {t("login.forget")}
              </Link>
            </Form.Group>

            <Button
              className=" rounded-pill mb-2 purpleMediumButton bodyDetailsBold btnStartSesion"
              type="submit"
            >
              {t("login.login")}
            </Button>
          </Form>
        </Col>
      </Row>
    </span>
  );
};
