import imgSizeTattooTidy from "../../../../assets/images/img-Size-Tattoo-Tidy.png";
import imgSizeTattooSmall from "../../../../assets/images/img-Size-Tattoo-Small.png";
import imgSizeTattooMedium from "../../../../assets/images/img-Size-Tattoo-Medium.png";
import imgSizeTattooLarge from "../../../../assets/images/img-Size-Tattoo-Large.png";
import imgSizeTattooExtraLarge from "../../../../assets/images/img-Size-Tattoo-Extra-Large.png";

export const SizeTattooData = (t) => {
  return [
    {
      id: "diminuto",
      text: `${t("formChat.sizeTiny")}`,
      textSize: `${t("formChat.textSizeTyny")}`,
      textMicro: `${t("formChat.textSizeTynyMicro")}`,
      value: "< de 4 cm",
      valueText: "Muy pequeño ",
      valueTextI: "Very small ",
      image: imgSizeTattooTidy,
      type: "radio",
    },
    {
      id: "diminutoMicro",
      text: `${t("formChat.sizeTiny")}`,
      textSize: `${t("formChat.textSizeTyny")}`,
      textMicro: `${t("formChat.textSizeTynyMicro")}`,
      value: "Menor de 4 cm",
      valueText: "Muy pequeño ",
      valueTextI: "Very small ",
      image: imgSizeTattooTidy,
      type: "radio",
    },
    {
      id: "pequeño",
      text: `${t("formChat.sizeSmall")}`,
      textSize: `${t("formChat.textSizeSmall")}`,
      value: "de 5 a 9 cm",
      valueText: "Pequeño ",
      valueTextI: "Small ",
      image: imgSizeTattooSmall,
      type: "radio",
    },
    {
      id: "pequeñoMicro",
      text: `${t("formChat.sizeSmallMicro")}`,
      textMicro: `${t("formChat.sizeSmall")}`,
      textSize: `${t("formChat.textSizeSmallMicro")}`,
      value: "de 5 a 7 cm",
      image: imgSizeTattooSmall,
      type: "radio",
    },
    {
      id: "mediano",
      text: `${t("formChat.sizeMedium")}`,
      textSize: `${t("formChat.textSizeMedium")}`,
      value: "de 10 a 15 cm",
      valueText: "Mediano ",
      valueTextI: "Medium ",
      image: imgSizeTattooMedium,
      type: "radio",
    },
    {
      id: "medianoMicro",
      text: `${t("formChat.sizeMediumMicro")}`,
      textMicro: `${t("formChat.sizeMedium")}`,
      textSize: `${t("formChat.textSizeMediumMicro")}`,
      value: "de 8 a 10 cm",
      image: imgSizeTattooLarge,
      type: "radio",
    },
    {
      id: "grande",
      text: `${t("formChat.sizeLarge")}`,
      textSize: `${t("formChat.textSizeLarge")}`,
      textMicro: `${t("formChat.sizeLarge")}`,
      value: "de 15 a 20 cm",
      valueText: "Grande ",
      valueTextI: "Large ",
      image: imgSizeTattooLarge,
      type: "radio",
    },
    {
      id: "grandeMicro",
      text: `${t("formChat.sizeLargeMicro")}`,
      textMicro: `${t("formChat.sizeLarge")}`,
      textSize: `${t("formChat.textSizeLarge2")}`,
      value: "de 16 a 20 cm",
      image: imgSizeTattooLarge,
      type: "radio",
    },
    {
      id: "muy-grande",
      text: `${t("formChat.sizeExtraLarge")}`,
      textSize: `${t("formChat.textSizeExtraLarge")}`,
      value: "> de 20 cm",
      valueText: "Muy grande ",
      valueTextI: "Very large ",
      image: imgSizeTattooExtraLarge,
      type: "radio",
    },
    {
      id: "muy-grandeMicro",
      text: `${t("formChat.sizeExtraLarge")}`,
      textSize: `${t("formChat.textSizeExtraLarge")}`,
      value: "Mayor de 20 cm",
      valueText: "Muy grande ",
      valueTextI: "Very large ",
      image: imgSizeTattooExtraLarge,
      type: "radio",
    },
    {
      id: "intermedio",
      text: `${t("formChat.sizeIntermediateMicro")}`,
      textSize: `${t("formChat.textSizeIntermediateMicro")}`,
      value: "de 11 a 15 cm",
      image: imgSizeTattooLarge,
      type: "radio",
    },
    {
      id: "no-lo-se-size",
      text: `${t("formChat.colorIDoNotKnow")}`,
      textSize: `${t("formChat.colorIDoNotKnow")}`,
      value: "No lo sé",
      // image: imageTattooIDontKnow,
      type: "radio",
    },
  ];
};
export default SizeTattooData;
