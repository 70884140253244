import React, { useState } from "react";
import { BudgetCarousel } from "../BudgetBody/BudgetCarousel";
import { BudgetDescription } from "../BudgetBody/Description";
import { BudgetElements } from "../BudgetBody/BudgetElements";
import { BudgetHeaderForm } from "../BudgetBody/BudgetHeaderForm";
import { BudgetToFrom } from "../BudgetBody/BudgetToFrom";
import { BudgetComments } from "../BudgetBody/BudgetComments";
import { BudgetContact } from "../BudgetBody/BudgetContact";
import { Col, Row } from "react-bootstrap";
import { BudgetButtonsNew } from "../BudgetBody/BudgetButtonsNew";
import {useSelector} from "react-redux";
import {currencySelectedSelector} from "../../../../redux/currency/currencyReducer";

export const BudgetRow = ({
  budgets,
  budget,
  theme,
  index,
  pcInfo,
  idRol,
  idUser,
  changeTextButton,
}) => {
  const [from, setFrom] = useState(
    budget.price_from ? budget.price_from : null
  );

  const currency = useSelector(currencySelectedSelector);
  const [until, setUntil] = useState(budget.price_up ? budget.price_up : null);

  const [replyHeaderMessage, setReplyHeaderMessage] = useState("");

  const [comments, setComments] = useState(
    budget.email_sent ? budget.email_sent : ""
  );
  const [textAreaCount, setTextAreaCount] = useState(0);
  const maxLength = 10;

  const handleInputChange = (e, setInputValue) => {
    let value = e.target.value;
    if (value.length <= maxLength) {
      setInputValue(value);
    }
  };

  const deleteInputPlaceholder = (inputId) => {
    document.getElementById(inputId).placeholder = "";
    document.getElementById(inputId).value = "";
  };

  const recalculateComments = (e) => {
    setTextAreaCount(e.target.value.length);
    setComments(e.target.value);
  };
  return (
    <Row>
      <Col md={12} lg={6}>
        <BudgetCarousel budget={budget} />
        <div className="budget-elements-container-large">
          <BudgetElements budget={budget} pcInfo={pcInfo} />
        </div>
      </Col>
      <Col md={12} lg={6}>
        <div className="div-actions-pending">
          <BudgetContact budget={budget} />
          <BudgetDescription budget={budget} idRol={idRol} />
          <div className="budget-elements-container-small">
            <BudgetElements budget={budget} pcInfo={pcInfo} />
          </div>

          <BudgetHeaderForm budget={budget} pcInfo={pcInfo} onChangeReplyHeaderMessagePreview={setReplyHeaderMessage}/>
          <BudgetToFrom
            budget={budget}
            pcInfo={pcInfo}
            deleteInputPlaceholder={deleteInputPlaceholder}
            handleInputChange={handleInputChange}
            from={from}
            setFrom={setFrom}
            until={until}
            setUntil={setUntil}
          />
          <BudgetComments
            budget={budget}
            pcInfo={pcInfo}
            deleteInputPlaceholder={deleteInputPlaceholder}
            recalculateComments={recalculateComments}
            textAreaCount={textAreaCount}
            comments={comments}
          />
          <BudgetButtonsNew
            budgets={budgets}
            budget={budget}
            theme={theme}
            index={index}
            from={from}
            until={until}
            comments={comments}
            idUser={idUser}
            pcInfo={pcInfo}
            replyHeaderMessage={replyHeaderMessage}
            changeTextButton={() => changeTextButton(index)}
            currency={currency}
          />
        </div>
      </Col>
    </Row>
  );
};
