import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { PCOperations } from "../../../redux/pageConfiguration/pcOperators";
import iconSolidStore from "../../../assets/images/icon-solid-store.svg";
import iconGlobe from "../../../assets/images/icon-globe.svg";

export const DomainName = ({ id: id_user, PCConfiguration }) => {
  const [t] = useTranslation("global");
  const [corpUserInfo, setCorpUserInfo] = useState(
    PCConfiguration.PCInformation
  );
  const [domain_name, setDomain_name] = useState(corpUserInfo.domain_name);
  const { studio_name } = corpUserInfo;
  const dispatch = useDispatch();
  const [showButton, setShowButton] = useState(true);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      PCOperations.updateCorpInformation(
        corpUserInfo.id_user,
        domain_name,
        studio_name,
        t
      )
    );
    setShowButton(true);
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setCorpUserInfo({ ...corpUserInfo, [name]: value });
    setShowButton(false);
  }

  function handleDomainName(e) {
    setDomain_name(e.target.value.replace(/\s/g, "-"));
    setShowButton(false);
  }

  return (
    <Form onSubmit={onFormSubmit}>
      <Form.Group className="mb-3">
        <Form.Label className="color-text-secondary">
          {t("editInformation.businessName")}
        </Form.Label>
        <div className="divMyAccount">
          <img src={iconSolidStore} alt="Icon nombre"></img>
          <Form.Control
            autoComplete="nope"
            className="inputProfile color-text-secondary bg-input"
            type="text"
            id="studio_name"
            name="studio_name"
            onChange={handleChange}
            value={studio_name === null ? "" : studio_name}
            placeholder={t("editInformation.placeholderWebsite")}
          ></Form.Control>
        </div>
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label className="color-text-secondary">
          {t("editInformation.businessDomain")}
        </Form.Label>
        <div className="divMyAccount">
          <img src={iconGlobe} alt="Icon nombre"></img>
          <Form.Control
            autoComplete="nope"
            className="inputProfile color-text-secondary bg-input"
            type="text"
            id="domain_name"
            name="domain_name"
            value={domain_name === null ? "" : domain_name}
            onChange={handleDomainName}
            placeholder={t("editInformation.placeholderAddress")}
          ></Form.Control>
        </div>
      </Form.Group>
      <div className="divBottonProfile">
        <Button
          disabled={showButton}
          type="submit"
          className="bottonProfile bodyDetailsBold bg-button color-text-button"
        >
          {t("updatePassword.update")}
        </Button>
      </div>
    </Form>
  );
};
