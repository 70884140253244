import { useTranslation } from "react-i18next";
export const MessagesError = (error) => {
  const [t] = useTranslation("global");
  let messageError = [
    { id: "incorrectEmail", value: 1, text: t("login.incorrectEmail") },
    { id: "incorrectPassword", value: 2, text: t("login.incorrectPassword") },
  ];

  let errorMessage;
  const matchingError = messageError.find((item) => item.value === error);

  if (matchingError) {
    errorMessage = matchingError.text;
  }
  return errorMessage;
};
