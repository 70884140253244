export const PCActionsTypes = {
  SET_LOAD_STORE_PAGE_CONFIGURATION: "SET_LOAD_STORE_PAGE_CONFIGURATION",
  UPDATE_AVAILABILITY:"UPDATE_AVAILABILITY",
   DELETE_EMPLOYE: "DELETE_EMPLOYE",
  DELETE_TYPEJEWELRY: "DELETE_TYPEJEWELRY",
   UPDATE_ABOUT_ME: "UPDATE_ABOUT_ME",
   UPDATE_ASSISTANT_ME: "UPDATE_ASSISTANT_ME",
  UPDATE_NUMBERS_SERVICES: "UPDATE_NUMBERS_SERVICES",
  UPDATE_CORP_INFORMATION: "UPDATE_CORP_INFORMATION",
   UPDATE_SKINTONE: "UPDATE_SKINTONE",
  UPDATE_CHECK_TYPE_JEWELRY: "UPDATE_CHECK_TYPE_JEWELRY",
  UPDATE_CHECK_CITY: "UPDATE_CHECK_CITY",
  UPDATE_CHECK_EMPLOYED: "UPDATE_CHECK_EMPLOYED",
  UPDATE_CHECK_TATTOO: "UPDATE_CHECK_TATTOO",
  UPDATE_CHECK_LASER: "UPDATE_CHECK_LASER",
  UPDATE_CHECK_BARBER: "UPDATE_CHECK_BARBER",
  UPDATE_CHECK_GEM_TOOTH: "UPDATE_CHECK_GEM_TOOTH",
  UPDATE_CHECK_MICROBLADING: "  UPDATE_CHECK_MICROBLADING",
  UPDATE_CHECK_MICRO: "UPDATE_CHECK_MICRO",
  UPDATE_CHECK_PIERCING: "UPDATE_CHECK_PIERCING",
  UPDATE_CHECK_SIZE_TATTOO: "UPDATE_CHECK_SIZE_TATTOO",
  DELETE_CITY: "DELETE_CITY",
  ADD_CITY: "ADD_CITY",
  ADD_ARTIST: "ADD_ARTIST",
  ADD_TYPEJEWELRY: "ADD_TYPEJEWELRY",
  RESET_CHECK: "RESET_CHECK",
  UPDATE_CHECK_LOGO: "UPDATE_CHECK_LOGO",
   UPDATE_CHECK_ABOUT: "UPDATE_CHECK_ABOUT",
};
