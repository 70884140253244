import React, { useState } from "react";
import { PCSharedLinks } from "./Shared_Links";
import { PCSharedInsta } from "./Shared_Insta";
import { PCSharedInstaDM } from "./Shared_DM";
import { PCSharedInstaStories } from "./Shared_Stories";
import { PCSharedWhatsapp } from "./Shared_Whatsapp";

export const PCShared = ({ setOpenModal, pcInfo }) => {
  const [shared, setShared] = useState("links");
  
  return (
    <>
      {shared === "links" && (
        <PCSharedLinks setOpenModal={setOpenModal} setShared={setShared} />
      )}
      {shared === "insta" && (
        <PCSharedInsta
          setOpenModal={setOpenModal}
          setShared={setShared}
          pcInfo={pcInfo}
        />
      )}
      {shared === "instaDM" && (
        <PCSharedInstaDM
          setOpenModal={setOpenModal}
          setShared={setShared}
          pcInfo={pcInfo}
        />
      )}
      {shared === "whatsapp" && (
        <PCSharedWhatsapp
          setOpenModal={setOpenModal}
          setShared={setShared}
          pcInfo={pcInfo}
        />
      )}
        {shared === "instaStories" && (
        <PCSharedInstaStories
          setOpenModal={setOpenModal}
          setShared={setShared}
          pcInfo={pcInfo}
        />
      )}
    </>
  );
};
