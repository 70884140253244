import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Row, Col } from "react-bootstrap";
import { forgotPassword } from "../../helpers/dashboardHelper";
import { Link } from "react-router-dom";
import { AlertConfirmation } from "../Alerts/AlertConfirmation";
import logoInkupSidebar from "../../assets/images/logoInkupSidebar.svg";
import "../../assets/styles/css/index.css";

const ForgotPassword = () => {
  const [t] = useTranslation("global");
  const [show, setShow] = useState(false);
  const [tittle, setTittle] = useState(null);
  const [text, setText] = useState(null);
  const emailRef = useRef(null);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const email = emailRef.current.value;
      const response = await forgotPassword({ email });
      if (response.data.accepted) {
        setShow(true);
        // setTittle(t("forgotPassword.passwordSent"));
        setText(t("forgotPassword.messagePasswordSent"));
      } else {
        setShow(true);
        setText(t("forgotPassword.errorContactUs"));
        setTittle(t("forgotPassword.anErrorOccurred"));
      }
    } catch (error) {}
  };

  return (
    <Row translate="no" className="totalHeight lightBackground">
      <Col xs={12} md={12} lg={12} xl={12} xxl={12}>
        <div className="d-flex mx-0 pt-5 divStartSesion">
          <img
            src={logoInkupSidebar}
            className="logo-img imageLogo"
            alt="logo"
          />
          <Link to="/login">
            <Button className="border-3 rounded-pill greenMediumButton bodyDetailsBold btnStartSesion">
              {t("forgotPassword.login")}
            </Button>
          </Link>
        </div>
        <div className="d-flex justify-content-center mx-0 pt-5 pb-5"></div>
        <h2 className="mx-auto my-auto mb-3 text-center headingH2">
          {t("forgotPassword.resetPassword")}
        </h2>
        <div className="mx-auto instructionsText ">
          <p className="text-center pt-3 pb-2 bodyRegular">
            {t("forgotPassword.textInstructions")}
          </p>
        </div>
        <Form className="mx-auto formDataPassword" onSubmit={onFormSubmit}>
          <Form.Group
            className="mb-3 pb-2"
            controlId="formBasicEmail"
          ></Form.Group>
          <Form.Label className="bodySubcontentBold">
            {t("forgotPassword.email")}
          </Form.Label>
          <Form.Control
            autoComplete="nope"
            type="email"
            ref={emailRef}
            placeholder="Email@email.com"
            className="border-3"
          ></Form.Control>
          <Button
            className="mt-4 mx-auto rounded-pill purpleLargeButton bodyDetailsBols"
            type="submit"
          >
            {t("forgotPassword.send")}
          </Button>
        </Form>
      </Col>
      {show === false && (
        <AlertConfirmation
          text={text}
          tittle={tittle}
          show={show}
          setShow={setShow}
        />
      )}
    </Row>
  );
};
export default ForgotPassword;
