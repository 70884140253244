import React from "react";
import {useTranslation} from "react-i18next";
import {WrappedAmountInput} from "../../../Shared/Form/WrappedAmountInput";

export const BudgetToFrom = ({
  from,
  setFrom,
  until,
  setUntil,
}) => {
  const [t] = useTranslation("global");

  return (
    <div className="div-inpusts-price-budget">
      <div className="div-input-from">
        <p className="bodyBold mb-1 color-text-secondary">{t("budget.from")}</p>
        <WrappedAmountInput disableCurrency={false}
                            onValueChange={setFrom}
                            value={from}
        />
      </div>
      <div className="div-input-to">
        <p className="bodyBold mb-1 color-text-secondary">{t("budget.to")}</p>
          <WrappedAmountInput disableCurrency={true}
                       onValueChange={setUntil}
                       value={until}
          />
      </div>
    </div>
  );
};
