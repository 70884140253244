import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import iconClosedAlert from "../../../../assets/images/icons/iconClosedAlert.svg";
// import iconWhatsappColorModal from "../../../../assets/images/icons/iconWhatsappColorModal.svg";
import WImage from "../../../../assets/images/image-whatsApp.png";
import WImageEn from "../../../../assets/images/image-whatsApp-en.png";
import WImageDe from "../../../../assets/images/image-whatsApp-de.png";
import iconChevronLeft from "../../../../assets/images/icons/iconChevronLeft.svg";
// import iconChevronRight from "../../../../assets/images/icons/iconChevronRight.svg";
import iconCheckCircleWhite from "../../../../assets/images/icon-Check-Circle-White.svg";

export const PCSharedWhatsapp = ({ setOpenModal, setShared, pcInfo }) => {
  const [t] = useTranslation("global");
  const [changeButon, setChangeButon] = useState(false);
  const ROOT_URL = process.env.REACT_APP_MY_URL;

  let copy = `${t("dashboard.modalSharedCopyLinkWhatsApp")}
  ${ROOT_URL}/${pcInfo.domain_name}`;
   let image;

  switch (window?.navigator?.language?.slice(0, 2)) {
    case "es":
      image = WImage;
      break;
    case "de":
      image = WImageDe;
      break;
    case "en":
      image = WImageEn;
      break;
    default:
      image = WImageEn;
      break;
  }

  const handleChange = () => {
    toast.success(t("information.copiedCorrectly"));
    setChangeButon(true);
  };

  return (
    <>
      <div className="alert-confirmation-budget sharedLinks m-0 d-flex ">
        <div className="header-alert-budget header-link">
          <Button
            className="modalShared iconBack"
            onClick={() => setShared("links")}
          >
            <img src={iconChevronLeft} alt="instaColor" />
          </Button>
          WhatsApp Business
          <Button className="closedAlert" onClick={() => setOpenModal(false)}>
            <img
              className="iconCheck"
              src={iconClosedAlert}
              alt="Icono Check"
            />
          </Button>
        </div>
        <div className="sharedBody">
          <div className="divAlertConfirmation">
            <p className="text-start  mb-0  pb2">
              {t("dashboard.modalSharedWhatsAppDescription")}&nbsp;
              <span className="textBoldRRSS">
                {t("dashboard.modalSharedWhatsAppDescription2")}
              </span>
              &nbsp;
              {t("dashboard.modalSharedWhatsAppDescription3")}
            </p>
          </div>
          <div className="sharedBodyImg">
            <img className="iconCheck" src={image} alt="Icono Check" />
          </div>
          <div className="sharedBodycopy">
            <p className="">
              {t("dashboard.modalSharedGreetings")} ...
             </p>
            <CopyToClipboard text={copy}>
              <Button
                className={
                  changeButon === false ? "ButonRRSS" : "ButonRRSSGreen"
                }
                onClick={changeButon === false && (() => handleChange())}
              >
                {changeButon === false ? (
                  `${t("information.copy")}`
                ) : (
                  <>
                    <img
                      className="iconCheckButtonRRSS"
                      src={iconCheckCircleWhite}
                      alt=""
                    ></img>
                    <p> {t("information.copying")} </p>
                  </>
                )}
              </Button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </>
  );
};
