import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Col } from "react-bootstrap";
import { Cookies } from "../Cookies/Cookies";

export const ModalCookies = ({ cookies }) => {
  const [isOpenModal, setIsOpenModal] = useState(true);

  return (
    <>
      {!cookies && (
        <Col>
          <Cookies setIsOpenModal={setIsOpenModal} />
          <Modal
            backdropClassName="modalCookiesOpacity"
            className="template01"
            show={isOpenModal}
            onHide={() => setIsOpenModal(false)}
            backdrop="static"
            keyboard={false}
            dialogClassName="sizeModalCookiesTemplate01"
          ></Modal>
        </Col>
      )}
    </>
  );
};
