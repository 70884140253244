export const setBudgetFree = (budgets, premium) => {
  let limitBudget;

  if (premium === 0) {
    limitBudget = 11;
  }
  if (premium === 1) {
    limitBudget = 40;
  }
  if (premium === 2) {
    limitBudget = 8000;
  }
  let arr = [];
  let arrayResultBudgets = [];
  //Extraemos la fecha de cada uno de los budgets y la pasamos a string
  const getDate = (date) => {
    return { moth: date.split("-")[1], year: date.split("-")[0] };
  };
  //Introducimos en un array todos los años de cada uno de los budget
  let arrayYears = [];

  budgets.map(async (e) => {
    let a = e.budget_created_at.split("-")[0];

    arrayYears.push(parseInt(a));
  });

  //Introducimos en un nuevo array los años existentes sin repetir los años de los budgets, y permitiendo añadir mas años automaticamente
  let resultArrayYears = arrayYears.filter((item, index) => {
    return arrayYears.indexOf(item) === index;
  });

  let monthsYear = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  //Buscamos por cada año y por cada mes, todos los budgets y los metemos en una nueva array
  for (let y = 0; y < resultArrayYears.length; y++) {
    for (let i = 0; i <= monthsYear.length; i++) {
      const updatedArray = budgets.map((element) => {
        let e = getDate(element.budget_created_at);
        if (Number(e.moth) === Number(monthsYear[i]) && Number(e.year) === Number(resultArrayYears[y])) {
          return element;
        }
        return null;
      }).filter(element => element !== null);

      arr.push(...updatedArray);
      //Seleccionamos los primeros x budgets de cada mes y cada año
      if (limitBudget < arr.length) {
        let resultBudgetOpen = arr.slice(arr.length - limitBudget); // EJ 5 budgets: 4 hasta el numero 0
        resultBudgetOpen.map(function (dato) {
          dato.disabled = false;
          return dato;
        });
        //Seleccionamos todos los budgets menos los x primeros de cada mes y cada año
        let resultBudgetClosed = arr.slice(0, arr.length - limitBudget);
        arr = [];

        //Introducimos en una nueva Array el resultado de los primeros x budgets de cada mes y cada año y el resultado de todos los budgets menos los x primeros de cada mes y cada año
        arrayResultBudgets.push(...resultBudgetClosed, ...resultBudgetOpen);
      } else {
        let resultBudgetOpen = arr; // EJ 5 budgets: 4 hasta el numero 0
        resultBudgetOpen.map(function (dato) {
          dato.disabled = false;
          return dato;
        });
        //Seleccionamos todos los budgets menos los x primeros de cada mes y cada año
        arr = [];

        //Introducimos en una nueva Array el resultado de los primeros x budgets de cada mes y cada año y el resultado de todos los budgets menos los x primeros de cada mes y cada año
        arrayResultBudgets.push(...resultBudgetOpen);
      }
    }
  }
  //ordeno por fecha de mayor a menor
  arrayResultBudgets.sort((a, b) => b.budget_created_at.localeCompare(a.budget_created_at));

  const newArrayBudget = arrayResultBudgets.filter((i) => {
    return !i.deleted_at;
  });

  return newArrayBudget;
};