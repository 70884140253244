
import React from "react";
import iconEmail from "../../../../assets/images/icons/emailIcon.svg";
import iconPhone from "../../../../assets/images/icons/phoneIcon.svg";

export const BudgetContact = ({ budget }) => {
  return (
    <div className="div-mail-phone pb-3 color-border">
      {budget.email && (
        <div className="icon-container d-flex align-items-center">
          <img
            src={iconEmail}
            alt="Email Icon"
            className="icon icon-with-margin"
          />
          <p className="mb-0 color-text-secondary">{budget.email}</p>
        </div>
      )}
      {budget.phone_budget && (
      <div className="mt-2 icon-container d-flex align-items-start">
        <img
          src={iconPhone}
          alt="Phone Icon"
          className="icon icon-with-margin"
        />
        <p className="color-text-secondary">{budget.phone_budget}</p>
      </div>
      )}
    </div>
  );
};
