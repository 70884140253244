import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { AuthOperations } from "./redux/auth/authOperators";
import { PageLoading } from "./components/PageLoading/PageLoading";
import PublicRoute from "./routes/public/index";
import PrivateRoute from "./routes/private/index";
import { getAuthUser2 } from "./helpers/authHelper";
import "react-phone-input-2/lib/style.css";

const App = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [cookies, setCookies] = useState(false);
  const [loading, setLoading] = useState(true);

  // Memoize `setRedux` to prevent unnecessary re-renders and make sure the reference remains stable
  const setRedux = useCallback(async () => {
    const tokenAuth = getAuthUser2();
    if (!!tokenAuth) {
      dispatch(AuthOperations.setLoadStore(tokenAuth.userObj.id));
    }
  }, [dispatch]); // Dispatch is a stable dependency

  useEffect(() => {
    setRedux(); // Using the memoized function
    showCookies();
  }, [setRedux]); // Include `setRedux` as a dependency

  const showCookies = async () => {
    const superCookies = localStorage.getItem("cookiesInkup");
    if (superCookies) {
      setCookies(true);
    }
  };

  useEffect(() => {
    const isFirstLoadLocalStorage = localStorage.getItem("isFirstLoad008");
    if (!isFirstLoadLocalStorage) {
      localStorage.setItem("isFirstLoad008", "true");
      window.location.reload(true);
    }
  }, []);

  return (
    <>
      {loading === true ? (
        <PageLoading setLoading={setLoading} />
      ) : (
        <BrowserRouter>
          {!state.auth?.token || state.auth?.token?.length === 0 ? (
            <PublicRoute />
          ) : (
            <PrivateRoute
              state={state}
              setCookies={setCookies}
              cookies={cookies}
            />
          )}
        </BrowserRouter>
      )}
    </>
  );
};

export default App;