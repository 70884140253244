import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { BudgetOperations } from "../../../../redux/budget/budgetOperators";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import iconSolidEnvelopeWhite from "../../../../assets/images/icons/iconSolidEnvelopeWhite.svg";
import iconWhatsappBrandsWhite from "../../../../assets/images/icons/iconWhatsappBrandsWhite.svg";
import { ButtonInkup } from "../../../Button/ButtonInkup";
import { textMessage } from "../../Hooks/useTextMessage";

export const BudgetButtonsNew = ({
  budgets,
  budget,
  theme,
  index,
  from,
  until,
  comments,
  idUser,
  pcInfo,
  changeTextButton,
  currency,
  replyHeaderMessage
}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  let eventKey = index;
  const emailDefaultInkup = "hola@inkup.io";
  let ButtonInkupbuttonIdWhatsApp =
    budget.state_budget_tag !== 1
      ? "idButtonResendWhatsAppBudgedAccepted"
      : "idButtonReplyWhatsAppBudgedPending";

  let ButtonInkupbuttonIdEmail =
    budget.state_budget_tag !== 1
      ? "idButtonResendEmailBudgedAccepted"
      : "idButtonReplyEmailBudgedPending";

  const recipientEmail =
    budget.email === t("budget.exampleEmailMock")
      ? emailDefaultInkup
      : budget.email;

  const closedAccordion = useAccordionButton(eventKey, () => "");

  const handleClick = () => {
    closedAccordion();
    changeTextButton(index);
  };

  const onBudgetResponse = async (buttonId) => {
    handleClick(eventKey);
    dispatch(
      BudgetOperations.acceptBudget({
        budgets,
        budget,
        from,
        until,
        comments,
        index,
      })
    );
    dispatch(
      BudgetOperations.updateStateTagBudget({
        state_budget_tag: 2,
        budgetId: budget.id,
        idUser,
      })
    );

    if (buttonId === ButtonInkupbuttonIdWhatsApp) {
      let text = textMessage(
        true,
        budget,
        pcInfo,
        t,
        from,
        until,
        comments,
        true,
        currency,
        replyHeaderMessage
      );
      if (pcInfo?.check_response_budget === 0) {
        let whatsapp = `https://api.whatsapp.com/send?phone=+${budget.phone_budget}&text=${text}`;
        window.open(`${whatsapp}`, "_blank");
      } else {
        let whatsapp = `https://wa.me/+${budget.phone_budget}`;
        window.open(`${whatsapp}`, "_blank");
      }
    } else if (buttonId === ButtonInkupbuttonIdEmail) {
      let text = textMessage(false, budget, pcInfo, t, from, until, comments,false,currency,replyHeaderMessage);

      let mail =
        "mailto:" +
        recipientEmail +
        `?subject=${t("budget.emailSubject")}&body=` +
        text;
      window.open(`${mail}`, "_blank");
    }
  };

  return (
    <>
      <div className="div-actions-pending">
        {budgets?.budgetsAll?.length === 0 && (
          <div className="mt-3 div-icons-text-info-budgets div-text-example-mock">
            <p className="bodyBold">{t("budgetInformation.commentBudget")}</p>
          </div>
        )}

        <div
          className={
            budget.state_budget_tag !== 1
              ? "pt-3 d-flex justify-content-center btnActionValue"
              : ""
          }
        >
          <div className="btnAcceptSmall">
            {budget?.phone_budget && (
              <ButtonInkup
                buttonId={ButtonInkupbuttonIdWhatsApp}
                buttonClass={
                  "rounded-pill bodyDetailsBold greenLargeButton bottonWhatsApp borderNone"
                }
                handle={() => onBudgetResponse(ButtonInkupbuttonIdWhatsApp)}
                text={
                  budget.state_budget_tag !== 1
                    ? t("budget.buttonReSendWhatsApp")
                    : t("budget.buttonSendWhatsApp")
                }
                image={
                  <img
                    src={iconWhatsappBrandsWhite}
                    className="mx-2"
                    alt="whatsapp"
                  />
                }
              />
            )}
            {budget?.email && (
              <ButtonInkup
                buttonId={ButtonInkupbuttonIdEmail}
                buttonClass={
                  "rounded-pill mb-3 bodyDetailsBold bg-buttonEmail color-text-button w-100 borderNone"
                }
                handle={() => onBudgetResponse(ButtonInkupbuttonIdEmail)}
                text={
                  budget.state_budget_tag !== 1
                    ? t("budget.buttonReSenEmail")
                    : t("budget.buttonSenEmail")
                }
                image={
                  <img
                    src={iconSolidEnvelopeWhite}
                    className={theme === 0 ? "mx-2" : " mx-2 filterInvert"}
                    alt="mail"
                  />
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
