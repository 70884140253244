import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { PCOperations } from "../../../../../redux/pageConfiguration/pcOperators";
 
export const PCSize = ({ PCInformation }) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
 
  const checkAvailabilityLaser = async (number) => {
     dispatch(
      PCOperations.changeCheckSizeTattoo(
        number,
        PCInformation.PCInformation.id_user,t
      )
    );
  };

  return (
    <div className="availabilityCheck">
    

      <p className="color-text-secondary">
        {t("pageConfiguration.optionform")}
      </p>
      <p className="color-text-secondary">
        {t("pageConfiguration.selectSize")}
      </p>
      {/* //////// Alineados los checks a la izquierda //////// */}
      {/* <div className="ps-3"> */}
      <div>
        {/* ///////////////////////////////// */}
         <div className="div-options-form">
          <Form.Check
            ///////////////comentando reverse, se quita un warning de la consola///////////
            // reverse
            // reverse
            onClick={() => checkAvailabilityLaser(0)}
            name="groupRadioSizeTattoo"
            type={"radio"}
            id={`reverse-radio-1`}
            defaultChecked={PCInformation.PCInformation.check_size_tattoo === 0}
          />
          <span translate="no" className="text-color">
            {t("pageConfiguration.genericSize")}
          </span>
        </div>
        <div className="div-options-form">
          <Form.Check
            ///////////////comentando reverse, se quita un warning de la consola///////////
            // reverse
            onClick={() => checkAvailabilityLaser(1)}
            name="groupRadioSizeTattoo"
            type={"radio"}
            id={`reverse-radio-2`}
            defaultChecked={PCInformation.PCInformation.check_size_tattoo === 1}
          />
          <span translate="no" className="text-color">
            {t("pageConfiguration.microSize")}
          </span>
        </div>
      </div>
    </div>
    // <>
    //   <Col>
    //     <h4 className="headingH4 text-color">
    //       Ajustando tamaño para lo delicados
    //     </h4>
    //     <div className="pcContainer divConfigureAssistantLogo bg-container">
    //       <p className="color-text-secondary">
    //         {" "}
    //         Tu decides Iluminati pulsa aqui
    //       </p>

    //       <div className="color-border border-div-availabilityCheck availabilityCheck mt-3">
    //         <div className="div-options-form">
    //           <Form.Check
    //             reverse
    //             onClick={() => checkAvailabilityLaser(0)}
    //             name="groupRadioSizeTattoo"
    //             type={"radio"}
    //             id={`reverse-radio-1`}
    //             defaultChecked={
    //               PCInformation.PCInformation.check_size_tattoo === 0
    //             }
    //           />
    //           <span translate="no" className="text-color">
    //             El tamaño importa
    //           </span>
    //         </div>
    //         <div className="div-options-form">
    //           <Form.Check
    //             reverse
    //             onClick={() => checkAvailabilityLaser(1)}
    //             name="groupRadioSizeTattoo"
    //             type={"radio"}
    //             id={`reverse-radio-2`}
    //             defaultChecked={
    //               PCInformation.PCInformation.check_size_tattoo === 1
    //             }
    //           />
    //           <span translate="no" className="text-color">
    //             El tamaño no importa
    //           </span>
    //         </div>
    //       </div>
    //     </div>
    //   </Col>
    // </>

  );
};
