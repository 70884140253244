import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form, Col } from "react-bootstrap";
import { PCOperations } from "../../../../../redux/pageConfiguration/pcOperators";
import { AddArtist } from "./PC_AddArtist";

export const PCArtist = ({ theme, PCInformation, id }) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
  let lengthEmployeds = PCInformation.pCEmployed.length;

  const checkEmployed = async () => {
    if (PCInformation.PCInformation.check_employeds === 0) {
      dispatch(
        PCOperations.changeCheckEmployed(
          1,
          PCInformation.PCInformation.id_user,
          lengthEmployeds,
          ""
        )
      );
    }

    if (PCInformation.PCInformation.check_employeds === 1) {
      dispatch(
        PCOperations.changeCheckEmployed(
          0,
          PCInformation.PCInformation.id_user,
          lengthEmployeds,
          ""
        )
      );
    }
  };

  return (
    <Col>
      <h4 className="headingH4 text-color">
        {t("pageConfiguration.titleArtist")}
      </h4>
      <div className="pcContainer divConfigureAssistantLogo bg-container">
        <>
          <div className="availabilityCheck">
            <p className="color-text-secondary">
              {t("pageConfiguration.manageArtists")}
            </p>
            <div className="div-options-form">
              <Form.Check
                onClick={() => checkEmployed()}
                type="switch"
                id="custom-switch"
                style={{ height: "2em !important" }}
                className="switch-schedule"
                text="Selecciona si tu negocio dispone de turno intensivo o partido"
                checked={PCInformation.PCInformation.check_employeds}
                readOnly
              />
              <span className="text-color">
                {t("pageConfiguration.optionsFormAddArtist")}
              </span>
            </div>
            {PCInformation?.PCInformation?.check_employeds === 0 && (
              <p className="color-text-secondary">
                {t("pageConfiguration.optionsFormTextArtist")}
              </p>
            )}
          </div>
          {PCInformation.PCInformation.check_employeds === 1 && (
            <AddArtist theme={theme} PCInformation={PCInformation} id={id} />
          )}
        </>
      </div>
    </Col>
  );
};
