import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { PCOperations } from "../../../../../redux/pageConfiguration/pcOperators";

export const PCAvailability = ({ PCInformation }) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();

  const checkAvailability = async () => {
    if (PCInformation.PCInformation.time_availability === 0) {
      dispatch(
        PCOperations.changeAvailability(1, PCInformation.PCInformation.id_user)
      );
    }
    if (PCInformation.PCInformation.time_availability === 1) {
      dispatch(
        PCOperations.changeAvailability(0, PCInformation.PCInformation.id_user)
      );
    }
  };

  return (
    <>
      <div className="availabilityCheck">
        {/* <p className="color-text-secondary">
          {t("pageConfiguration.optionform")}
        </p> */}
        <div className="div-options-form">
          <Form.Check
            onClick={() => checkAvailability()}
            type="switch"
            id="custom-switch"
            style={{ height: "2em !important" }}
            className="switch-schedule"
            text="Selecciona si tu negocio dispone de turno intensivo o partido"
            defaultChecked={PCInformation.PCInformation.time_availability}
          />
          <span className="text-color">
            {t("pageConfiguration.customerAvailability")}
          </span>
        </div>
      </div>
      <div className="div-text-schedule color-text-secondary">
        {t("pageConfiguration.optionsFormTextTime")}
      </div>
    </>
  );
};
