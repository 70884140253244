import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PCOperations } from "../../../../../redux/pageConfiguration/pcOperators";
import iconPlusCircle from "../../../../../assets/images/icons/icon-Plus-circle.svg";
import iconX from "../../../../../assets/images/icon-x-circle2.svg";

export const AddCity = ({ theme, PCInformation }) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
  const [city, setCity] = useState("");
  let lengthCities = PCInformation.pcCity.length;

  const deleteCity = async (id_city, index) => {
    dispatch(PCOperations.deleteCity(id_city, index));

    if (lengthCities === 1) {
      dispatch(
        PCOperations.changeCheckCity(
          0,
          PCInformation.PCInformation.id_user,
          lengthCities,
          ""
        )
      );
    }
  };

  const handleSubmit = (evt) => {
    const newTodo = { name: city };
    if (newTodo.name !== "") {
      dispatch(
        PCOperations.addCity(newTodo, PCInformation.PCInformation.id_user,t)
      );

      if (lengthCities === 0) {
        dispatch(
          PCOperations.changeCheckCity(
            1,
            PCInformation.PCInformation.id_user,
            lengthCities,
            newTodo
          )
        );
      }
    }
    setCity("");
  };

  return (
    <>
      <div className="todo-list">
        <div className="file-input">
          <p className="p-add-city color-text-secondary">
            {" "}
            {t("pageConfiguration.optionsFormCityStudy")}
          </p>
          <div className="div-input-add-city">
            <input
              onChange={(e) => setCity(e.target.value)}
              value={city === null ? "" : city}
              className="inputCity bg-input color-text-secondary"
              id="task"
              type="text"
              name="task"
              placeholder={t("pageConfiguration.placeholderArtistCity")}
            />
            <button className="buttonCityAdd" onClick={() => handleSubmit()}>
              <img
                className={theme === 1 ? "no-filter-img" : "filter-img"}
                src={iconPlusCircle}
                alt="buttonADD"
              />
            </button>
          </div>
        </div>
        {PCInformation?.pcCity?.length !== 0 && (
          <p className="text-city-study color-text-secondary">
            {t("pageConfiguration.addedCitys")}
          </p>
        )}
        <div className="cityList">
          {PCInformation.pcCity.map((element, index) => (
            <div key={index} className="cityElementList color-border">
              <div className="div-add-city">
                <button
                  className="btn-delete-city"
                  size="small"
                  onClick={() => deleteCity(element, index)}
                  shape="circle"
                >
                  <img
                    className={theme === 1 ? "filter-img" : ""}
                    src={iconX}
                    alt="x"
                  />
                </button>
                <p className="size-bold-text color-text-secondary">
                  {element.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
