import axios from "axios";

const ROOT_URL = process.env.REACT_APP_API_URL;

export const updateProfile = async (user, id) => {
  try {
    return await axios.put(`${ROOT_URL}/api/profile/updateProfile`, {
      user,
      id,
    });
  } catch (error) {
    return false;
  }
};
export const updateImageProfile = async (image, id) => {
  try {
    return await axios.post(`${ROOT_URL}/api/profile/updateImageProfile`, {
      image,
      id,
    });
  } catch (error) {
    return false;
  }
};

export const changePassword = async (data, id) => {
  try {
    return await axios.put(`${ROOT_URL}/api/profile/changePwc`, { data, id });
  } catch (error) {
    return false;
  }
};
//// OLD

export const getProfile = async (id) => {
  try {
    return await axios.post(`${ROOT_URL}/api/user/profile`, id);
  } catch (error) {
    return false;
  }
};
export const getImageProfile = async (id) => {
  try {
    return await axios.post(`${ROOT_URL}/api/user/getImgProfile`, id);
  } catch (error) {
    return false;
  }
};
export const deleteImageProfile = async (id) => {
  try {
    return await axios.post(`${ROOT_URL}/api/profile/deleteImageProfile`, {
      id,
    });
  } catch (error) {
    return false;
  }
};
export const updateCheckTheme = async (theme, id) => {
  try {
    return await axios.put(`${ROOT_URL}/api/profile/updateCheckTheme`, {
      theme,
      id,
    });
  } catch (error) {
    return false;
  }
};
