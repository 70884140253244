import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Col } from "react-bootstrap";
import { Logout } from "./components/logout";
import logoIcon from "../../assets/images/logoIcon.svg";
import logoSidebar from "../../assets/images/logoSidebar.svg";
import arrowSidebarLeft from "../../assets/images/icon-chevron-circle-left.svg";
import arrowSidebarRight from "../../assets/images/icon-chevron-circle-right.svg";
import Item from "./components/Item/Item";
import { Link2, Link6, Link5, LogoutButton } from "./components/Data/data";
import imgProfile from "../../assets/images/icons/icon-regular-user-alt-grey.svg";

export const Sidebar = ({ state, setSidebarUsed, sidebarUsed }) => {
  const [open, setOpen] = useState(false);
  const [t] = useTranslation("global");

  return (
    <Col md="auto" className="d-none d-md-block p-0">
      <div className={open ? "sidebarOpen" : "sidebar"}>
        <div className="pt-0 pb-5">
          <div className="sidebarTop">
            <div className="logoIcon">
              <img src={logoIcon} alt="" />
            </div>
            <div>
              <img src={logoSidebar} className="logoTypographic" alt="" />
            </div>
            {open === false}
            <div className="divSidebarArrow" onClick={() => !setOpen(!open)}>
              <img src={arrowSidebarLeft} className="sidebarArrow" alt="" />
            </div>
          </div>
          {open === false && (
            <div className="divSidebarArrow" onClick={() => setOpen(!open)}>
              <img
                src={arrowSidebarRight}
                className="sidebarArrowRight"
                alt=""
              />
            </div>
          )}
          <div
            className={
              sidebarUsed === "pageConfiguration"
                ? "linksTopAzul1 bodySubcontentBold"
                : "linksTop2 bodySubcontentBold"
            }
          >
            {Link6.map(({ text, to, svg }, idx) => (
              <div
                key={idx}
                onClick={() => setSidebarUsed("pageConfiguration")}
              >
                <Item
                  open={open}
                  to={to}
                  text={t("sideboard.pageConfiguration")}
                  svg={svg}
                >
                  {text}
                </Item>
              </div>
            ))}
          </div>
          <div
            className={
              sidebarUsed === "presupuestos"
                ? "linksTopAzul1 bodySubcontentBold"
                : "linksTop2 bodySubcontentBold"
            }
          >
            {Link2.map(({ text, to, svg }, idx) => (
              <div key={idx} onClick={() => setSidebarUsed("presupuestos")}>
                <Item
                  open={open}
                  to={to}
                  text={t("sideboard.requests")}
                  svg={svg}
                  budget={state.budget.budgetsPending}
                >
                  {text}
                </Item>
              </div>
            ))}
          </div>
        </div>
        <div className="pb-5">
          <div className={sidebarUsed === "perfil" ? "linksprofileAzul" : ""}>
            <div
              onClick={() => setSidebarUsed("perfil")}
              className={"sidebarOpen" ? "userOpen" : "sidebar"}
            >
              <div>
                <Link to="/perfil">
                  <Button type="button" className="userButton iconSidebar">
                    {state.user.imageProfile?.path_media ? (
                      <img
                        className="avatar avatar-48 rounded-circle p-2 userImage"
                        src={state.user.imageProfile?.path_media}
                        alt="img-profile"
                      />
                    ) : (
                      <img
                        className={
                          sidebarUsed === "perfil"
                            ? "avatar avatar-48 rounded-circle p-2 iconSidebarAzul"
                            : "avatar avatar-48 rounded-circle p-2"
                        }
                        src={imgProfile}
                        alt="img-profile"
                      />
                    )}
                  </Button>
                </Link>
              </div>
              <div
                className={
                  sidebarUsed === "perfil"
                    ? "userData linksProfileAzul1"
                    : "userData"
                }
              >
                <Link className="linkNotStyle" to="/perfil">
                  <h5 className="text-start headingH5 LinkUser mb-0">
                    {state?.pageConfiguration?.PCInformation?.studio_name}
                  </h5>
                </Link>
              </div>
            </div>
          </div>
 <div
            className={
              sidebarUsed === "sugerencias"
                ? "linksDownAzul linksDown bodySubcontentBold"
                : "linksDown bodySubcontentBold"
            }
          >
            {Link5.map(({ text, to, svg }, idx) => (
              <div key={idx} onClick={() => setSidebarUsed("sugerencias")}>
                <Item
                  open={open}
                  to={to}
                  text={t("sideboard.suggestion")}
                  svg={svg}
                >
                  {text}
                </Item>
              </div>
            ))}
          </div> 
          <div>
            {LogoutButton.map(({ text, to, svg }, idx) => (
              <Logout
                key={idx}
                open={open}
                to={to}
                text={t("sideboard.logout")}
                svg={svg}
              >
                {text}
              </Logout>
            ))}
          </div>
        </div>
      </div>
    </Col>
  );
};
