import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { PCOperations } from "../../../../../redux/pageConfiguration/pcOperators";
import { AddTypeJewelry } from "./PC_AddTypeJewelry";

export const PCTypeJewelry = ({ theme, PCInformation, id }) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
  let lengthJewelry = PCInformation.pcTypeJewelry.length;

  const checkTipeJewelry = async () => {
    if (PCInformation.PCInformation.check_typejewelry === 0) {
      dispatch(
        PCOperations.changeCheckTypeJewelry(
          1,
          PCInformation.PCInformation.id_user,
          lengthJewelry,
          ""
        )
      );
    }
    if (PCInformation.PCInformation.check_typejewelry === 1) {
      dispatch(
        PCOperations.changeCheckTypeJewelry(
          0,
          PCInformation.PCInformation.id_user,
          lengthJewelry,
          ""
        )
      );
    }
  };

  return (
    <>
      <div className=" mt-3 availabilityCheck">
        <p className="color-text-secondary">
          {t("pageConfiguration.optionform")}
        </p>
        <div className="div-options-form">
          <Form.Check
            onClick={() => checkTipeJewelry()}
            type="switch"
            id="custom-switch"
            style={{ height: "2em !important" }}
            className="switch-schedule"
            text="Selecciona si tu negocio dispone de turno intensivo o partido"
            checked={PCInformation.PCInformation.check_typejewelry}
            readOnly
          />

          <span className="text-color">
            {t("pageConfiguration.optionsFormAddTypeJewelry")}
          </span>
        </div>

        {PCInformation?.PCInformation?.check_typejewelry === 0 && (
          <p className="color-text-secondary">
            {t("pageConfiguration.optionsFormTextTypeJewelry")}
          </p>
        )}
        {PCInformation?.PCInformation?.check_typejewelry === 1 && (
          <p className="color-text-secondary">
            {t("pageConfiguration.manageTypeJewelry")}
          </p>
        )}
      </div>

      {PCInformation?.PCInformation?.check_typejewelry === 1 && (
        <AddTypeJewelry theme={theme} PCInformation={PCInformation} id={id} />
      )}
    </>
  );
};
