import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { PCOperations } from "../../../../../redux/pageConfiguration/pcOperators";
import ReactPhoneInput from "react-phone-input-2";

export const PCPiercing = ({
  PCInformation,
  setPhone_piercing,
  setDisabledButton,
}) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
  const checkAvailabilityLaser = async () => {
    if (PCInformation.PCInformation.check_piercing === 0) {
      dispatch(
        PCOperations.changeCheckPiercing(1, PCInformation.PCInformation.id_user)
      );
    }
    if (PCInformation.PCInformation.check_piercing === 1) {
      dispatch(
        PCOperations.changeCheckPiercing(0, PCInformation.PCInformation.id_user)
      );
    }
  };

  const handleChange = (e) => {
    setDisabledButton(false);
    setPhone_piercing(e, "phone_piercing");
  };

  return (
    <>
      <div className="color-border border-div-availabilityCheck availabilityCheck mt-3">
        <div className="div-options-form">
          <Form.Check
            onClick={() => checkAvailabilityLaser()}
            type="switch"
            id="custom-switch"
            style={{ height: "2em !important" }}
            className="switch-schedule"
            text="Selecciona si tu negocio dispone de turno intensivo o partido"
            defaultChecked={PCInformation.PCInformation.check_piercing}
          />
          <span translate="no" className="text-color">
            Piercing
          </span>
        </div>
        {PCInformation?.PCInformation?.check_piercing === 1 && (
          <Form.Group className="mb-3" id="phone">
            <Form.Label className="size-text-label  color-text-secondary">
              {" "}
              {t("profile.textPhoneOtherServices")}{" "}
            </Form.Label>
            <div className="divMyAccount">
              <ReactPhoneInput
                id="idInputPhoneNumberForm"
                className=" greyTextFormPhone color-text-secondary bg-input"
                inputExtraProps={{
                  name: "phone_piercing",
                  required: true,
                  autoFocus: true,
                }}
                placeholder={t("pageConfiguration.textNewPhone")}
                value={
                  PCInformation.PCInformation?.phone_piercing === null
                    ? PCInformation.PCInformation.phone_corporation
                    : PCInformation.PCInformation?.phone_piercing
                }
                // placeholder={t("profile.phoneBox")}
                required
                country={"es"}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </Form.Group>
        )}
      </div>
    </>
  );
};
