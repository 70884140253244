import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import ReactPhoneInput from "react-phone-input-2";

import { PCOperations } from "../../../../../redux/pageConfiguration/pcOperators";

export const PCMicroblanding = ({
  PCInformation,
  setPhone_microblading,
  setDisabledButton,
}) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();

  const checkAvailabilityMicroblanding = async () => {
    if (PCInformation.PCInformation.check_microblading === 0) {
      dispatch(
        PCOperations.changeCheckMicroblading(
          1,
          PCInformation.PCInformation.id_user
        )
      );
    }
    if (PCInformation.PCInformation.check_microblading === 1) {
      dispatch(
        PCOperations.changeCheckMicroblading(
          0,
          PCInformation.PCInformation.id_user
        )
      );
    }
  };

  const handleChange = (e) => {
    setDisabledButton(false);
    setPhone_microblading(e, "phone_microblading");
  };

  return (
    <>
      <div className="color-border border-div-availabilityCheck availabilityCheck mt-3">
        <div className="div-options-form">
          <Form.Check
            onClick={() => checkAvailabilityMicroblanding()}
            type="switch"
            id="custom-switch"
            style={{ height: "2em !important" }}
            className="switch-schedule"
            text="Selecciona si tu negocio dispone de turno intensivo o partido"
            defaultChecked={PCInformation.PCInformation.check_microblading}
          />
          <span className="text-color">
            {t("pageConfiguration.customerMicrobladingAvailability")}
          </span>
        </div>
        {PCInformation?.PCInformation?.check_microblading === 1 && (
          <Form.Group className="mb-2" id="phone">
            <Form.Label className="size-text-label color-text-secondary">
              {" "}
              {t("profile.textPhoneOtherServices")}{" "}
            </Form.Label>
            <div className="divMyAccount">
              <ReactPhoneInput
                id="idInputPhoneNumberForm"
                className="greyTextFormPhone color-text-secondary bg-input"
                inputExtraProps={{
                  name: "phone_barber",
                  required: true,
                  autoFocus: true,
                }}
                placeholder={t("pageConfiguration.textNewPhone")}
                value={
                  PCInformation.PCInformation?.phone_microblading === null
                    ? PCInformation.PCInformation.phone_corporation
                    : PCInformation.PCInformation?.phone_microblading
                }
                country="es"
                required
                onChange={(e) => handleChange(e)}
              />
            </div>
          </Form.Group>
        )}
      </div>
    </>
  );
};
