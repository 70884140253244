import AlertActions from "./alertActions";

export const AlertOperations = {
  alertSuccessShow: (data) => async (dispatch) => {
      dispatch(AlertActions.alertSuccessShowAction(data));
  },
  alertSuccessHide: (data) => async (dispatch) => {
    dispatch(AlertActions.alertSuccessHideAction(data));
},
};
