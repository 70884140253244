import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BudgetAccordion } from "./components/BudgetAccordion";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const Budget = ({ user, budgets, pCInfo }) => {
  const [t] = useTranslation("global");
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container
      fluid
      translate="no"
      className="budget-container scroll-container"
    >
      <Row className="row-budget-container">
        <Col sm={12} className="col-title-budget">
          <div className="div-title-budget">
            <h2 className="budget-tittle headingH2 text-color">
              {t("budget.inquiries")}
            </h2>
          </div>
        </Col>
        <hr className="color-hr"></hr>
      </Row>
      <div className="div-budget-container">
        <div className="div-generic-budget">
          <Row className="row-generic-budget">
            {budgets.budgetsCountMoth !== undefined && (
              <Col sm={12} className="col-messages-count">
                {budgets?.budgetLimit !== 0 && (
                  <div className="budget-message-count">
                    <p>
                      {t("budget.textBudgetPending")}
                      {budgets?.budgetLimit}

                      {user.profile.premium === 0 &&
                        `${t("budget.textBudgetPending11")}`}
                      {user.profile.premium === 1 &&
                        `${t("budget.textBudgetPending1")}`}
                      {user.profile.premium === 0 &&
                        `${t("budget.textBudgetPending3")}`}

                      {t("budget.textBudgetPending4")}
                      {user.profile.premium === 0 && (
                        <Link to="/stripe" className="linkWhite">
                          {t("budget.textBudgetPending5")}
                        </Link>
                      )}
                      {user.profile.premium === 1 && (
                        <Link to="/stripe" className="linkWhite">
                          {t("budget.textBudgetPending6")}
                        </Link>
                      )}
                    </p>
                  </div>
                )}

                {budgets?.budgetLimit === 0 ? (
                  <div className="budget-message-count-limit">
                    <p>
                      {t("budget.textBudgetPending2")}
                      {user.profile.premium === 0 &&
                        `${t("budget.textBudgetPending3")}`}
                      {t("budget.textBudgetPending4")}
                      <a
                        href="/stripe"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="linkWhite"
                      >
                        {t("budget.textBudgetPending5")}
                      </a>
                    </p>
                  </div>
                ) : null}
              </Col>
            )}
            <BudgetAccordion
              userProfile={user}
              budgets={budgets}
              pcInfo={pCInfo}
            />
          </Row>
        </div>
      </div>
    </Container>
  );
};
