import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { BudgetOperations } from "../../../../redux/budget/budgetOperators";

export const DropdownButtonBudget = ({
  budgetState,
  budgetId,
  user,
  buttonDisabled,
}) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();

  const handleChange = (data) => {
    dispatch(
      BudgetOperations.updateStateTagBudget({
        state_budget_tag: data,
        budgetId: budgetId,
        user: user,
      })
    );
  };
  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-basic"
        bsPrefix={
          budgetState === 1 || budgetState === 0
            ? "stylesButtonBudget colorButtonPendingBudget"
            : budgetState === 2
            ? "stylesButtonBudget colorButtonAcceptedBudget"
            : budgetState === 3
            ? "stylesButtonBudget colorButtonClientBudget"
            : budgetState === 4
            ? "stylesButtonBudget colorButtonDiscardedBudget"
            : ""
        }
        disabled={buttonDisabled}
      >
        {(budgetState === 1 || budgetState === 0) &&
          t("budgetAccordion.accordionPending")}
        {budgetState === 2 && t("budgetAccordion.accordionAccepted")}
        {budgetState === 3 && t("budgetAccordion.accordionClient")}
        {budgetState === 4 && t("budgetAccordion.accordionDiscarded")}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleChange(1)}>
          {t("budgetAccordion.accordionPending")}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleChange(2)}>
          {t("budgetAccordion.accordionAccepted")}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleChange(3)}>
          {t("budgetAccordion.accordionClient")}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleChange(4)}>
          {t("budgetAccordion.accordionDiscarded")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
