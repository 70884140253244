import {Button as BootstrapButton} from "react-bootstrap";

const bgColorTheme = {
    "acento": "#5268af",
}

const textColorTheme = {
    "acento": "#ffffff",
}

export const Button = ({
                           children,
                           size = "sm",
                           theme = "acento",
                           type = "button",
                           rounded = true,
                           capitalized = true,
                           onClick = (e) => null
                       }) => {
    return (
        <BootstrapButton type={type}
                         size={size}
                         variant={theme === 'blank' ? 'light' : ''}
                         style={{
                             "background-color": bgColorTheme[theme] ?? '',
                             "color": textColorTheme[theme] ?? ""
                         }}
                         className={`
                            ${rounded ? 'rounded-pill' : ''}
                            ${capitalized ? 'text-capitalize' : ''}
                        `}
                         onClick={(e) => onClick && onClick(e)}
        >
            {children}
        </BootstrapButton>
    );
}