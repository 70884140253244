 import arm from "../../../../assets/images/image-Arm.png";

export const TimeTattooData = (t) => {
   return [
    {
      id: "mañanas",
      value: "Mañanas",
      text: `${t("formChat.cityMorning")}`,
      image: arm,
      type: "radio",
      classNameBorder: "borderUp",
    },
    {
      id: "tardes",
      value: "Tardes",
      text: `${t("formChat.cityAfternoon")}`,
      image: arm,
      type: "radio",
    },
    {
      id: "ambos",
      value: "Ambos",
      text: `${t("formChat.cityBoth")}`,
      image: arm,
      type: "radio",
      classNameBorder: "borderBottom",
    },
  ];
};
